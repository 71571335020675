import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';

import { Button, ButtonIcon } from '../../../design-system';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';

const PlaybookBuilderNavigation = ({ playbookId }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { width: screenWidth } = useWindowSize();
    const isDesktop = screenWidth >= 1024;

    const openPlaybookBuilder = (mode) => {
        navigate(`/playbook/${playbookId}?mode=${mode}`, { state: { from: location } });
    };

    return (
        <div className="flex items-center justify-end gap-2">
            {isDesktop && (
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={EditLineIcon}
                    onClick={() => openPlaybookBuilder(PLAYBOOK_BUILDER_MODE.edit)}
                />
            )}
            <Button
                type="neutral"
                size="xs"
                text="View Playbook"
                onClick={() => openPlaybookBuilder(PLAYBOOK_BUILDER_MODE.view)}
            />
        </div>
    );
};

export default PlaybookBuilderNavigation;
