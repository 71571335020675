import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useDefaultSearchParams } from '../../../hooks/useDefaultSearchParams';

import BaseDetailPageHeader from './BaseDetailPageHeader/BaseDetailPageHeader';
import Loading from '../../../components/Loading';
import BaseEntriesIndex from './BaseEntriesIndex/BaseEntriesIndex';

const BaseDetailPage = () => {
    const { baseId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.from || '/bases';

    const [baseDetail, setBaseDetail] = useState(null);

    useEffect(() => {
        const fetchBaseDetail = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.knowledge.base}${baseId}/`);
                setBaseDetail(data);
            } catch (e) {
                navigate(backLinkHref);
            }
        };

        if (baseDetail) {
            setBaseDetail(null);
        }
        fetchBaseDetail();
    }, [baseId]);

    useDocumentTitle(baseDetail?.name);

    useDefaultSearchParams();

    return (
        <div className="page-position flex flex-col bg-neutral-50 overflow-y-auto">
            {baseDetail && (
                <BaseDetailPageHeader
                    baseDetail={baseDetail}
                    setBaseDetail={setBaseDetail}
                    backLinkHref={backLinkHref}
                />
            )}

            <BaseEntriesIndex isBaseDetailLoaded={!!baseDetail} baseId={baseId} />

            {!baseDetail && (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default BaseDetailPage;
