import React, { useEffect, useState } from 'react';
import organizationClient from '../../../services/organization-api';
import { DEFAULT_LIMIT } from '../../../hooks/useCustomPagination';
import { API } from 'constants';
import useUser from '../../../hooks/useUser';
import { transformOrganizationDataForTable } from '../../../helpers/formatOrganizationDataForTable';
import Table from '../../../design-system/Table/Table';

const OrganizationsBlock = () => {
    const { user } = useUser();
    const [tableData, setTableData] = useState(null);
    const [page, setPage] = useState(1);

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const { data } = await organizationClient.get(
                    `${API.ROUTES.organization.organizationMembership}?user=${user.id}&limit=${DEFAULT_LIMIT}&offset=${
                        (page - 1) * DEFAULT_LIMIT
                    }`
                );
                setTableData(transformOrganizationDataForTable(data, page));
            } catch (e) {
                console.log('error', e);
            }
        };

        fetchOrganizations();
    }, [page]);

    return (
        <>
            {tableData && (
                <div className="p-4 md:pt-4 md:pb-8 md:px-8 rounded-md w-full bg-white flex gap-x-2 gap-y-6 flex-wrap">
                    <div className="flex flex-col space-y-5 w-full">
                        <p className="font-body-bold text-body-bold-m">Organization Settings</p>
                        <Table
                            data={tableData}
                            columnsCount={1}
                            shift={350}
                            includePagination
                            onPaginationPreviousClick={() => setPage((prevPage) => prevPage - 1)}
                            onPaginationNextClick={() => setPage((prevPage) => prevPage + 1)}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default OrganizationsBlock;
