import React, { createContext, useState } from 'react';

import { useWebSocketListeningLoadingState } from '../hooks/useWebSocketListeningLoadingState';

export const ListeningMultipleLoadingsContext = createContext();

export const WsMultipleLoadingsProvider = ({ messageType, children }) => {
    // keeps data in format {id: function(handler) | null}
    const [loadingHandlers, setLoadingHandlers] = useState({});
    const [shouldConnectWebsocket, setShouldConnectWebsocket] = useState(false);

    const { addRequestUuid, tryReconnectIfWebsocketClosed } = useWebSocketListeningLoadingState({
        messageType,
        onSuccess: onWebsocketStatusChange,
        onError: onWebsocketStatusChange,
        shouldConnectWebsocket,
    });

    function onWebsocketStatusChange(message) {
        const id = message.data?.id;

        setLoadingHandlers((loadingHandlers) => {
            const onStatusChange = loadingHandlers[id];
            if (onStatusChange) {
                onStatusChange(message);
            }

            return { ...loadingHandlers, [id]: null };
        });
    }

    return (
        <ListeningMultipleLoadingsContext.Provider
            value={{
                loadingHandlers,
                setLoadingHandlers,
                addRequestUuid,
                setShouldConnectWebsocket,
                tryReconnectIfWebsocketClosed,
            }}
        >
            {children}
        </ListeningMultipleLoadingsContext.Provider>
    );
};
