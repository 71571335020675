import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/library-api';
import operateClient from '../../../services/operate-api';

import {
    checkIsFormDataValid,
    extractDocIds,
    formatInputsToFormData,
    getStartNewJobFormDataInitialState,
    handleClientStartNewJobRequestErrors,
    replaceDocsVariableMappingInFormData,
} from '../../../helpers/startNewJobFormUtils';
import { HISTORY_TAB } from '../../../constants/assistant';

import StartNewJobForm from '../StartNewJobForm/StartNewJobForm';
import JobSubmissionDetails from '../JobSubmissionDetails/JobSubmissionDetails';
import LoadingState from '../LoadingState/LoadingState';
import Alert from '../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const NewJobBlock = ({
    agentId,
    jobDataHookResponse,
    isExpanded,
    toggleSectionExpand,
    newJobName,
    setJobsHistoryList,
    mappedDocsLabelsState,
}) => {
    const { isJobStarted, setIsJobStarted, setJobData } = jobDataHookResponse;

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const activeTab = searchParams.get('tab') || HISTORY_TAB.chats;

    const [agentDetail, setAgentDetail] = useState(null);
    const [formData, setFormData] = useState([]);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const submitFormController = useRef(new AbortController());

    const [errorAlert, setErrorAlert] = useState(null);

    const isJobSubmissionDetailsExpanded = useRef(isExpanded.jobSubmissionDetails);

    const [mappedDocsLabels, setMappedDocsLabels] = mappedDocsLabelsState;

    useEffect(() => {
        const resetAgentDetail = () => {
            if (agentDetail) {
                setAgentDetail(null);
            }
            if (formData) {
                setFormData(null);
            }
        };

        const fetchAgentDetail = async (controller) => {
            try {
                const { data } = await client.get(`${API.ROUTES.library.process}${agentId}/`, {
                    signal: controller.signal,
                });
                setAgentDetail(data);
                setFormData(getStartNewJobFormDataInitialState(data.inputs));
            } catch (e) {
                if (e.message === 'canceled') {
                    return;
                }
                navigate(`/assistant?tab=${activeTab}`);
            }
        };

        resetAgentDetail();
        const controller = new AbortController();
        fetchAgentDetail(controller);

        return () => {
            controller?.abort();
            submitFormController.current?.abort();
        };
    }, [agentId]);

    const handleSubmitForm = async () => {
        try {
            const isFormDataValid = checkIsFormDataValid(formData, setFormData);
            if (!isFormDataValid) {
                return;
            }

            setIsSubmitLoading(true);
            setIsJobStarted(true);

            const inputs = formatInputsToFormData(formData);
            const requestBody = { process: agentId, auto: true, inputs };

            if (newJobName) {
                requestBody.name = newJobName;
            }

            const updatedFormData = replaceDocsVariableMappingInFormData(formData);
            setFormData(updatedFormData);
            const mappedDocs = extractDocIds(updatedFormData);
            setMappedDocsLabels((prevData) => ({ ...mappedDocs, ...prevData }));

            const newController = new AbortController();
            submitFormController.current = newController;

            const { data } = await operateClient.post(API.ROUTES.operate.thread, requestBody, {
                signal: newController.signal,
            });
            setJobData(data);

            const newJobHistory = { ...data, created_at_group: 'today' };
            setJobsHistoryList((jobs) => [newJobHistory, ...(jobs || [])]);

            navigate(`/assistant/job/${data.id}?tab=${HISTORY_TAB.jobs}`, {
                state: { isJobSubmissionDetailsExpanded: !!isJobSubmissionDetailsExpanded.current },
            });
        } catch (error) {
            setIsSubmitLoading(false);
            setIsJobStarted(false);

            if (error.message === 'canceled') {
                return;
            }

            handleClientStartNewJobRequestErrors({ error, setErrorAlert, formData, setFormData });
        }
    };

    useEffect(() => {
        isJobSubmissionDetailsExpanded.current = isExpanded.jobSubmissionDetails;
    }, [isExpanded.jobSubmissionDetails]);

    const isFormDisplayed = !isJobStarted && !isSubmitLoading;

    return (
        <>
            {isFormDisplayed && (
                <StartNewJobForm
                    agentDetail={agentDetail}
                    formData={formData}
                    setFormData={setFormData}
                    handleSubmitForm={handleSubmitForm}
                />
            )}

            {isSubmitLoading && agentDetail && (
                <div className="gap-0 sm:gap-3 md:gap-6">
                    <JobSubmissionDetails
                        inputs={formData}
                        agentName={agentDetail.name}
                        isExpanded={isExpanded.jobSubmissionDetails}
                        toggleSectionExpand={toggleSectionExpand}
                        mappedDocsLabels={mappedDocsLabels}
                    />
                    <LoadingState mode="agent" agentName={agentDetail.name} />
                </div>
            )}

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={4000}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

export default NewJobBlock;
