import React from 'react';
import DesktopBottomActionBar from '../DesktopBottomActionBar/DesktopBottomActionBar';
import MobileBottomActionBar from '../MobileBottomActionBar/MobileBottomActionBar';

const PlaybookBottomActionBar = ({
    isDesktop,
    mode,
    isSaveEnabled,
    handleSaveChanges,
    isSaveChangesLoading,
    playbookContentFormData,
    areSectionsExpandedState,
}) => {
    return (
        <>
            {isDesktop && (
                <DesktopBottomActionBar
                    mode={mode}
                    isSaveEnabled={isSaveEnabled}
                    handleSaveChanges={handleSaveChanges}
                    isSaveChangesLoading={isSaveChangesLoading}
                />
            )}

            {!isDesktop && (
                <MobileBottomActionBar
                    mode={mode}
                    playbookContentFormData={playbookContentFormData}
                    areSectionsExpandedState={areSectionsExpandedState}
                />
            )}
        </>
    );
};

export default PlaybookBottomActionBar;
