import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { SORT_TYPES, templateLibrarySortOptions } from '../../constants/sort';
import { TEMPLATES_LIBRARY_TABS } from '../../constants/template';
import { parseTemplatesLibrarySearchParams } from '../../helpers/parseTemplatesLibrarySearchParams';
import {
    handleClearSearchBar,
    handleSearch,
    handleSortOptionSelect,
} from '../../helpers/handleUpdateSearchParams';

import SearchBar from '../../design-system/SearchBar/SearchBar';
import SortDropdown from '../../components/SortDropdown/SortDropdown';
import { Button, Tabs } from '../../design-system';
import { AddCircleLineIcon, ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../design-system/Icons';
import TemplatesLibraryPaginatedContainer from './TemplatesLibraryPaginatedContainer/TemplatesLibraryPaginatedContainer';

export const createNewTemplateRoute = {
    [TEMPLATES_LIBRARY_TABS.goals]: '/templates/goal/new',
    [TEMPLATES_LIBRARY_TABS.processes]: '/templates/process/new',
};

const tabs = [{ name: 'Goals' }, { name: 'Processes' }];

const TemplatesLibraryPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = parseTemplatesLibrarySearchParams(searchParams);

    const activeTab = location.pathname?.slice(11); // goals or processes
    const tabIndex = activeTab === TEMPLATES_LIBRARY_TABS.goals ? 0 : 1;

    const [sortDropdownOpened, setSortDropdownOpened] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    useDocumentTitle('Templates');

    useEffect(() => {
        const page = Number.isNaN(parsedParams.page) ? 1 : parsedParams.page;
        const sort = Object.values(SORT_TYPES).includes(parsedParams.sort)
            ? parsedParams.sort
            : SORT_TYPES.created;

        if (Number.isNaN(parsedParams.page) || parsedParams.sort !== sort) {
            setSearchParams(
                {
                    ...parsedParams,
                    page,
                    sort,
                },
                { replace: true }
            );
        }
    }, [parsedParams, setSearchParams]);

    useEffect(() => {
        // fill the search bar if the "search" parameter is present in the searchQuery after the page reload
        if (parsedParams.search && parsedParams.search !== searchValue) {
            setSearchValue(parsedParams.search);
        }
    }, []);

    const onTabChanged = () => {
        navigate(
            activeTab === TEMPLATES_LIBRARY_TABS.goals ? '/templates/processes' : '/templates/goals'
        );
    };

    const handleTemplatesSortOptionSelect = (sortOption) => {
        handleSortOptionSelect({
            searchParams,
            setSearchParams,
            sortOption,
            onSearchDropdownClose: () => setSortDropdownOpened(false),
        });
    };

    const handleSearchTemplates = () => {
        handleSearch({ searchParams, setSearchParams, searchValue });
    };

    const handleClearSearchQuery = () => {
        handleClearSearchBar({ searchParams, setSearchParams, setSearchValue });
    };

    const handleNewTemplateClick = () => {
        navigate(createNewTemplateRoute[activeTab], {
            state: { templatesLibraryLocation: location },
        });
    };

    return (
        <div className="w-full sm:w-[calc(100%-68px)] max-sm:px-3 max-sm:py-4 flex flex-col gap-5">
            <div className="flex items-start flex-col">
                <p className="font-heading-bold text-heading-bold-l text-black">Templates</p>
                <p className="font-body text-body-regular-xs text-neutral-300 pt-1">
                    Tested and approved configurations of tasks and goals for use in a process.
                </p>
            </div>
            <div className="flex min-[910px]:items-center flex-row gap-x-4 gap-y-2 justify-between flex-wrap">
                <div className="flex min-[910px]:items-center gap-2 flex-grow flex-wrap">
                    <div className="w-fit">
                        <Tabs onTabChanged={onTabChanged} tabIndex={tabIndex} tabs={tabs} />
                    </div>
                    <div className="flex items-center gap-2 flex-grow max-sm:relative">
                        <div className="flex-grow xs:min-w-[220px] max-w-[300px]">
                            <SearchBar
                                size="xs"
                                value={searchValue}
                                withSubmitIcon
                                withClearIcon
                                onChange={(e) => setSearchValue(e.target.value)}
                                onSubmit={handleSearchTemplates}
                                onClear={handleClearSearchQuery}
                                placeholder="Search by name"
                            />
                        </div>

                        <div className="flex items-center gap-2 justify-end sm:relative">
                            <Button
                                type="link"
                                size="xs"
                                text="Sort"
                                trailingIcon={
                                    sortDropdownOpened ? ArrowUpSLineIcon : ArrowDownSLineIcon
                                }
                                onClick={() => setSortDropdownOpened((prev) => !prev)}
                            />

                            {sortDropdownOpened && (
                                <SortDropdown
                                    options={templateLibrarySortOptions}
                                    selectedOption={parsedParams.sort}
                                    handleOptionSelect={handleTemplatesSortOptionSelect}
                                    onClose={() => setSortDropdownOpened(false)}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <Button
                        type="secondary"
                        size="xs"
                        text="New Template"
                        leadingIcon={AddCircleLineIcon}
                        onClick={handleNewTemplateClick}
                    />
                </div>
            </div>

            <TemplatesLibraryPaginatedContainer parsedParams={parsedParams} activeTab={activeTab} />
        </div>
    );
};

export default TemplatesLibraryPage;
