import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, ButtonIcon } from '../../../../design-system';
import {
    ArrowGoBackLineIcon,
    ErrorWarningLineIcon,
    More2FillIcon,
} from '../../../../design-system/Icons';
import DocDetailMoreOptionsPopup from '../DocDetailMoreOptionsPopup/DocDetailMoreOptionsPopup';
import Alert from '../../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';
import ArchiveDocModal from '../../ArchiveDocModal/ArchiveDocModal';

const DocDetailTopControls = ({ id, label, backLinkHref, docsRequestKeysToMutate }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isMoreOptionsPopupOpened, setIsMorePopupOpened] = useState(false);

    const [successAlert, setSuccessAlert] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const [isArchiveDocModalOpened, setIsArchiveDocModalOpened] = useState(false);

    const navigateToDocsIndex = () => {
        if (backLinkHref?.pathname === '/docs' || backLinkHref === '/docs') {
            navigate(backLinkHref);
        } else {
            navigate('/docs');
        }
    };

    return (
        <div className="flex items-center justify-between gap-4">
            <div className="flex items-center gap-5">
                <Button
                    type="link"
                    size="xs"
                    text="Back to Docs"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate(backLinkHref, { state: { from: location } })}
                />
                <p className="font-body text-body-regular-s text-neutral-300">Docs /</p>
            </div>

            <div className="relative">
                <ButtonIcon
                    type="neutral"
                    size="sm"
                    icon={More2FillIcon}
                    onClick={() => setIsMorePopupOpened(true)}
                />

                {isMoreOptionsPopupOpened && (
                    <DocDetailMoreOptionsPopup
                        id={id}
                        setErrorAlert={setErrorAlert}
                        setSuccessAlert={setSuccessAlert}
                        requestKeysToMutate={docsRequestKeysToMutate}
                        openArchiveDocModal={() => setIsArchiveDocModalOpened(true)}
                        onClose={() => setIsMorePopupOpened(false)}
                    />
                )}
            </div>

            {isArchiveDocModalOpened && (
                <ArchiveDocModal
                    docData={{ id, label }}
                    requestKeysToMutate={docsRequestKeysToMutate}
                    navigateToDocsIndex={navigateToDocsIndex}
                    onClose={() => setIsArchiveDocModalOpened(false)}
                />
            )}

            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    description={successAlert.description}
                    position="top-center"
                    autoCloseInMS={5000}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default DocDetailTopControls;
