import React, { useMemo, useState } from 'react';

import {
    checkIfDisplayedRequiredFieldsFilled,
    checkIsFormDataValid,
    getSettingsFormDataInitialState,
} from '../../../../helpers/settingsFormUtils';
import { generateUUID } from '../../../../helpers/generateUUID';
import { updateOrderKeys } from '../../../../helpers/insertItemAtSpecificPositionWithOrderUpdate';

import Modal from '../../../../design-system/Modal/Modal';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import SettingsForm from '../SettingsForm';
import { Button } from '../../../../design-system';
import { DeleteBin4LineIcon } from '../../../../design-system/Icons';

const NestedItemModal = ({
    mode = 'add',
    item,
    properties,
    itemName,
    handleNestedInputChange,
    shouldShowHiddenSettings,
    onClose,
}) => {
    const { temporaryId, data } = item || {};

    const [formData, setFormData] = useState(() => {
        let formattedProperties = properties;

        if (mode === 'edit') {
            const propertiesWithAddedValues = properties.map((property) => ({
                ...property,
                value: data[property.name],
            }));
            formattedProperties = propertiesWithAddedValues;
        }
        return getSettingsFormDataInitialState(formattedProperties);
    });

    const handleAddNewItem = (newItemData) => {
        const newItem = {
            temporaryId: generateUUID(),
            data: newItemData,
        };
        handleNestedInputChange((prevData) => ({
            ...prevData,
            value: updateOrderKeys([...(prevData.value || []), newItem]),
        }));
    };

    const handleEditItem = (updatedItemData) => {
        const updatedItem = { ...item, data: updatedItemData };

        handleNestedInputChange((prevData) => {
            const updatedValue = prevData.value.map((item) =>
                item.temporaryId === temporaryId ? updatedItem : item
            );
            return { ...prevData, value: updatedValue };
        });
    };

    const action = {
        add: handleAddNewItem,
        edit: handleEditItem,
    };

    const handleSubmitForm = () => {
        const isFormDataValid = checkIsFormDataValid(
            formData,
            setFormData,
            !shouldShowHiddenSettings
        );
        if (!isFormDataValid) {
            return;
        }

        const data = formData.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {});

        const handleAction = action[mode];
        handleAction(data);

        onClose();
    };

    const handleDeleteItem = () => {
        handleNestedInputChange((prevData) => {
            const value = prevData.value.filter((item) => item.temporaryId !== temporaryId);
            return { ...prevData, value: updateOrderKeys(value) };
        });
        onClose();
    };

    const allRequiredFieldsAreFilled = useMemo(
        () =>
            checkIfDisplayedRequiredFieldsFilled({
                formData,
                areHiddenSettingsDisplayed: shouldShowHiddenSettings,
            }),
        [formData]
    );

    const submitButtonState = allRequiredFieldsAreFilled ? 'default' : 'disabled';

    const submitButtonText = {
        add: `Add ${itemName}`,
        edit: `Save ${itemName}`,
    };

    const modalTitle = {
        add: `Add ${itemName}`,
        edit: `Edit ${itemName}`,
    };

    return (
        <Modal onClose={onClose} size="mediumPlus" resetPadding>
            <div className="flex flex-col max-h-[75vh]">
                <ModalHeader
                    onClose={onClose}
                    title={modalTitle[mode]}
                    customPaddings="px-5 py-3"
                    outlined
                />

                <div className="px-5 py-5 flex-1 overflow-y-auto">
                    <SettingsForm
                        formData={formData}
                        setFormData={setFormData}
                        containerGap={24}
                        shouldShowHiddenSettings={shouldShowHiddenSettings}
                    />
                </div>

                <div className="px-5 py-3 flex items-center justify-between gap-2">
                    {mode === 'add' && (
                        <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                    )}

                    {mode === 'edit' && (
                        <Button
                            type="neutral"
                            size="sm"
                            text={`Delete ${itemName}`}
                            leadingIcon={DeleteBin4LineIcon}
                            onClick={handleDeleteItem}
                        />
                    )}

                    <Button
                        type="primary"
                        size="sm"
                        state={submitButtonState}
                        text={submitButtonText[mode]}
                        onClick={handleSubmitForm}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default NestedItemModal;
