import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { handleDuplicateDoc } from '../../../../helpers/docsUtils';
import { ListOption, ListOptionGroup } from '../../../../design-system';

const DocDetailMoreOptionsPopup = ({
    id,
    setSuccessAlert,
    setErrorAlert,
    requestKeysToMutate,
    openArchiveDocModal,
    onClose,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();

    const handleClose = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        onClose();
    };

    const duplicateDoc = async () => {
        await handleDuplicateDoc({
            id,
            location,
            setSuccessAlert,
            setErrorAlert,
            setIsLoading,
            closePopup: handleClose,
            requestKeysToMutate,
        });
    };

    const handleArchiveClick = () => {
        onClose();
        openArchiveDocModal();
    };

    return (
        <div className="absolute top-[120%] right-0 z-20 pb-5">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="copyIcon"
                            text="Duplicate"
                            isLoading={isLoading}
                            onClick={duplicateDoc}
                        />
                        <ListOption
                            leadingIconName="inboxArchiveLineIcon"
                            text="Archive"
                            onClick={handleArchiveClick}
                        />
                    </>
                }
                handleClose={handleClose}
            />
        </div>
    );
};

export default DocDetailMoreOptionsPopup;
