import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/report-api';

import { TAB } from '../../constants/jobsAndProjects';
import { jobsSortOptions, SORT_TYPES } from '../../constants/sort';
import { capitalizeStr } from '../../helpers/formatOrganizationDataForTable';
import { parseJobsSearchParams } from '../../helpers/parseJobsSearchParams';
import { getJobsRequestQueryParams } from '../../helpers/getJobsRequestQueryParams';

import useCustomPagination from '../../hooks/useCustomPagination';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useDefaultSearchParams } from '../../hooks/useDefaultSearchParams';
import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';
import { usePageParamSyncWithDevice } from '../../hooks/usePageParamSyncWithDevice';
import { useCollectListDataForMobileViews } from '../../hooks/useCollectListDataForMobileViews';
import { useSaveInContextRequestKeysWithMultipleAccessors } from '../../hooks/useSaveInContextRequestKeysWithMultipleAccessors';

import PageHeader from './PageHeader/PageHeader';
import PageTopControls, { tabs } from './PageTopControls/PageTopControls';
import PageMobilePaginatedContainer from './PageMobilePaginatedContainer/PageMobilePaginatedContainer';
import PageDesktopPaginatedContainer from './PageDesktopPaginatedContainer/PageDesktopPaginatedContainer';
import EmptyIndex from './EmptyIndex/EmptyIndex';
import JobCard from './JobCard/JobCard';
import ProjectCard from './ProjectCard/ProjectCard';

const route = {
    [TAB.jobs]: API.ROUTES.report.job,
    [TAB.projects]: API.ROUTES.report.project,
};
const DEFAULT_TAB = TAB.jobs;
const DEFAULT_TAB_ROUTE = route[DEFAULT_TAB];

const JobsAndProjectsPage = () => {
    const [searchParams] = useSearchParams();
    const parsedParams = useMemo(() => parseJobsSearchParams(searchParams), [searchParams]);

    const location = useLocation();
    const activeTab = location.pathname.includes(TAB.jobs) ? TAB.jobs : TAB.projects;
    const inactiveTab = activeTab === TAB.jobs ? TAB.projects : TAB.jobs;

    const [page, setPage] = useState(parsedParams.page || 1);
    const [shouldSetNewData, setShouldSetNewData] = useState(false); // this state is for mobile version (true when we should not merge new data but replace existing data)

    const { isMobile } = useResponsiveBreakpoints({ maxMobileWidth: 640 });

    useDocumentTitle(capitalizeStr(activeTab));

    const requestSearchParams = getJobsRequestQueryParams({
        parsedSearchParams: parsedParams,
        activeTab,
    });

    const dataHookResponse = useCustomPagination({
        pageIndex: page - 1,
        searchParams: requestSearchParams,
        client: client,
        route: route[activeTab] || DEFAULT_TAB_ROUTE,
    });

    const { data, key, nextPageKey } = dataHookResponse;

    const [collectedData, setCollectedData] = useCollectListDataForMobileViews({
        data,
        shouldSetNewData,
        setShouldSetNewData,
    });

    const requestKeysToMutate = useSaveInContextRequestKeysWithMultipleAccessors({
        key,
        nextPageKey,
        pageAccessor: activeTab,
        allPageAccessors: tabs,
    });

    useDefaultSearchParams({
        checkPage: false,
        checkSort: true,
        defaultSortType: SORT_TYPES.created,
        availableSortTypes: jobsSortOptions.map((item) => item.value),
    });

    usePageParamSyncWithDevice({ parsedParams, page, setPage, isMobile });

    const resetPageState = useCallback(() => {
        setPage(1);
        setShouldSetNewData(true);
    }, [setPage, setShouldSetNewData]);

    // cache data for inactive tab
    useCustomPagination({
        pageIndex: 0,
        searchParams: { ordering: SORT_TYPES.created },
        client: client,
        route: route[inactiveTab],
    });

    const CardComponent = activeTab === TAB.jobs ? JobCard : ProjectCard;

    return (
        <div className="page-position bg-neutral-50 px-4 xs:px-5 pt-4 sm:px-6 sm:pt-6 md:px-8 md:pt-8 pb-[40px] overflow-y-auto flex flex-col gap-5">
            <PageHeader activeTab={activeTab} />

            <PageTopControls
                key={`top-controls-${activeTab}`}
                activeTab={activeTab}
                isMobile={isMobile}
                resetPageState={resetPageState}
            />

            <EmptyIndex
                activeTab={activeTab}
                dataHookResponse={dataHookResponse}
                parsedParams={parsedParams}
            />

            {!isMobile && (
                <PageDesktopPaginatedContainer
                    page={page}
                    dataHookResponse={dataHookResponse}
                    requestKeysToMutate={requestKeysToMutate[TAB[activeTab] || []]}
                    setData={setCollectedData}
                    cardComponent={CardComponent}
                />
            )}

            {isMobile && (
                <PageMobilePaginatedContainer
                    data={collectedData}
                    setData={setCollectedData}
                    dataHookResponse={dataHookResponse}
                    setPage={setPage}
                    requestKeysToMutate={requestKeysToMutate[TAB[activeTab] || []]}
                    cardComponent={CardComponent}
                />
            )}
        </div>
    );
};

export default JobsAndProjectsPage;
