import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../services/assistant-api';

import useUser from './useUser';
import { chatGpt4oModel } from '../pages/AssistantPage/AIModelSelector/AIModelSelector';

export const useFetchChatThreadData = ({ chatThreadId }) => {
    const { user } = useUser();
    const navigate = useNavigate();

    const defaultIsDiviBotValue = useMemo(() => {
        if (!user) {
            return true;
        }

        const isChatGpt4oAvailable = !!user.organization.available_models?.find(
            ({ model }) => model === chatGpt4oModel
        );
        return isChatGpt4oAvailable;
    }, [user]);

    const [chatThreadData, setChatThreadData] = useState(null);
    const [controller, setController] = useState(new AbortController());

    const [isDiviBot, setIsDiviBot] = useState(defaultIsDiviBotValue);
    const [selectedAiModel, setSelectedAiModel] = useState(null);

    const isFirstRender = useRef(true);

    useEffect(() => {
        const fetchChatThreadData = async () => {
            try {
                setChatThreadData(null);

                controller.abort();

                const newController = new AbortController();
                setController(newController);

                const { data } = await client.get(
                    `${API.ROUTES.assistant.chatThread}${chatThreadId}/`,
                    { signal: newController.signal }
                );
                setChatThreadData(data);
                setIsDiviBot(data.is_divibot);
                setSelectedAiModel(data.settings.model);
            } catch (e) {
                if (e.message === 'canceled') {
                    return; // the next request is loading
                }

                navigate('/assistant');
            }
        };

        const resetChatThreadData = () => {
            controller.abort();

            setChatThreadData(null);
            setIsDiviBot(defaultIsDiviBotValue);
            setSelectedAiModel(defaultIsDiviBotValue ? chatGpt4oModel : null);
        };

        if (chatThreadId && chatThreadId !== chatThreadData?.id) {
            fetchChatThreadData();
        }

        if (!chatThreadId && !isFirstRender.current) {
            resetChatThreadData();
        }

        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
    }, [chatThreadId]);

    return {
        chatThreadData,
        setChatThreadData,
        selectedAiModel,
        setSelectedAiModel,
        isDiviBot,
        setIsDiviBot,
    };
};
