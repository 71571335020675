import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';

import useUser from '../../../../hooks/useUser';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../../../constants/organization';
import { mutateDataAfterRenamingBase } from '../../../../helpers/basesUtils';
import { useSwrRequestKeysToMutateFromContext } from '../../../../hooks/useSwrRequestKeysToMutateFromContext';

import { Button, SvgIcon } from '../../../../design-system';
import { ArrowGoBackLineIcon } from '../../../../design-system/Icons';
import ArchiveLineIcon from '../../../../design-system/Icons/ArchiveLineIcon';
import SearchBarWithUrlParamsHandling from '../../../../components/SearchBarWithUrlParamsHandling/SearchBarWithUrlParamsHandling';
import TextFieldWithCustomTextStyles, {
    emptyFieldError,
} from '../../../../design-system/TextFieldWithCustomTextStyles/TextFieldWithCustomTextStyles';

const BaseDetailPageHeader = ({ baseDetail, backLinkHref, setBaseDetail }) => {
    const { id, name } = baseDetail;

    const navigate = useNavigate();
    const location = useLocation();

    const { orgPlan, userRole } = useUser();
    const hasEditAccess =
        orgPlan === ORGANIZATION_PLAN.agency &&
        (userRole === USER_ROLE.admin || userRole === USER_ROLE.owner);

    const {
        requestKeysToMutate: { bases: requestKeysToMutate },
    } = useSwrRequestKeysToMutateFromContext();

    const saveUpdatedName = async (name) => {
        if (!name) {
            throw new Error(emptyFieldError);
        }

        setBaseDetail((prevData) => ({ ...prevData, name }));
        mutateDataAfterRenamingBase({
            id,
            requestKeysToMutate,
            updatedName: name,
        });
        await client.patch(`${API.ROUTES.knowledge.base}${id}/`, { name });
    };

    return (
        <div className="px-5 sm:px-6 py-5 bg-white">
            <Button
                type="link"
                size="xs"
                text="Back to Bases List"
                leadingIcon={ArrowGoBackLineIcon}
                onClick={() => navigate(backLinkHref, { state: { from: location } })}
            />

            <div className="flex gap-1 mb-2">
                <div className="w-[32px] h-[32px] min-w-[32px] rounded-2 bg-purple-500 flex items-center justify-center">
                    <SvgIcon color="#FFFFFF" icon={ArchiveLineIcon} size="medium" />
                </div>

                <div className="flex-grow my-auto max-w-[730px]">
                    {hasEditAccess ? (
                        <TextFieldWithCustomTextStyles
                            value={name}
                            minHeight={32}
                            isSaveOnBlur
                            isSaveOnEnterPress
                            handleSave={saveUpdatedName}
                            textStyle="font-body-bold text-body-bold-l text-black"
                            customTextFieldStyles="px-2 pb-0.5 pt-1.5 rounded-2 shadow-inset-1 shadow-transparent focus:shadow-purple-500 transition-all"
                        />
                    ) : (
                        <p className="font-body-bold text-body-bold-l text-black px-2">{name}</p>
                    )}
                </div>
            </div>

            <SearchBarWithUrlParamsHandling size="xs" maxWidth={680} placeholder="Search..." />
        </div>
    );
};

export default memo(BaseDetailPageHeader);
