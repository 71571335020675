import React, { useState } from 'react';
import classNames from 'classnames';

import SelectPromptBlock from '../SelectPromptBlock/SelectPromptBlock';
import ChatInputWrapper from '../ChatInputWrapper/ChatInputWrapper';

export const placeholder = 'Start chatting with AI or start with a Prompt';

const ChatInputGenericView = ({
    message,
    handleMessageChange,
    textareaRef,
    textareaContainerRef,
    commonButtonsGroup,
    handleFocusTextarea,
    handleSendMessageOnEnterPress,
    isMobile,
    isExpandedMode,
    setIsExpandedMode,
    handleAppendPromptToMessage,
    chatInputErrorMessage,
}) => {
    const [closeExpandedModeOnOutsideClick, setCloseExpandedModeOnOutsideClick] = useState(true);

    const textareaHeight = textareaRef.current?.clientHeight;

    const bottomButtonsGroup = (
        <div className="flex px-3 pb-2 pt-1 items-center justify-between gap-4">
            <SelectPromptBlock
                textareaHeight={textareaHeight}
                handleAppendPromptToMessage={handleAppendPromptToMessage}
                setCloseExpandedModeOnOutsideClick={setCloseExpandedModeOnOutsideClick}
                isMobile={isMobile}
            />

            {commonButtonsGroup}
        </div>
    );

    const chatInputContainerClassName = classNames(
        'flex flex-col rounded-2 shadow-inset-1 relative z-20 bg-white transition-colors',
        {
            'shadow-neutral-200': !chatInputErrorMessage,
            'shadow-red-500': chatInputErrorMessage,
        }
    );

    return (
        <ChatInputWrapper
            message={message}
            handleMessageChange={handleMessageChange}
            placeholder={placeholder}
            chatInputErrorMessage={chatInputErrorMessage}
            bottomButtonsGroup={bottomButtonsGroup}
            isExpandedMode={isExpandedMode}
            setIsExpandedMode={setIsExpandedMode}
            isMobile={isMobile}
            closeExpandedModeOnOutsideClick={closeExpandedModeOnOutsideClick}
        >
            <div className={chatInputContainerClassName}>
                <div className="cursor-text px-3 py-2.5" onClick={handleFocusTextarea}>
                    <div ref={textareaContainerRef}>
                        <textarea
                            ref={textareaRef}
                            value={message}
                            placeholder={placeholder}
                            rows={1}
                            onChange={handleMessageChange}
                            onKeyDown={handleSendMessageOnEnterPress}
                            className="max-w-full w-full focus:outline-0 resize-none bg-transparent placeholder-neutral-300 font-body text-body-regular-s text-neutral-500"
                        />
                    </div>
                </div>

                {bottomButtonsGroup}
            </div>
        </ChatInputWrapper>
    );
};

export default ChatInputGenericView;
