import React from 'react';
import { SvgIcon } from '../index';
import { ErrorWarningLineIcon } from '../Icons';

const ErrorMessage = ({ message, highlightedStart, customStyles }) => {
    return (
        <div className={`flex gap-1 ${customStyles}`}>
            <SvgIcon color="#E95B69" icon={ErrorWarningLineIcon} size="medium" />
            <p className="font-body text-body-regular-xs text-red-500">
                {highlightedStart && (
                    <span className="text-body-bold-xs">{highlightedStart}: </span>
                )}{' '}
                {message}
            </p>
        </div>
    );
};

export default ErrorMessage;
