import { TASK_TYPES, TASK_TYPES_ICON, TASK_TYPES_LABEL } from '../constants/library';

export const getTaskTypeBadge = ({
    taskType,
    model = '',
    usePromptLabelInsteadOfModel = false,
    usePluralLabels = false,
}) => {
    const label = `${TASK_TYPES_LABEL[taskType]}${usePluralLabels ? 's' : ''}`;

    const badge = {
        [TASK_TYPES.prompt]: {
            label: usePromptLabelInsteadOfModel
                ? `Prompt Task${usePluralLabels ? 's' : ''}`
                : model,
            color: 'neutral',
            icon: TASK_TYPES_ICON[taskType],
            iconColor: '#1F2125',
        },
        [TASK_TYPES.inbox]: {
            label,
            color: 'neutral',
            icon: TASK_TYPES_ICON[taskType],
            iconColor: '#1F2125',
        },
        [TASK_TYPES.retrieval]: {
            label,
            color: 'neutral',
            icon: TASK_TYPES_ICON[taskType],
            iconColor: '#1F2125',
        },
        [TASK_TYPES.trigger]: {
            label,
            color: 'neutral',
            icon: TASK_TYPES_ICON[taskType],
            iconColor: '#1F2125',
        },
        [TASK_TYPES.webhook]: {
            label,
            color: 'neutral',
            icon: TASK_TYPES_ICON[taskType],
            iconColor: '#1F2125',
        },
        [TASK_TYPES.assistant]: {
            label,
            color: 'neutral',
            icon: TASK_TYPES_ICON[taskType],
            iconColor: '#1F2125',
        },
        [TASK_TYPES.record]: {
            label,
            color: 'neutral',
            icon: TASK_TYPES_ICON[taskType],
            iconColor: '#1F2125',
        },
        [TASK_TYPES.api]: {
            label,
            color: 'neutral',
            icon: TASK_TYPES_ICON[taskType],
            iconColor: '#1F2125',
        },
        [TASK_TYPES.regex]: {
            label,
            color: 'neutral',
            icon: TASK_TYPES_ICON[taskType],
            iconColor: '#1F2125',
        },
        [TASK_TYPES.function]: {
            label,
            color: 'neutral',
            icon: TASK_TYPES_ICON[taskType],
            iconColor: '#1F2125',
        },
        [TASK_TYPES.doc]: {
            label,
            color: 'neutral',
            icon: TASK_TYPES_ICON[taskType],
            iconColor: '#1F2125',
        },
        [TASK_TYPES.transform]: {
            label,
            color: 'neutral',
            icon: TASK_TYPES_ICON[taskType],
            iconColor: '#1F2125',
        },
    };

    return badge[taskType];
};
