import React, { useEffect, useState } from 'react';
import { Button } from 'design-system';
import { useDeleteRequest, useGetRequest, usePostRequest, usePatchRequest } from 'hooks/request';
import * as API from 'constants/api';
import externalClient from 'services/external-api';
import Table from 'design-system/Table/Table';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import EditApiKeyModal from 'pages/OrganizationPage/EditApiKeyModal/EditApiKeyModal';
import { transformDataApiKeysTable } from 'helpers/formatApiKeyDataForTable';

const ApiKeyBlock = ({orgId}) => {
    const [editApiKeyModal, setEditApiKeyModal] = useState({
        opened: false,
        data: null,
        mode: 'create',
    });
    const [deleteApiKeyConfirmModal, setDeleteApiKeyConfirmModal] = useState({
        opened: false,
        itemId: null,
    });

    const [apiKeysTableData, setApiKeysTableData] = useState({ columns: [], rows: [] });
    const [isLoading, setIsLoading] = useState(true);

    const apiKeysData = useGetRequest({ url: `${API.ROUTES.apikey.apikey}?organization=${orgId}` }, externalClient);
    const apiKeyDelete = useDeleteRequest({}, externalClient);
    const apiKeyCreate = usePostRequest({ url: API.ROUTES.apikey.apikey }, externalClient);
    const apiKeyEdit = usePatchRequest({}, externalClient);

    const fetchData = async () => {
        setIsLoading(true);
        const { response, success, error } = await apiKeysData.request();
        if (success) {
            const transformedData = transformDataApiKeysTable(response.results);
            console.log(transformedData);
            setApiKeysTableData(transformedData);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleUpdateApiKey = () => {
        fetchData();
    };

    const handleEditApiKey = async (apiKeyId) => {
        const { success, error } = await apiKeyEdit.request({
            data: { name: editApiKeyModal.data.name },
            url: `${API.ROUTES.apikey.apikey}${apiKeyId}/`,
        });
        if (success) {
            setEditApiKeyModal({
                opened: false,
            });
            fetchData();
        }
    };

    const handleDeleteApiKeyConfirm = async (apiKeyId) => {
        const { success, error } = await apiKeyDelete.request({
            url: `${API.ROUTES.apikey.apikey}${apiKeyId}/?organization=${orgId}`,
        });
        if (success) {
            setDeleteApiKeyConfirmModal({
                opened: false,
            });
            fetchData();
        }
    };

    const apiKeyRowActionButtons = [
        // {
        //     action: 'edit',
        //     handleAction: (row) => {
        //         setEditApiKeyModal({
        //             opened: true,
        //             data: row,
        //             mode: 'edit',
        //         });
        //     },
        // },
        {
            action: 'delete',
            handleAction: (row) => {
                setDeleteApiKeyConfirmModal({
                    opened: true,
                    itemId: row.id,
                });
            },
        },
    ];

    return (
        <div className="">
            {deleteApiKeyConfirmModal.opened && (
                <ConfirmationModal
                    onClose={() =>
                        setDeleteApiKeyConfirmModal({
                            opened: false,
                        })
                    }
                    onConfirm={handleDeleteApiKeyConfirm}
                    itemId={deleteApiKeyConfirmModal.itemId}
                    confirmText="Are you sure to delete this API key?"
                />
            )}

            {editApiKeyModal.opened && (
                <EditApiKeyModal
                    data={editApiKeyModal.data}
                    onClose={() =>
                        setEditApiKeyModal({
                            opened: false,
                            data: null,
                            mode: 'create',
                        })
                    }
                    apiKeyCreate={apiKeyCreate}
                    apiKeyEdit={handleEditApiKey}
                    handleSave={handleUpdateApiKey}
                    mode={editApiKeyModal.mode}
                    orgId={orgId}
                />
            )}

            {apiKeysTableData.rows && apiKeysTableData.rows.length > 0 && (
                <div className="p-4 md:pt-4 md:pb-8 md:px-8 rounded-md flex flex-col w-full bg-white space-y-4">
                    <div className="font-body-bold text-body-bold-l text-neutral-500">
                    Your DiviUp API Keys
                    </div>
                    <div className="w-full relative">
                        <Table
                            data={apiKeysTableData || {}}
                            size="md"
                            rowActionButtons={apiKeyRowActionButtons}
                            tableActionButton={{
                                buttonText: 'Create new API Key',
                                buttonSize: 'sm',
                                onClick: () => {
                                    setEditApiKeyModal({
                                        opened: true,
                                    });
                                },
                            }}
                            columnsCount={3}
                            includePagination={false}
                            shift={130}
                        />
                    </div>
                </div>
            )}
            {(!apiKeysTableData.rows || apiKeysTableData.rows.length === 0) && (
                <div className="p-4 md:pt-4 md:pb-8 md:px-8 rounded-md flex flex-col w-full bg-white space-y-4">
                    {/* <div className="font-body-bold text-body-bold-m"> */}
                    <div className="font-body-bold text-body-bold-l text-neutral-500">
                        Click below to create your first DiviUp API Key
                    </div>
                    <div>
                        <Button
                            type="primary"
                            text="Create an API Key"
                            size="sm"
                            onClick={() => setEditApiKeyModal({ opened: true })}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ApiKeyBlock;
