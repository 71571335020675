import React from 'react';
import { Input } from '../../../../design-system';
import JsonTextareaBox from '../../../../design-system/JsonTextareaBox/JsonTextareaBox';

const ScenarioVersionDetails = ({
    selectedVersionFormData,
    handleInputChange,
    fieldErrorMessages,
    shouldRefreshConfigFile,
    setShouldRefreshConfigFile,
    isEditable,
    setFieldErrorMessages,
}) => {
    const handleJsonValueChange = (value) => {
        handleInputChange('config', value);
        setFieldErrorMessages((prevMessages) => ({ ...prevMessages, config: null }));
    };

    return (
        <div className="flex flex-col gap-5 flex-1">
            <Input
                size="sm"
                value={selectedVersionFormData.name}
                name="name"
                onChange={(e) => handleInputChange('name', e.target.value)}
                state={!isEditable ? 'disabled' : fieldErrorMessages.name ? 'error' : 'default'}
                errorMessage={fieldErrorMessages.name}
                label="Version Name"
                isRequired
                placeholder="Enter Version Name"
            />

            <JsonTextareaBox
                mode="code"
                value={selectedVersionFormData.config}
                label="CONFIG FILE"
                isRequired
                state={!isEditable ? 'disabled' : fieldErrorMessages.config ? 'error' : 'default'}
                errorMessage={fieldErrorMessages.config}
                setValue={handleJsonValueChange}
                withCopyButton
                withDownloadButton
                shouldRefreshValue={shouldRefreshConfigFile}
                setShouldRefreshValue={setShouldRefreshConfigFile}
                autoExpand
                parentGap={20}
            />
        </div>
    );
};

export default ScenarioVersionDetails;
