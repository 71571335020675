import React, { memo, useMemo } from 'react';
import classNames from 'classnames';

import Checkbox from '../../../design-system/Checkbox/Checkbox';

export const rowMinWidthStyles = 'max-md:min-w-[700px] max-md:w-[700px]';
export const docItemGridColsStyles =
    'grid grid-cols-[24px_minmax(150px,_1.5fr)_minmax(132px,_1fr)_minmax(132px,_1fr)_24px] lg:grid-cols-[24px_minmax(150px,_1.1fr)_minmax(132px,_1fr)_minmax(132px,_1fr)_24px] gap-3 md:gap-4 px-4 items-center';

const DocsColumnHeader = ({ selectedDocIds, setSelectedDocIds, allCurrentPageDocIds }) => {
    const allDocsOnCurrentPageSelected = useMemo(() => {
        if (!allCurrentPageDocIds.length || !Object.keys(selectedDocIds).length) {
            return false;
        }

        const hasUnselectedDocsOnCurrentPage = allCurrentPageDocIds.some(
            (id) => !selectedDocIds[id]
        );

        return !hasUnselectedDocsOnCurrentPage;
    }, [selectedDocIds, allCurrentPageDocIds]);

    const handleSelectAllDocsOnPageChange = () => {
        if (!allDocsOnCurrentPageSelected) {
            const currentPageSelectedDocs = allCurrentPageDocIds.reduce(
                (acc, id) => ({ ...acc, [id]: true }),
                {}
            );
            setSelectedDocIds((prevSelectedIds) => ({
                ...prevSelectedIds,
                ...currentPageSelectedDocs,
            }));
            return;
        }

        if (allDocsOnCurrentPageSelected) {
            const currentPageUnselectedDocs = allCurrentPageDocIds.reduce(
                (acc, id) => ({ ...acc, [id]: false }),
                {}
            );
            setSelectedDocIds((prevSelectedIds) => ({
                ...prevSelectedIds,
                ...currentPageUnselectedDocs,
            }));
        }
    };

    const containerClassNames = classNames(
        'py-1.5 rounded-2 bg-[#CFD6E54D] mb-1',
        docItemGridColsStyles,
        rowMinWidthStyles
    );

    return (
        <div className={containerClassNames}>
            <Checkbox
                name="all-docs"
                isChecked={allDocsOnCurrentPageSelected}
                onChange={handleSelectAllDocsOnPageChange}
            />

            <p className="font-body-bold text-body-bold-xs text-neutral-500 truncate">Doc Name</p>
            <p className="font-body-bold text-body-bold-xs text-neutral-500 truncate">
                Last updated at
            </p>
            <p className="font-body-bold text-body-bold-xs text-neutral-500 truncate">
                Last updated by
            </p>
        </div>
    );
};

export default memo(DocsColumnHeader);
