import React from 'react';

import { containerPaddings } from '../BaseEntriesIndex/BaseEntriesIndex';

import EntryCard from '../EntryCard/EntryCard';
import PaginatedContainer from '../../../../components/PaginatedContainer/PaginatedContainer';

const EntriesList = ({ page, baseEntriesHookResponse }) => {
    const { data: entries, total, count } = baseEntriesHookResponse;

    return (
        <div className={`${containerPaddings} flex flex-col gap-5`}>
            <PaginatedContainer page={page} count={count} total={total}>
                {!!entries?.length && (
                    <ul className="flex flex-col gap-2">
                        {entries.map((entry) => (
                            <EntryCard key={entry.uuid} entry={entry} />
                        ))}
                    </ul>
                )}
            </PaginatedContainer>
        </div>
    );
};

export default EntriesList;
