import React, { useEffect, useState } from 'react';
import { mutate } from 'swr';

import { API } from 'constants';
import client from '../../../services/knowledge-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { mutateKeyData } from '../../../helpers/swrMutateUtils';
import { insertItemAtSpecificIndexInArray } from '../../../helpers/insertItemAtSpecificIndexInArray';

import Alert from '../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import Loading from '../../../components/Loading';

const SuccessArchiveDocAlert = ({ data, requestKey, setErrorAlert, handleClose }) => {
    const { archivedDocId, archivedDocLabel, archivedDocIndex } = data;

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(false);
    }, [archivedDocId]);

    if (!archivedDocId) {
        return <></>;
    }

    const handleUnarchiveDoc = async () => {
        try {
            setIsLoading(true);

            const { data } = await client.patch(
                `${API.ROUTES.knowledge.document}${archivedDocId}/?is_deleted=true`,
                {
                    is_deleted: false,
                }
            );

            const shouldRefetchDocs = archivedDocIndex === undefined || archivedDocIndex === null;
            if (!shouldRefetchDocs) {
                mutateKeyData({
                    key: requestKey,
                    revalidate: true,
                    cb: (currentData) => {
                        const updatedResults = insertItemAtSpecificIndexInArray(
                            currentData.results,
                            data,
                            archivedDocIndex || 0
                        );

                        return {
                            ...currentData,
                            results: updatedResults,
                            count: currentData.count + 1,
                        };
                    },
                });
            }
            if (shouldRefetchDocs && requestKey) {
                await mutate(requestKey);
            }

            handleClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
            handleClose();
        }
    };

    const Description = (
        <div className="relative">
            <button
                type="button"
                onClick={handleUnarchiveDoc}
                disabled={isLoading}
                className={`font-[inherit] text-[inherit] underline ${
                    isLoading ? 'opacity-0' : 'opacity-100'
                }`}
            >
                Undo
            </button>

            {isLoading && (
                <div className="absolute top-1/2 transform translate-y-[-50%] left-1">
                    <Loading withText={false} size="extraSmall" />
                </div>
            )}
        </div>
    );

    return (
        <Alert
            status="positive"
            message={`Doc ”${archivedDocLabel}” archived`}
            description={Description}
            icon={CheckLineIcon}
            position="top-center"
            autoCloseDisabled={isLoading}
            autoCloseInMS={5000}
            handleClose={handleClose}
        />
    );
};

export default SuccessArchiveDocAlert;
