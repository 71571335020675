import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { BLOCK_TYPE } from '../../../constants/playbookBuilder';
import RichTextArea from '../../RichTextArea/RichTextArea';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

RichTextBlock.propTypes = {
    blockData: PropTypes.shape({
        order: PropTypes.number,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        temporaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        block_type: PropTypes.oneOf([BLOCK_TYPE.richtext]),
        properties: PropTypes.shape({
            content: PropTypes.string,
        }).isRequired,
    }),
    editMode: PropTypes.bool,
    handleDeleteBlock: PropTypes.func.isRequired,
    handleBlockPropertyChange: PropTypes.func.isRequired,
};

function RichTextBlock({
    blockData,
    editMode = false,
    handleDeleteBlock,
    handleBlockPropertyChange,
}) {
    const {
        properties: { content },
    } = blockData;

    const { isDesktop } = useResponsiveBreakpoints({ minDesktopWidth: 1170 });

    return (
        <div className={!editMode ? 'p-2.5' : ''}>
            {editMode && (
                <RichTextArea
                    value={content}
                    setValue={(value) => handleBlockPropertyChange('content', value)}
                    minHeight={140}
                    autoExpand
                    placeholder="Enter content here"
                    withExtendedToolBar
                    handleDelete={handleDeleteBlock}
                    isMobileViewActive={!isDesktop}
                    useInternalMobileViewState={false}
                />
            )}
            {!editMode && (
                <RichTextArea
                    value={content}
                    previewOnly
                    minHeight={20}
                    autoExpand
                    isBorderHidden
                />
            )}
        </div>
    );
}

export default memo(RichTextBlock);
