import React, { useState } from 'react';

import { SvgIcon } from '../../../design-system';
import DownloadCloudIcon from '../../../design-system/Icons/DownloadCloudIcon';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import LoaderFillIcon from '../../../design-system/Icons/LoaderFillIcon';

const SyncPlaybookDataButton = ({ handleSync }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isToolTipShown, setIsToolTipShown] = useState(false);

    const handleSyncButtonClick = async () => {
        setIsLoading(true);
        setIsToolTipShown(false);

        await handleSync();

        setIsLoading(false);
    };

    return (
        <div
            onMouseEnter={() => setIsToolTipShown(true)}
            onMouseLeave={() => setIsToolTipShown(false)}
        >
            <ToolTip
                text={isLoading ? 'Playbook is syncing' : 'Sync Playbook'}
                position="top-right"
                shift="5px"
                isShown={isToolTipShown}
            >
                <button
                    className="flex w-[40px] h-[40px] min-w-[40px] items-center justify-center"
                    disabled={isLoading}
                    onClick={handleSyncButtonClick}
                >
                    {!isLoading && (
                        <SvgIcon color="#754DCF" icon={DownloadCloudIcon} size="large" />
                    )}
                    {isLoading && (
                        <span className="animate-icon-spin">
                            <SvgIcon icon={LoaderFillIcon} size="large" color="#754DCF" />
                        </span>
                    )}
                </button>
            </ToolTip>
        </div>
    );
};

export default SyncPlaybookDataButton;
