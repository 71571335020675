import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import templateClient from '../../../services/template-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import {
    checkIsFormDataValid,
    checkIsRequiredNonHiddenFieldFilled,
    formatStepFormDataForRequestBody,
    getSettingsFormDataInitialState,
} from '../../../helpers/settingsFormUtils';
import { useWebSocketListeningLoadingState } from '../../../hooks/useWebSocketListeningLoadingState';
import useDocumentTitle from 'hooks/useDocumentTitle';

import { ArrowGoBackLineIcon } from '../../../design-system/Icons';
import Button from '../../../design-system/Button/Button';
import Loading from '../../../components/Loading';
import Alert from '../../../design-system/Alert/Alert';
import ErrorWarningLineIcon from '../../../design-system/Icons/ErrorWarningLineIcon';
import ErrorBanner from '../../../design-system/ErrorBanner/ErrorBanner';
import SubmissionLoader from '../../../design-system/SubmissionLoader/SubmissionLoader';
import SubmissionSuccessBlock from '../../../design-system/SubmissionSuccessBlock/SubmissionSuccessBlock';
import RunIntegrationTestForm from './RunIntegrationTestForm/RunIntegrationTestForm';

const RunIntegrationTestPage = () => {
    const { processTemplateId } = useParams();

    const [searchParams] = useSearchParams();
    const versionId = searchParams.get('version') || null;

    const navigate = useNavigate();
    const location = useLocation();
    const backlinkUrl = location.state?.from ?? `/templates/process/${processTemplateId}`;

    const [processTemplateData, setProcessTemplateData] = useState(null);
    const [branchDetails, setBranchDetails] = useState(null);

    const formDataInitialState = {
        integrationTestSettings: {
            version: versionId,
            organization: null,
        },
        processSettings: {
            settings: null,
        },
    };

    const [formData, setFormData] = useState(formDataInitialState);

    const areIntegrationTestSettingsFilled = useMemo(() => {
        const { version, organization } = formData.integrationTestSettings;
        return !!version && !!organization;
    }, [formData.integrationTestSettings]);

    const areProcessSettingsFilled = useMemo(() => {
        const { settings } = formData.processSettings;
        return !!settings && settings?.every(checkIsRequiredNonHiddenFieldFilled);
    }, [formData.processSettings]);

    const isFormFilled = areIntegrationTestSettingsFilled && areProcessSettingsFilled;
    const [isProcessSettingsFormBlockOpenable, setIsProcessSettingsFormBlockOpenable] =
        useState(false);

    const [isExpanded, setIsExpanded] = useState({
        integrationTestSettings: true,
        processSettings: false,
    });

    const [formSubmissionState, setFormSubmissionState] = useState('default'); // loading, success, default
    const [errorBannerMessage, setErrorBannerMessage] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);
    // settings error messages renders SettingsForm
    const [fieldsErrorMessage, setFieldsErrorMessage] = useState({
        organization: null,
    });

    const [controller, setController] = useState(new AbortController()); // state to manage the AbortController instance used for aborting requests

    useDocumentTitle('Run Integration Test');

    const { addRequestUuid, clearRequestUuid, tryReconnectIfWebsocketClosed } =
        useWebSocketListeningLoadingState({
            messageType: 'integration_test_result',
            onSuccess: onWebsocketSuccess,
            onError: onWebsocketError,
        });

    function onWebsocketSuccess() {
        setFormSubmissionState('success');
    }

    function onWebsocketError(message) {
        setErrorBannerMessage(message.error);
        setErrorAlert({ message: defaultErrorMessage });
        setFormSubmissionState('default');
    }

    useEffect(() => {
        const fetchProcessTemplateData = async () => {
            try {
                const { data } = await templateClient.get(
                    `${API.ROUTES.template.processTemplate}${processTemplateId}/`
                );
                setProcessTemplateData(data);
            } catch (e) {
                navigate(backlinkUrl);
            }
        };

        fetchProcessTemplateData();
    }, []);

    useEffect(() => {
        const fetchBranchDetails = async () => {
            setBranchDetails(null);
            controller.abort(); // cancel the previous request if it's still active

            // create and set the new controller as the active one
            const newController = new AbortController();
            setController(newController);

            try {
                const { version, organization } = formData.integrationTestSettings;
                const { data } = await templateClient.get(
                    `${API.ROUTES.template.processTemplateVersion}${version}/test/?organization=${organization}`,
                    { signal: newController.signal }
                );
                setBranchDetails(data);
                updateFormDataBasedOnBranchDetailsResponse(data);
            } catch (error) {
                if (error.message === 'canceled') {
                    return; // the next request is loading
                }
                navigate(backlinkUrl);
            }
        };

        if (areIntegrationTestSettingsFilled) {
            clearProcessSettingsFormData();
            fetchBranchDetails();
        }
    }, [formData.integrationTestSettings, areIntegrationTestSettingsFilled]);

    useEffect(() => {
        if (areIntegrationTestSettingsFilled) {
            // make Process Settings block enable
            setIsProcessSettingsFormBlockOpenable(true);
        }
    }, [areIntegrationTestSettingsFilled]);

    const updateFormDataBasedOnBranchDetailsResponse = (data) => {
        const formattedSettingsList = data?.settings?.reduce(
            (acc, step) => [...acc, ...(step.settings || [])],
            []
        );
        setFormData((prevData) => ({
            ...prevData,
            processSettings: {
                settings: getSettingsFormDataInitialState(formattedSettingsList) || [],
            },
        }));
    };

    const clearErrorStates = () => {
        setFieldsErrorMessage({});
        setErrorBannerMessage(null);
        setErrorAlert(null);
    };

    const clearProcessSettingsFormData = () => {
        setFormData((prevData) => ({
            ...prevData,
            processSettings: formDataInitialState.processSettings,
        }));
        clearErrorStates();
    };

    const setSectionFormData = (section, cb) => {
        setFormData((prevData) => ({
            ...prevData,
            [section]: typeof cb === 'function' ? cb(prevData[section]) : cb,
        }));
    };

    const setUpdatedProcessSettingsFormData = (updatedData) =>
        setFormData((prevData) => ({
            ...prevData,
            processSettings: { settings: updatedData },
        }));

    const getRequestBody = () => {
        const settings = formatStepFormDataForRequestBody(formData.processSettings.settings);
        return { settings };
    };

    const handleProcessSettingsFormError = (errorObject) => {
        const { settings } = branchDetails;
        const settingsFormData = formData.processSettings.settings;
        // keeps data in format [{stepName:[step.name], settingsFormFieldsName: [step.settings[0].name, step.settings[1].name, ...}]]
        const stepsNamesMapping =
            settings?.reduce(
                (acc, step) => [
                    ...acc,
                    {
                        stepName: [step.name],
                        settingsFormFieldsName: step.settings?.map((item) => item.name),
                    },
                ],
                []
            ) || [];

        const updatedFormData = settingsFormData.map((formField) => {
            const currentFormFieldStepName = stepsNamesMapping.find((step) =>
                step.settingsFormFieldsName?.includes(formField.name)
            )?.stepName;
            const currentFieldErrorMessage =
                errorObject[currentFormFieldStepName]?.[formField.name];

            if (currentFieldErrorMessage) {
                return { ...formField, state: 'error', errorMessage: currentFieldErrorMessage };
            }

            return formField;
        });

        setUpdatedProcessSettingsFormData(updatedFormData);
    };

    const handleStartRun = async () => {
        clearErrorStates();

        // check if Inputted Process Setting Data is valid (is json valid and ets.)
        const isProcessSettingsFormDataValid = checkIsFormDataValid(
            formData.processSettings.settings,
            setUpdatedProcessSettingsFormData
        );
        if (!isProcessSettingsFormDataValid) {
            return;
        }

        try {
            setFormSubmissionState('loading');
            const requestBody = getRequestBody();
            const { version, organization } = formData.integrationTestSettings;
            const { data } = await templateClient.post(
                `${API.ROUTES.template.processTemplateVersion}${version}/test/?organization=${organization}`,
                requestBody
            );
            if (data.status === 'started' && data.request_uuid) {
                addRequestUuid(data.request_uuid);
            }
            tryReconnectIfWebsocketClosed();
        } catch (error) {
            setFormSubmissionState('default');

            // errors handling during validation
            const statusCode = error.response?.status;
            const errorData = error.response?.data?.error;

            if (statusCode === 400 && errorData && typeof errorData === 'string') {
                setErrorBannerMessage(errorData);
                setErrorAlert({ message: defaultErrorMessage });
            } else if (statusCode === 400 && errorData && typeof errorData === 'object') {
                setErrorAlert({ message: defaultErrorMessage });
                // expand every block to show all related errors
                setIsExpanded({
                    integrationTestSettings: true,
                    processSettings: true,
                });
                // handle settings error
                if (errorData.settings) {
                    handleProcessSettingsFormError(errorData.settings);
                }

                // handle not settings errors
                const { settings, ...restErrors } = errorData;
                setFieldsErrorMessage(restErrors || {});
            } else {
                setErrorAlert({ message: defaultErrorMessage });
            }
            clearRequestUuid();
        }
    };

    const isBranchPreselectLoading = versionId && !processTemplateData;

    return (
        <div className="bg-white w-full flex justify-center h-full px-[14px] pb-[40px] min-h-[calc(100vh-64px)]">
            <div className="w-full max-w-[800px] py-5">
                <div className={`${isBranchPreselectLoading && 'h-0 max-h-0 overflow-hidden'}`}>
                    <div className="mb-5">
                        <Button
                            type="link"
                            size="sm"
                            text={`Back to ${processTemplateData?.name || 'Process Template'}`}
                            leadingIcon={ArrowGoBackLineIcon}
                            onClick={() => navigate(backlinkUrl)}
                        />
                    </div>
                    <h1 className="font-heading-bold text-heading-bold-s text-black mb-8">
                        Run Integration Test
                    </h1>

                    {errorBannerMessage && (
                        <div className="mb-8">
                            <ErrorBanner errorMessage={errorBannerMessage} />
                        </div>
                    )}

                    <div
                        className={`flex flex-col items-start gap-5 ${
                            formSubmissionState !== 'default' && 'h-0 max-h-0 overflow-hidden'
                        }`}
                    >
                        <RunIntegrationTestForm
                            formData={formData}
                            processTemplateData={processTemplateData}
                            fieldsErrorMessage={fieldsErrorMessage}
                            setFieldsErrorMessage={setFieldsErrorMessage}
                            isFormFilled={isFormFilled}
                            handleStartRun={handleStartRun}
                            setSectionFormData={setSectionFormData}
                            isProcessSettingsFormBlockOpenable={isProcessSettingsFormBlockOpenable}
                            isExpanded={isExpanded}
                            setIsExpanded={setIsExpanded}
                        />
                    </div>

                    {formSubmissionState === 'loading' && (
                        <SubmissionLoader
                            text="Integration Test running!"
                            bottomText="Stay on this page to see results..."
                        />
                    )}

                    {formSubmissionState === 'success' && (
                        <SubmissionSuccessBlock
                            title="Integration Test successful!"
                            description="Start another Test for this Template or go to all Process Templates"
                            topButtonText="Start another Test"
                            bottomButtonText="Go to All Process Templates"
                            handleTopButtonClick={() => setFormSubmissionState('default')}
                            handleBottomButtonClick={() => navigate('/templates/processes')}
                        />
                    )}
                </div>
                {isBranchPreselectLoading && (
                    <div className="h-full flex-grow self-center flex justify-center">
                        <Loading />
                    </div>
                )}
                {errorAlert && (
                    <Alert
                        status="critical"
                        message={errorAlert.message}
                        icon={ErrorWarningLineIcon}
                        statusCode={errorAlert.statusCode}
                        handleClose={() => setErrorAlert(null)}
                    />
                )}
            </div>
        </div>
    );
};

export default RunIntegrationTestPage;
