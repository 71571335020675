import React, { memo, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';

import { docItemGridColsStyles } from '../DocsColumnHeader/DocsColumnHeader';

import { ButtonIcon, SvgIcon } from '../../../design-system';
import { More2FillIcon } from '../../../design-system/Icons';
import UserBadge from '../../../design-system/UserBadge/UserBadge';
import FileTextIcon from '../../../design-system/Icons/FileTextIcon';
import Checkbox from '../../../design-system/Checkbox/Checkbox';

const DocCard = ({
    doc,
    isSelected = false,
    setSelectedDocIds,
    setDocMoreOptionsPopup,
    allCurrentPageDocIds,
}) => {
    const {
        id,
        label,
        last_updated_by: { name, updated_at },
    } = doc;

    const location = useLocation();

    const moreOptionsButtonRef = useRef(null);
    const moreOptionsButtonTopPosition = useRef(0);

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy, HH:mm:ss');

    useEffect(() => {
        if (moreOptionsButtonRef.current) {
            const rect = moreOptionsButtonRef.current.getBoundingClientRect();
            moreOptionsButtonTopPosition.current = rect?.top;
        }
    }, [moreOptionsButtonRef, allCurrentPageDocIds]);

    const openMoreOptionsPopup = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setDocMoreOptionsPopup({
            isOpened: true,
            docData: { id, label },
            buttonTopPosition: moreOptionsButtonTopPosition.current,
        });
    };

    const handleCheckboxChange = () => {
        setSelectedDocIds((prevSelectedIds) => ({
            ...prevSelectedIds,
            [id]: !prevSelectedIds[id],
        }));
    };

    return (
        <li className="w-full relative">
            <div className="absolute top-1/2 transform translate-y-[-50%] left-4 z-40">
                <Checkbox isChecked={isSelected} name={label} onChange={handleCheckboxChange} />
            </div>

            <Link
                to={`/doc/${id}`}
                state={{ from: location }}
                className={`py-3 bg-white rounded-2 ${docItemGridColsStyles}`}
            >
                <div className="opacity-0">Checkbox</div>

                <div className="flex items-center gap-3 lg:gap-4">
                    <div className="w-[32px] h-[32px] min-w-[32px] rounded-2 flex items-center justify-center bg-purple-100">
                        <SvgIcon icon={FileTextIcon} color="#754DCF" size="medium" />
                    </div>
                    <p className="font-body text-body-regular-s text-black flex-grow truncate">
                        {label}
                    </p>
                </div>
                <div className="font-body-medium text-body-regular-xs text-neutral-500 truncate">
                    {formattedTime}
                </div>

                <UserBadge name={name} color="purple" />

                <div ref={moreOptionsButtonRef}>
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={More2FillIcon}
                        onClick={openMoreOptionsPopup}
                    />
                </div>
            </Link>
        </li>
    );
};

export default memo(DocCard);
