import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import templateClient from '../../services/template-api';
import executeClient from '../../services/execute-api';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { defaultErrorMessage } from '../../constants/errorMessages';

import Button from '../../design-system/Button/Button';
import UnitTestSettingsBlock from './UnitTestSettingsBlock/UnitTestSettingsBlock';
import Loading from '../../components/Loading';
import DatasetSettingsBlock from './DatasetSettingsBlock/DatasetSettingsBlock';
import GoalSettingsBlock from './GoalSettingsBlock/GoalSettingsBlock';
import SuccessfulSubmitBlock from './SuccessfulSubmitBlock/SuccessfulSubmitBlock';
import { ArrowGoBackLineIcon, FlashlightFillIcon } from '../../design-system/Icons';
import {
    checkIsFormDataValid,
    checkIsRequiredFieldFilled,
    checkIsRequiredNonHiddenFieldFilled,
    formatSettingsFormDataForRequestBody,
    getMappedSettingsFormErrors,
    getSettingsFormDataInitialState,
    mergeValuesIntoSettingsFormData,
} from '../../helpers/settingsFormUtils';
import ErrorBanner from '../../design-system/ErrorBanner/ErrorBanner';
import Alert from '../../design-system/Alert/Alert';
import errorWarningLineIcon from '../../design-system/Icons/ErrorWarningLineIcon';

const formBlocksSequence = ['unitTestSettings', 'datasetSettings', 'goalSettings'];

const CreateUnitTestPage = () => {
    const { goalTemplateId } = useParams();

    const [searchParams] = useSearchParams();
    const versionId = searchParams.get('version') || null;
    const datasetFromUrl = searchParams.get('dataset') || null;
    const organizationFromUrl = searchParams.get('organization') || null;
    const testRunIdFromUrl = searchParams.get('testrun') || null;

    const navigate = useNavigate();
    const location = useLocation();
    const backlinkUrl = location.state?.from ?? `/templates/goal/${goalTemplateId}`;

    const [goalTemplateData, setGoalTemplateData] = useState(null);
    const [branchDetails, setBranchDetails] = useState(null);

    const formDataInitialState = {
        unitTestSettings: {
            version: versionId,
            organization: null,
        },
        datasetSettings: {
            dataset: null,
            inputs: null,
            outputs: null,
        },
        goalSettings: {
            settings: null,
        },
    };

    const [formData, setFormData] = useState(formDataInitialState);

    const isUnitTestSettingsFilled = useMemo(() => {
        const { version, organization } = formData.unitTestSettings;
        return !!version && !!organization;
    }, [formData.unitTestSettings]);

    const isDatasetSettingsFilled = useMemo(() => {
        const { dataset, inputs, outputs } = formData.datasetSettings;
        return (
            !!dataset &&
            !!inputs &&
            !!outputs &&
            Object.values(inputs || {})?.every((input) => !!input) &&
            Object.values(outputs || {})?.every((output) => !!output)
        );
    }, [formData.datasetSettings]);

    const isGoalSettingsFilled = useMemo(() => {
        const { settings } = formData.goalSettings;
        return !!settings && settings?.every(checkIsRequiredNonHiddenFieldFilled);
    }, [formData.goalSettings]);

    const isFormFilled =
        isUnitTestSettingsFilled && isDatasetSettingsFilled && isGoalSettingsFilled;

    const shouldOrgAndDatasetBePreselected = !!organizationFromUrl && !!datasetFromUrl;
    const shouldPreviousTestRunDataBePreselected = !!testRunIdFromUrl;

    // Organization and Dataset preselect (from search params) or Previous Test Run Data preselect
    const [isPreselectLoading, setIsPreselectLoading] = useState({
        orgAndDataset: shouldOrgAndDatasetBePreselected,
        previousTestRunData: shouldPreviousTestRunDataBePreselected,
    });
    // while previous Test Run Data is preselecting, Loader is shown just for preselecting branch, organization and dataset. After preselecting is continuing, but Loader is hidden.
    const isLoaderShownWhilePreviousTestRunDataPreselecting =
        isPreselectLoading.previousTestRunData && !formData.datasetSettings.dataset;

    const [previousTestRunData, setPreviousTestRunData] = useState(null);

    const [isFormBlockOpenable, setIsFormBlockOpenable] = useState({
        datasetSettings: false,
        goalSettings: false,
    });
    const [isExpanded, setIsExpanded] = useState({
        unitTestSettings: true,
        datasetSettings: false,
        goalSettings: false,
    });
    const [successfulSubmitPageShown, setSuccessfulSubmitPageShown] = useState(false);

    const [controller, setController] = useState(new AbortController()); // state to manage the AbortController instance used for aborting requests

    const [isSubmitFormLoading, setIsSubmitFormLoading] = useState(false);
    const [errorBannerMessage, setErrorBannerMessage] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);
    // settings error messages renders GoalSettingsForm
    const [fieldsErrorMessage, setFieldsErrorMessage] = useState({
        organization: null,
        dataset: null,
        inputs: {},
        outputs: {},
    });

    useDocumentTitle('Create Unit Test');

    useEffect(() => {
        const fetchGoalTemplateData = async () => {
            try {
                const { data } = await templateClient.get(
                    `${API.ROUTES.template.goalTemplate}${goalTemplateId}/`
                );
                setGoalTemplateData(data);
            } catch (e) {
                navigate(backlinkUrl);
            }
        };

        fetchGoalTemplateData();
    }, []);

    const findDefaultBranch = (goalTemplateData) => {
        return goalTemplateData?.versions?.find((version) => version.is_default) || null;
    };

    const stopPreselecting = () =>
        setIsPreselectLoading({ orgAndDataset: false, previousTestRunData: false });

    useEffect(() => {
        const shouldPreselectDefaultBranch = goalTemplateData && isPreselectLoading.orgAndDataset;

        if (shouldPreselectDefaultBranch) {
            // find default branch among all versions and set in the branch selector
            const defaultBranch = findDefaultBranch(goalTemplateData);
            if (defaultBranch) {
                setFormData((prevData) => ({
                    ...prevData,
                    unitTestSettings: { ...prevData.unitTestSettings, version: defaultBranch.id },
                }));
            } else {
                // if default branch is not found => stop preselecting (means stop filling other fields and turn off the loader)
                stopPreselecting();
            }
        }
    }, [goalTemplateData, isPreselectLoading.orgAndDataset]);

    useEffect(() => {
        const shouldPreselectPreviousTestRunBranch =
            goalTemplateData && previousTestRunData && !formData.unitTestSettings.version;

        if (shouldPreselectPreviousTestRunBranch) {
            // find branch from previous test run among all versions and set in the branch selector
            const previousTestRunBranch =
                goalTemplateData?.versions?.find(
                    (version) =>
                        version.status !== 'canceled' &&
                        version.id === previousTestRunData.goal_template_version.id
                ) || null;
            if (previousTestRunBranch) {
                setFormData((prevData) => ({
                    ...prevData,
                    unitTestSettings: {
                        ...prevData.unitTestSettings,
                        version: previousTestRunBranch.id,
                    },
                }));
            } else {
                // if branch from previous test run is not found => stop preselecting (means stop filling other fields and turn off the loader) and show error alert
                stopPreselecting();
                setErrorAlert({
                    message: 'Oops, this Test Run could not be found! Please try again.',
                });
            }
        }
    }, [goalTemplateData, previousTestRunData]);

    useEffect(() => {
        const fetchPreviousTestRunData = async () => {
            try {
                const { data } = await executeClient.get(
                    `${API.ROUTES.execute.testRun}${testRunIdFromUrl}/`
                );
                setPreviousTestRunData(data);
            } catch (e) {
                stopPreselecting();
                setErrorAlert({
                    message: 'Oops, this Test Run could not be found! Please try again.',
                });
            }
        };

        if (testRunIdFromUrl) {
            setPreviousTestRunData(null);
            fetchPreviousTestRunData();
        }
    }, [testRunIdFromUrl]);

    useEffect(() => {
        const fetchBranchDetails = async () => {
            setBranchDetails(null);
            controller.abort(); // cancel the previous request if it's still active

            // create and set the new controller as the active one
            const newController = new AbortController();
            setController(newController);

            try {
                const { data } = await executeClient.get(
                    `${API.ROUTES.template.goalTemplateVersion}${formData.unitTestSettings.version}/test/?organization=${formData.unitTestSettings.organization}`,
                    { signal: newController.signal }
                );
                setBranchDetails(data);
                updateFormDataBasedOnBranchDetailsResponse(data);
            } catch (error) {
                if (error.message === 'canceled') {
                    return; // the next request is loading
                }
                navigate(backlinkUrl);
            }
        };

        if (isUnitTestSettingsFilled) {
            clearBlocksFormData(['datasetSettings', 'goalSettings']);
            fetchBranchDetails();
        }
    }, [formData.unitTestSettings, isUnitTestSettingsFilled]);

    useEffect(() => {
        // if Previous Test Run Preselect is Loading but version or organization was changed than stop preselecting
        const areVersionOrOrgChangedWhilePreviousTestRunPreselectLoading =
            isPreselectLoading.previousTestRunData &&
            !isLoaderShownWhilePreviousTestRunDataPreselecting;

        if (areVersionOrOrgChangedWhilePreviousTestRunPreselectLoading) {
            stopPreselecting();
        }
    }, [formData.unitTestSettings]);

    useEffect(() => {
        // if Previous Test Run Preselect is Loading but dataset was changed than stop preselecting
        const isDatasetNotFromPreviousTestRun =
            formData.datasetSettings.dataset !== previousTestRunData?.dataset?.id;

        const isDatasetChangedWhilePreviousTestRunPreselectLoading =
            isPreselectLoading.previousTestRunData &&
            !isLoaderShownWhilePreviousTestRunDataPreselecting &&
            isDatasetNotFromPreviousTestRun;

        if (isDatasetChangedWhilePreviousTestRunPreselectLoading) {
            stopPreselecting();
        }
    }, [formData.datasetSettings.dataset]);

    const updateFormDataBasedOnBranchDetailsResponse = (data) => {
        // add inputs to datasetSettings and add goalSettings form data
        const inputs = data.inputs.reduce((acc, input) => ({ ...acc, [input.name]: null }), {});
        const outputs = data.outputs.reduce((acc, output) => ({ ...acc, [output.name]: null }), {});
        setFormData((prevData) => ({
            ...prevData,
            datasetSettings: {
                ...prevData.datasetSettings,
                inputs,
                outputs,
            },
            goalSettings: { settings: getSettingsFormDataInitialState(data.settings || []) },
        }));
    };

    const clearBlocksFormData = (blocksToClearArray) => {
        const clearedFormData = Object.fromEntries(
            blocksToClearArray?.map((block) => [block, formDataInitialState[block]])
        );

        setFormData((prevData) => ({ ...prevData, ...clearedFormData }));
    };

    const makeNextFormBlockOpenable = (currentBlock) => {
        const nextBlockIndex = formBlocksSequence?.findIndex((block) => block === currentBlock) + 1;
        const nextBlock = formBlocksSequence[nextBlockIndex];
        if (!isFormBlockOpenable[nextBlock]) {
            setIsFormBlockOpenable((prevState) => ({ ...prevState, [nextBlock]: true }));
        }
    };

    useEffect(() => {
        if (isUnitTestSettingsFilled) {
            makeNextFormBlockOpenable('unitTestSettings');

            // expand Dataset Settings Block if Unit Test Settings Block is filled and isPreselectLoading
            if (isPreselectLoading.orgAndDataset || isPreselectLoading.previousTestRunData) {
                setIsExpanded((prevState) => ({ ...prevState, datasetSettings: true }));
            }
        }
    }, [isUnitTestSettingsFilled]);

    useEffect(() => {
        if (isDatasetSettingsFilled) {
            makeNextFormBlockOpenable('datasetSettings');

            // expand Goal Settings Block and prefill goal Settings form if Dataset Settings Block is filled and previous Test Run Data Preselect is loading
            if (isPreselectLoading.previousTestRunData) {
                setIsExpanded((prevState) => ({ ...prevState, goalSettings: true }));
                const mergedSettingsFormData = mergeValuesIntoSettingsFormData(
                    formData.goalSettings.settings,
                    previousTestRunData?.settings || []
                );
                setUpdatedGoalSettingsFormData(mergedSettingsFormData);
                stopPreselecting();
            }
        }
    }, [isDatasetSettingsFilled]);

    const toggleExpanded = (field) => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const setSectionFormData = (section, cb) => {
        setFormData((prevData) => ({
            ...prevData,
            [section]: cb(prevData[section]),
        }));
    };

    const getRequestBody = () => {
        const { unitTestSettings, datasetSettings, goalSettings } = formData;

        const settings = formatSettingsFormDataForRequestBody(goalSettings.settings);

        return {
            organization: unitTestSettings.organization,
            dataset: datasetSettings.dataset,
            inputs: datasetSettings.inputs,
            outputs: datasetSettings.outputs,
            settings,
        };
    };

    const setUpdatedGoalSettingsFormData = (updatedData) =>
        setFormData((prevData) => ({
            ...prevData,
            goalSettings: { settings: updatedData },
        }));

    const handleStartTestRun = async () => {
        setErrorBannerMessage(null);
        setFieldsErrorMessage({});

        // check if Inputted Goal Setting Data is valid (is json valid and ets.)
        const isGoalSettingsFormDataValid = checkIsFormDataValid(
            formData.goalSettings.settings,
            setUpdatedGoalSettingsFormData
        );
        if (!isGoalSettingsFormDataValid) {
            return;
        }

        try {
            setIsSubmitFormLoading(true);
            const requestBody = getRequestBody();
            await executeClient.post(
                `${API.ROUTES.template.goalTemplateVersion}${formData.unitTestSettings.version}/test/?organization=${formData.unitTestSettings.organization}`,
                requestBody
            );
            setIsSubmitFormLoading(false);
            setSuccessfulSubmitPageShown(true);
        } catch (error) {
            setIsSubmitFormLoading(false);

            const statusCode = error.response?.status;
            const errorData = error.response?.data?.error;

            if (statusCode === 400 && errorData && typeof errorData === 'string') {
                setErrorBannerMessage(errorData);
                setErrorAlert({ message: defaultErrorMessage });
            } else if (statusCode === 400 && errorData && typeof errorData === 'object') {
                setErrorAlert({ message: defaultErrorMessage });
                // expand every block to show all related errors
                setIsExpanded({
                    unitTestSettings: true,
                    datasetSettings: true,
                    goalSettings: true,
                });
                // handle settings error
                if (errorData.settings) {
                    const updatedFormData = getMappedSettingsFormErrors(
                        error,
                        formData.goalSettings?.settings
                    );
                    setUpdatedGoalSettingsFormData(updatedFormData);
                }

                // handle not settings errors
                const { settings, ...restErrors } = errorData;
                setFieldsErrorMessage(restErrors || {});
            } else {
                setErrorAlert({ message: defaultErrorMessage });
            }
        }
    };

    const isBranchPreselectLoading = versionId && !goalTemplateData;

    const isPagePreselectLoading =
        isPreselectLoading.orgAndDataset ||
        isLoaderShownWhilePreviousTestRunDataPreselecting ||
        isBranchPreselectLoading;

    return (
        <div className="bg-white w-full flex justify-center h-full px-[14px] pb-[40px] min-h-[calc(100vh-64px)] overflow-y-auto">
            <div className="w-full max-w-[800px] py-5">
                <div className={`${isPagePreselectLoading && 'h-0 max-h-0 overflow-hidden'}`}>
                    <div className="mb-5">
                        <Button
                            type="link"
                            size="sm"
                            // add the goal template name to the back button text
                            text={`Back to ${goalTemplateData?.name || 'Goal Template'}`}
                            leadingIcon={ArrowGoBackLineIcon}
                            onClick={() => navigate(backlinkUrl)}
                        />
                    </div>
                    <h1 className="font-heading-bold text-heading-bold-s text-black mb-8">
                        Create New Unit Test Run
                    </h1>
                    {errorBannerMessage && (
                        <div className="mb-8">
                            <ErrorBanner errorMessage={errorBannerMessage} />
                        </div>
                    )}
                    <div
                        className={`flex flex-col items-start gap-5 ${
                            successfulSubmitPageShown && 'h-0 max-h-0 overflow-hidden'
                        }`}
                    >
                        <UnitTestSettingsBlock
                            isExpanded={isExpanded.unitTestSettings}
                            toggleExpand={() => toggleExpanded('unitTestSettings')}
                            versions={goalTemplateData?.versions}
                            formData={formData}
                            setUnitTestSettingsFormData={(cb) =>
                                setSectionFormData('unitTestSettings', cb)
                            }
                            fieldsErrorMessage={fieldsErrorMessage}
                            setFieldsErrorMessage={setFieldsErrorMessage}
                            isOrganizationPreselectLoading={
                                isPreselectLoading.orgAndDataset ||
                                isPreselectLoading.previousTestRunData
                            }
                            orgIdFromPreviousTestRun={previousTestRunData?.organization?.id}
                            stopPreselecting={stopPreselecting}
                        />
                        <DatasetSettingsBlock
                            isExpanded={isExpanded.datasetSettings}
                            toggleExpand={() => toggleExpanded('datasetSettings')}
                            isBlockOpenable={isFormBlockOpenable.datasetSettings}
                            goalTemplateId={goalTemplateId}
                            goalInputKeys={branchDetails?.inputs}
                            goalOutputKeys={branchDetails?.outputs}
                            formData={formData}
                            setDatasetSettingsFormData={(cb) =>
                                setSectionFormData('datasetSettings', cb)
                            }
                            fieldsErrorMessage={fieldsErrorMessage}
                            setFieldsErrorMessage={setFieldsErrorMessage}
                            isDatasetPreselectLoading={
                                isPreselectLoading.orgAndDataset ||
                                isPreselectLoading.previousTestRunData
                            }
                            datasetSettingsFromPreviousTestRun={{
                                dataset: previousTestRunData?.dataset?.id,
                                inputs: previousTestRunData?.inputs,
                                outputs: previousTestRunData?.outputs,
                            }}
                            stopPreselecting={stopPreselecting}
                        />
                        <GoalSettingsBlock
                            isExpanded={isExpanded.goalSettings}
                            toggleExpand={() => toggleExpanded('goalSettings')}
                            isBlockOpenable={isFormBlockOpenable.goalSettings}
                            formData={formData}
                            setUnitTestSettingsFormData={(cb) =>
                                setSectionFormData('goalSettings', cb)
                            }
                        />
                        <Button
                            type="primary"
                            size="sm"
                            text="Start Test Run"
                            leadingIcon={FlashlightFillIcon}
                            state={
                                !isFormFilled
                                    ? 'disabled'
                                    : isSubmitFormLoading
                                    ? 'loading'
                                    : 'default'
                            }
                            onClick={handleStartTestRun}
                        />
                    </div>
                </div>
                {isPagePreselectLoading && (
                    <div className="h-full flex-grow self-center flex justify-center">
                        <Loading />
                    </div>
                )}
                {successfulSubmitPageShown && (
                    <SuccessfulSubmitBlock onClose={() => setSuccessfulSubmitPageShown(false)} />
                )}
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={errorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default CreateUnitTestPage;
