import React, { memo, useMemo, useState } from 'react';

import * as API from '../../../constants/api';
import client from '../../../services/library-api';

import { SORT_TYPES } from '../../../constants/sort';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import UnconfirmedAgentBlock from './UnconfirmedAgentBlock/UnconfirmedAgentBlock';
import ConfirmedAgentBlock from './ConfirmedAgentBlock/ConfirmedAgentBlock';

const AgentBlock = ({
    blockData,
    editMode = false,
    handleDeleteBlock,
    handleBlockPropertyChange,
    connectedAgents,
    agentDetailedData,
    setAgentsDetailedData,
}) => {
    const {
        properties: { agent_id },
    } = blockData;

    const isAgentConfirmed = !!agent_id;

    const agentData = useMemo(() => {
        if (isAgentConfirmed) {
            return connectedAgents.find((agent) => agent.id === agent_id);
        }
        return null;
    }, [connectedAgents]);

    const [confirmedAgentData, setConfirmedAgentData] = useState(agentData);

    const formatResponseToOptions = (results) =>
        results?.map(({ id, name, icon_color }) => ({
            id,
            name,
            leadingIconColor: icon_color,
        }));

    // making any changes to this request, please make the same changes on the following page: PlaybookBuilderPage/PlaybookContent/PlaybookContent.js. There, the request is created so that when the page loads, the request is executed and the cached data is immediately returned here.
    const agentOptionsHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.library.process,
        searchParams: { ordering: SORT_TYPES.alphabetical },
        formatResponseToOptions,
    });

    const setAgentIdInBlockData = (agent_id) => {
        handleBlockPropertyChange('agent_id', agent_id);
    };

    const handleConfirmAgent = (agent) => {
        setAgentIdInBlockData(agent.id);
        setConfirmedAgentData({ ...agent, icon_color: agent.leadingIconColor });
    };

    return (
        <>
            {isAgentConfirmed && (
                <ConfirmedAgentBlock
                    isEditable={editMode}
                    agentId={agent_id}
                    agentData={confirmedAgentData}
                    agentDetailedData={agentDetailedData}
                    setAgentsDetailedData={setAgentsDetailedData}
                    handleDeleteBlock={handleDeleteBlock}
                />
            )}

            {!isAgentConfirmed && (
                <UnconfirmedAgentBlock
                    isEditable={editMode}
                    agentOptionsHookResponse={agentOptionsHookResponse}
                    handleDeleteBlock={handleDeleteBlock}
                    handleConfirmAgent={handleConfirmAgent}
                />
            )}
        </>
    );
};

export default memo(AgentBlock);
