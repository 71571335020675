import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useClickOutside } from 'hooks';
import { ArrowDownSLineIcon } from 'design-system/Icons';
import SvgIcon from 'design-system/SvgIcon/SvgIcon';

import classNames from 'classnames';
import ErrorWarningLineIcon from 'design-system/Icons/ErrorWarningLineIcon';
import { Badge } from '../index';
import Loading from '../../components/Loading';
import ClientSideFilteredSearchBar from '../../components/ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import SelectOption from '../SelectOption/SelectOption';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { useClientSideFilterBar } from '../../hooks/useClientSideFilterBar';

PaginatedSelect.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            logAs: PropTypes.string,
        })
    ).isRequired,
    optionsLoading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    value: PropTypes.string,
    errorMessage: PropTypes.string,
    size: PropTypes.oneOf(['md', 'sm', 'xs']).isRequired,
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    showAsBadge: PropTypes.bool,
    badgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
    placeholder: PropTypes.string,
    canLoadMore: PropTypes.bool,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    dropdownHeight: PropTypes.number,
    includeClientSideFiltering: PropTypes.bool,
    totalOptionsCount: PropTypes.number,
    startingOptionCountForFilterBar: PropTypes.number,
    useExternalDropdownState: PropTypes.bool,
    dropdownOpen: PropTypes.bool,
    setDropdownOpen: PropTypes.func,
    increaseComponentHeightBy: PropTypes.number,
};

function PaginatedSelect({
    label,
    name,
    size,
    isRequired = false,
    state,
    options,
    optionsLoading = false,
    onChange,
    value,
    logAs,
    errorMessage,
    showAsBadge = false,
    badgeColor = 'blue',
    placeholder = 'Select an option',
    fetchOptions,
    canLoadMore,
    dropdownHeight = 240,
    info,
    includeClientSideFiltering = false,
    totalOptionsCount = 0, // is required if includeClientSideFiltering = true (the count of all options across all pages)
    startingOptionCountForFilterBar = 6, // if includeClientSideFiltering = true, then the Filtering SearchBar will be displayed if totalOptionsCount >= startingOptionCountForFilterBar
    useExternalDropdownState = false, // dropdown open / close state is in parent Component
    dropdownOpen = false, // this value is used instead of open state if useExternalDropdownState = true
    setDropdownOpen = () => {}, // update external open state if useExternalDropdownState = true
    increaseComponentHeightBy = 0, // if you want to increase the height of the component when the dropdown is open (in px)
}) {
    const [open, setOpen] = useState(false);
    const selectedOption = options.find((option) => option.id === value);

    const showClientSideFilterBar =
        includeClientSideFiltering && totalOptionsCount >= startingOptionCountForFilterBar;

    const { filteredOptions, setFilteredOptions } = useClientSideFilterBar({
        options,
        showClientSideFilterBar,
    });

    const dropdownRef = useRef('inputMenu');

    const { guardRef, isNextPageLoading } = useIntersectionObserver({
        handleFetch: fetchOptions,
        options,
        optionsLoading,
        canLoadMore,
        isSelectOpen: open,
    });

    const sizes = {
        md: 'h-13 py-17 px-11',
        sm: 'h-12 py-15 px-11',
        xs: 'h-10 py-11 px-11',
    };

    const selectSize = sizes[size];

    let selectClassName = classNames(
        'w-full rounded-2 text-base text-neutral-500 font-normal disabled:text-neutral-300 leading-1.25 placeholder-neutral-300 focus:placeholder-transparent caret-neutral-500 border-neutral-300 border-1 focus:border-1 focus:outline-0 focus:shadow-l3 transition-all ease-in',
        selectSize,
        {
            'bg-white': state !== 'disabled',
            'bg-neutral-50': state === 'disabled',
            'border-neutral-300 focus:shadow-neutral-300': state !== 'error',
            'border-red-500 focus:shadow-red-500': state === 'error',
        }
    );

    const toggleOpen = () => {
        if (useExternalDropdownState) {
            setDropdownOpen((prevState) => !prevState);
        } else {
            setOpen((prevState) => !prevState);
        }
    };

    useClickOutside(dropdownRef, () => {
        if (useExternalDropdownState) {
            setDropdownOpen(false);
        } else {
            setOpen(false);
        }
    });

    const isDropdownOpened =
        (useExternalDropdownState ? dropdownOpen : open) && state !== 'disabled';

    return (
        <div
            className="flex flex-col gap-1 font-inter w-full min-w-36 transition-all"
            style={{ marginBottom: isDropdownOpened ? `${increaseComponentHeightBy}px` : 0 }}
            ref={dropdownRef}
        >
            <label className="text-small font-bold leading-1.25">
                {label && (
                    <div className="mb-1">
                        {label}
                        {isRequired && <sup className="text-red-500 leading-1 ml-0.5">*</sup>}
                    </div>
                )}

                <div className="relative">
                    <button
                        onClick={toggleOpen}
                        disabled={state === 'disabled'}
                        className={`${selectClassName}  flex justify-between items-center`}
                    >
                        {selectedOption ? (
                            showAsBadge ? (
                                <div className="w-[calc(100%-25px)] flex justify-start">
                                    <Badge text={selectedOption.name} color={badgeColor} />
                                </div>
                            ) : (
                                <span className="block truncate">{selectedOption.name}</span>
                            )
                        ) : (
                            <span className="text-neutral-300 ">{placeholder}</span>
                        )}
                        <span className="">
                            <SvgIcon icon={ArrowDownSLineIcon} size="medium" color="neutral" />
                        </span>
                    </button>

                    {isDropdownOpened && (
                        <div
                            className={`absolute z-10 mt-1 w-full flex flex-col rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
                            style={{ maxHeight: `${dropdownHeight}px` }}
                        >
                            <div className="flex-1 overflow-y-auto px-2 py-2 lg:px-2.5">
                                {optionsLoading && !options.length && (
                                    <div className="w-full min-h-[92px] flex items-center justify-center">
                                        <Loading withText={false} />
                                    </div>
                                )}
                                {options.length > 0 && (
                                    <>
                                        {showClientSideFilterBar && (
                                            <div className="px-2 py-1 mb-1">
                                                <ClientSideFilteredSearchBar
                                                    list={options || []}
                                                    setFilteredList={setFilteredOptions}
                                                    size="xs"
                                                />
                                            </div>
                                        )}
                                        {filteredOptions.map((option) => (
                                            <div
                                                key={option.id}
                                                onClick={() => {
                                                    onChange(option.id, option);
                                                }}
                                                className="relative cursor-pointer select-none py-0.5"
                                                {...(logAs ? { 'data-dd-action-name': logAs } : {})}
                                            >
                                                <SelectOption
                                                    option={option}
                                                    isSelected={option.id === value}
                                                />
                                            </div>
                                        ))}

                                        <div ref={guardRef} className="h-0"></div>
                                        {isNextPageLoading && (
                                            <div
                                                className={`${
                                                    !!filteredOptions?.length
                                                        ? 'h-[38px]'
                                                        : 'h-[60px]'
                                                } flex justify-center items-center`}
                                            >
                                                <Loading withText={false} />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </label>
            {state === 'error' && errorMessage && (
                <div className="flex items-center gap-1">
                    <SvgIcon icon={ErrorWarningLineIcon} color="#E95B69" size="medium" />
                    <p className="text-extraSmall text-red-500 leading-1.25">{errorMessage}</p>
                </div>
            )}
            {info && state !== 'error' && (
                <div className="flex gap-1">
                    <SvgIcon icon={ErrorWarningLineIcon} color="#5E6470" size="medium" />
                    <p className="font-body text-body-regular-xs text-neutral-300">{info}</p>
                </div>
            )}
        </div>
    );
}
export default PaginatedSelect;
