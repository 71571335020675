import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { SORT_TYPES } from '../constants/sort';

const DEFAULT_CONFIG = {
    checkPage: true,
    checkSort: false,
    checkTab: false,
    defaultSortType: SORT_TYPES.created,
    availableSortTypes: Object.values(SORT_TYPES),
};

export const useDefaultSearchParams = ({
    checkPage = DEFAULT_CONFIG.checkPage,
    checkSort = DEFAULT_CONFIG.checkSort,
    checkTab = DEFAULT_CONFIG.checkTab,
    defaultSortType = DEFAULT_CONFIG.defaultSortType,
    availableSortTypes = DEFAULT_CONFIG.availableSortTypes,
    defaultTab = 'default',
    availableTabs = ['default'],
} = DEFAULT_CONFIG) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    useEffect(() => {
        const parsedPage = Number(searchParams.get('page'));
        const parsedSort = searchParams.get('sort');
        const parsedTab = searchParams.get('tab');

        let sort = defaultSortType;
        if (checkSort && availableSortTypes.includes(parsedSort)) {
            sort = parsedSort;
        }

        let tab = defaultTab;
        if (checkTab && availableTabs.includes(parsedTab)) {
            tab = parsedTab;
        }

        const isWrongPageNumber = checkPage && !parsedPage;
        const isWrongSortType = checkSort && sort !== parsedSort;
        const isWrongTab = checkTab && tab !== parsedTab;

        const shouldUpdateSearchParams = isWrongSortType || isWrongPageNumber || isWrongTab;

        if (shouldUpdateSearchParams) {
            const urlSearchParams = new URLSearchParams(searchParams);
            if (isWrongPageNumber) {
                urlSearchParams.set('page', 1);
            }
            if (isWrongSortType) {
                urlSearchParams.set('sort', sort);
            }
            if (isWrongTab) {
                urlSearchParams.set('tab', tab);
            }

            setSearchParams(urlSearchParams, { replace: true, state: location.state });
        }
    }, []);
};
