import React, { useEffect, useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/library-api';

import { HISTORY_TAB } from '../../../../constants/assistant';

import { Badge, Button, ButtonIcon, SvgIcon } from '../../../index';
import {
    ArrowDownSLineIcon,
    ArrowUpSLineIcon,
    DeleteBin4LineIcon,
    FlashlightFillIcon,
    Robot2FillIcon,
} from '../../../Icons';
import PlayCircleFillIcon from '../../../Icons/PlayCircleFillIcon';
import AgentDetails from '../AgentDetails/AgentDetails';
import Loading from '../../../../components/Loading';

const ConfirmedAgentBlock = ({
    isEditable,
    agentId,
    agentData,
    agentDetailedData,
    setAgentsDetailedData,
    handleDeleteBlock,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const agentFullData = { ...(agentData || {}), ...(agentDetailedData || {}) };

    const setAgentDetails = (cb) => {
        setAgentsDetailedData((prevAgents) => ({
            ...prevAgents,
            [agentId]: cb(prevAgents[agentId]),
        }));
    };

    useEffect(() => {
        const fetchAgentDetails = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.library.clientProcess}${agentId}/`);
                setAgentDetails(() => data);
            } catch (e) {}
        };

        if (!agentDetailedData && agentId) {
            fetchAgentDetails();
        }
    }, [agentId, agentDetailedData]);

    if (!agentData && !agentDetailedData) {
        return (
            <div className="w-full max-w-full flex items-stretch border-1 border-purple-300 rounded-2 overflow-hidden min-h-[64px] z-[6]">
                <div className="self-stretch px-5 bg-purple-100 flex items-center rounded-l-[8px]">
                    <SvgIcon color="#754DCF" icon={Robot2FillIcon} size="large" />
                </div>
                <div className="flex-1 flex justify-center">
                    <Loading withText={false} />
                </div>
            </div>
        );
    }

    const { id, name, icon_color } = agentFullData;

    const openLinkInNewTab = (link) => {
        window.open(link, '_blank');
    };

    const expandIcon = isExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon;

    return (
        <div className="w-full flex items-start gap-1 justify-between">
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="relative w-full max-w-full flex items-stretch border-1 border-purple-300 rounded-2 overflow-hidden min-h-[64px] z-[6]">
                    <div className="self-stretch px-5 bg-purple-100 flex items-center rounded-l-[8px]">
                        <SvgIcon color="#754DCF" icon={Robot2FillIcon} size="large" />
                    </div>
                    <div className="flex-1 px-3 py-3.5 flex flex-col md:flex-row md:items-center lg:flex-col lg:items-stretch min-[1400px]:flex-row min-[1400px]:items-center gap-y-2 gap-x-3 justify-between truncate bg-white">
                        <div className="flex items-center gap-2 overflow-hidden">
                            <p className="font-body-bold text-body-bold-xs text-neutral-300 uppercase">
                                Agent
                            </p>
                            <div className="truncate flex items-center">
                                <Badge
                                    text={name}
                                    color={`${icon_color + '33'}`} // + "33" to HEX color means 20% opacity
                                    leadingIcon={PlayCircleFillIcon}
                                    leadingIconColor={icon_color}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col items-start min-[450px]:flex-row min-[450px]:items-center gap-x-3 min-[450px]:max-w-full whitespace-nowrap lg:whitespace-normal min-[1400px]:whitespace-nowrap justify-between">
                            <Button
                                type="secondary"
                                size="xs"
                                text="Start New Job"
                                trailingIcon={FlashlightFillIcon}
                                onClick={() =>
                                    openLinkInNewTab(
                                        `/assistant?agent=${id}&tab=${HISTORY_TAB.jobs}`
                                    )
                                }
                            />
                            <Button
                                type="link"
                                size="xs"
                                text="View Details"
                                trailingIcon={expandIcon}
                                onClick={() => setIsExpanded((prev) => !prev)}
                            />
                        </div>
                    </div>
                </div>

                <div className={isExpanded ? 'block' : 'hidden'}>
                    <AgentDetails
                        agentDetails={agentDetailedData}
                        setAgentDetails={setAgentDetails}
                        isEditable={isEditable}
                    />
                </div>
            </div>

            {isEditable && (
                <div className="min-[1400px]:pt-[17px]">
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={DeleteBin4LineIcon}
                        onClick={handleDeleteBlock}
                    />
                </div>
            )}
        </div>
    );
};

export default ConfirmedAgentBlock;
