import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import client from '../../services/template-api';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useFormState } from '../../hooks/useFormState';

import {
    defaultBackLinkHref,
    issueTypes,
    TEMPLATE_TYPES,
    versionAPIRoutes,
} from '../../constants/template';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../constants/errorMessages';
import { capitalizeFirstLetter } from '../../services/strings';

import Button from '../../design-system/Button/Button';
import GitBranchIcon from '../../design-system/Icons/GitBranchIcon';
import FormFieldWrapper from '../../design-system/FormFieldWrapper/FormFieldWrapper';
import RichTextArea from '../../design-system/RichTextArea/RichTextArea';
import CreationPageContainer from '../../design-system/CreationPageContainer/CreationPageContainer';
import { Input } from '../../design-system';

const CreateNewTemplateBranchPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { goalTemplateId, processTemplateId } = useParams();

    const templateType = location.pathname.includes('/templates/goal/')
        ? TEMPLATE_TYPES.goal
        : TEMPLATE_TYPES.process;
    const templateId = templateType === TEMPLATE_TYPES.goal ? goalTemplateId : processTemplateId;

    const backLinkHref = location.state?.from ?? defaultBackLinkHref[templateType];

    const { formData, fieldErrorMessages, setFieldErrorMessages, handleInputChange } = useFormState(
        {
            title: '',
            type: null,
            body: '',
        }
    );
    const [errorAlert, setErrorAlert] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useDocumentTitle('Create Template Branch');

    const createBranch = async () => {
        const areEmptyFields = !formData.title || !formData.type || !formData.body;
        if (areEmptyFields) {
            setFieldErrorMessages({
                title: !formData.title ? emptyFieldErrorMessage : null,
                type: !formData.type ? 'Please select a branch type.' : null,
                body: !formData.title ? emptyFieldErrorMessage : null,
            });
            return;
        }

        try {
            setIsLoading(true);
            const requestBody = {
                ...formData,
            };
            if (templateType === TEMPLATE_TYPES.goal) {
                requestBody.goal_template = goalTemplateId;
            }
            if (templateType === TEMPLATE_TYPES.process) {
                requestBody.process_template = processTemplateId;
            }

            const { data } = await client.post(versionAPIRoutes[templateType], requestBody);

            setIsLoading(false);
            navigate(`/templates/${templateType}/${templateId}/branch/${data.id}`);
        } catch (error) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.status });
        }
    };

    return (
        <CreationPageContainer
            title="Create New Template Branch"
            backButtonText={`Back to ${capitalizeFirstLetter(templateType)} Template`}
            handleBackButtonClick={() => navigate(backLinkHref)}
            errorAlert={errorAlert}
            setErrorAlert={setErrorAlert}
        >
            <div className="flex flex-col gap-5 mb-8">
                <Input
                    size="sm"
                    name="title"
                    value={formData.title}
                    label="Branch Title"
                    isRequired
                    placeholder="Descriptive title for the changes to make"
                    onChange={(e) => handleInputChange('title', e.target.value)}
                    state={fieldErrorMessages.title ? 'error' : 'default'}
                    errorMessage={'Please fill in this field.'}
                />
                <FormFieldWrapper
                    label="Branch Type"
                    isRequired
                    state={fieldErrorMessages.type ? 'error' : 'default'}
                    errorMessage="Please select a branch type."
                >
                    <div className="w-full grid grid-cols-1 min-[500px]:grid-cols-2 min-[800px]:grid-cols-3 gap-x-2 gap-y-1">
                        {issueTypes.map((issue) => (
                            <div
                                key={issue.id}
                                className={`w-full p-3 rounded-2 self-stretch cursor-pointer transition-shadow ${
                                    formData.type === issue.id
                                        ? 'shadow-inset-2 shadow-purple-500'
                                        : 'shadow-inset-1 shadow-neutral-200'
                                }`}
                                onClick={() => handleInputChange('type', issue.id)}
                            >
                                <p className="font-body text-body-bold-s text-neutral-500 mb-0.5">
                                    {issue.name}
                                </p>
                                <p className="font-body text-body-regular-s text-black">
                                    {issue.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </FormFieldWrapper>
                <div>
                    <RichTextArea
                        value={formData.body}
                        label="Branch Description"
                        isRequired
                        placeholder="Describe the problem to solve with this change or enhancement."
                        setValue={(value) => handleInputChange('body', value)}
                        state={fieldErrorMessages.body ? 'error' : 'default'}
                        errorMessage="Please fill in this field."
                        minHeight={160}
                        autoExpand
                    />
                </div>
            </div>

            <Button
                type="primary"
                size="sm"
                text="Create Branch"
                trailingIcon={GitBranchIcon}
                state={isLoading ? 'loading' : 'default'}
                onClick={createBranch}
            />
        </CreationPageContainer>
    );
};

export default CreateNewTemplateBranchPage;
