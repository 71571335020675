import React, { useRef } from 'react';
import { useClickOutside } from '../../../hooks';
import { BLOCK_TYPE } from '../../../constants/playbookBuilder';

import BlockOptionFromSelector from '../BlockOptionFromSelector/BlockOptionFromSelector';

const groupedDropdownData = [
    {
        name: 'Content Block',
        options: [BLOCK_TYPE.richtext, BLOCK_TYPE.image, BLOCK_TYPE.video],
    },
    {
        name: 'Prebuilt Block',
        options: [
            BLOCK_TYPE.callout,
            BLOCK_TYPE.document,
            BLOCK_TYPE.agent,
            BLOCK_TYPE.chat_prompt,
        ],
    },
];

const BlockOptionsSelector = ({
    onClose,
    position = 'top-full left-0',
    addNewBlock = () => {},
    isDraggableSelector = false,
}) => {
    const selectorRef = useRef(null);

    const handleClose = (e) => {
        e?.stopPropagation();
        onClose();
    };

    useClickOutside(selectorRef, handleClose);

    return (
        <div
            className={`w-[237px] rounded-2.5 p-5 flex flex-col gap-5 bg-neutral-400 absolute z-20 ${position}`}
            ref={selectorRef}
        >
            {groupedDropdownData.map((group, index) => (
                <div key={index}>
                    <p className="font-body text-body-regular-xs text-neutral-200 uppercase mb-3">
                        {group.name}
                    </p>
                    <ul className="flex flex-col gap-2">
                        {group.options.map((blockType) => (
                            <BlockOptionFromSelector
                                key={blockType}
                                blockType={blockType}
                                isDraggable={isDraggableSelector}
                                addNewBlock={addNewBlock}
                                closeSelector={onClose}
                            />
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default BlockOptionsSelector;
