import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/knowledge-api';
import useCustomPagination from '../../hooks/useCustomPagination';

import { SORT_TYPES } from '../../constants/sort';
import { ARCHIVED_DOC_DATA_LS_KEY } from '../../constants/docs';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { parseSearchParams } from '../../helpers/parseSearchSortPageSearchParams';
import { useSwrPageRequestKeysManagingWithContextSaving } from '../../hooks/useSwrPageRequestKeysManagingWithContextSaving';
import { useDefaultSearchParams } from '../../hooks/useDefaultSearchParams';

import DocsPaginatedContainer from './DocsPaginatedContainer/DocsPaginatedContainer';
import DocsPageHeader from './DocsPageHeader/DocsPageHeader';
import SearchBarWithUrlParamsHandling from '../../components/SearchBarWithUrlParamsHandling/SearchBarWithUrlParamsHandling';

const DocsPage = () => {
    const [searchParams] = useSearchParams();
    const parsedParams = parseSearchParams(searchParams);

    const page = parsedParams.page || 1;

    const requestSearchParams = { ordering: SORT_TYPES.alphabeticalByLabel };
    if (parsedParams.search) {
        requestSearchParams.search = parsedParams.search;
    }

    const docsHookResponse = useCustomPagination({
        pageIndex: page - 1,
        searchParams: requestSearchParams,
        client: client,
        route: API.ROUTES.knowledge.document,
        autoFetchNextPage: true,
    });
    const { key, nextPageKey } = docsHookResponse;

    const { requestKeysToMutate } = useSwrPageRequestKeysManagingWithContextSaving({
        pageAccessor: 'docs',
        key,
        nextPageKey,
    });

    useDefaultSearchParams();

    useEffect(() => {
        return () => {
            localStorage.removeItem(ARCHIVED_DOC_DATA_LS_KEY);
        };
    }, []);

    useDocumentTitle('Docs');

    return (
        <div className="page-position max-w-full bg-neutral-50 p-4 xs:p-5 sm:p-6 lg:p-8 flex flex-col gap-5 overflow-y-auto">
            <DocsPageHeader />

            <SearchBarWithUrlParamsHandling size="xs" placeholder="Search ..." maxWidth={550} />

            <DocsPaginatedContainer
                page={page}
                docsHookResponse={docsHookResponse}
                searchQuery={parsedParams.search}
                requestKeysToMutate={requestKeysToMutate}
            />
        </div>
    );
};

export default DocsPage;
