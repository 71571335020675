import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/assistant-api';
import useSWRRequest from '../../../hooks/useSWRRequest';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { useTextareaAutoResize } from '../../../hooks/useTextareaAutoResize';

import { ButtonIcon } from '../../../design-system';
import { SendPlane2FillIcon } from '../../../design-system/Icons';
import StopFillIcon from '../../../design-system/Icons/StopFillIcon';
import ExpandDiagonalLineIcon from '../../../design-system/Icons/ExpandDiagonalLineIcon';
import CollapseDiagonalLine from '../../../design-system/Icons/CollapseDiagonalLine';
import ChatInputDiviBotView from '../ChatInputDiviBotView/ChatInputDiviBotView';
import ChatInputGenericView from '../ChatInputGenericView/ChatInputGenericView';

const ChatInputBox = ({
    isDiviBot,
    selectedAiModel,
    submitChatMessage,
    selectedTools,
    setSelectedTools,
    handleCancelStream,
    isAssistantMessageLoadingOrStreaming,
    isCancelStreamingLoading,
    messageSubmissionErrorData,
    setMessageSubmissionErrorData,
}) => {
    const [message, setMessage] = useState('');
    const [isExpandedMode, setIsExpandedMode] = useState(false);

    const [searchParams] = useSearchParams();
    const [promptIdToFetch, setPromptIdToFetch] = useState(searchParams.get('prompt') || null);

    const { data, error } = useSWRRequest({
        client,
        route: promptIdToFetch ? `${API.ROUTES.assistant.chatPrompt}${promptIdToFetch}/` : null,
    });

    const { height: screenHeight, width: screenWidth } = useWindowSize();

    const { textareaRef, textareaContainerRef, isScrollable } = useTextareaAutoResize({
        text: message,
        minHeight: 10,
        maxHeight: Math.floor(screenHeight * 0.27),
    });

    useEffect(() => {
        if (promptIdToFetch && data) {
            const message = data.default_version?.messages?.[0]?.content;
            setMessage(message);

            setPromptIdToFetch(null);
        }

        if (promptIdToFetch && error) {
            setPromptIdToFetch(null);
        }
    }, [data]);

    useEffect(() => {
        const inputtedUserMessage = messageSubmissionErrorData?.submittedUserMessage;

        if (inputtedUserMessage) {
            setMessage(inputtedUserMessage);
            setMessageSubmissionErrorData((prevData) => ({
                ...prevData,
                submittedUserMessage: null,
            }));
        }
    }, [messageSubmissionErrorData?.submittedUserMessage]);

    const sendButtonState =
        message && !!selectedAiModel && !isCancelStreamingLoading ? 'default' : 'disabled';

    const handleSendMessage = async () => {
        try {
            setIsExpandedMode(false);
            setMessage('');
            await submitChatMessage(message);
        } catch (e) {}
    };

    const handleSendMessageOnEnterPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();

            if (!isAssistantMessageLoadingOrStreaming && sendButtonState !== 'disabled') {
                handleSendMessage();
            }
        }
    };

    const handleFocusTextarea = (e) => {
        const isTextareaClicked = e.target?.nodeName === 'TEXTAREA';
        if (isTextareaClicked) {
            return;
        }

        const textAreaEl = textareaRef.current;
        if (textAreaEl) {
            textAreaEl.focus();
        }
    };

    const toggleExpandedMode = () => {
        setIsExpandedMode((prevState) => !prevState);
    };

    const isMobile = screenWidth < 768;
    const isSwitchExpandedModeButton = isMobile && (isExpandedMode || isScrollable);

    const handleAppendPromptToMessage = (prompt) => {
        setMessage((prevMessage) => (prevMessage ? `${prevMessage} \n${prompt}` : prompt));
        clearErrorMessage();
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
        clearErrorMessage();
    };

    function clearErrorMessage() {
        if (messageSubmissionErrorData) {
            setMessageSubmissionErrorData(null);
        }
    }

    const commonButtonsGroup = (
        <div className="flex items-center gap-4">
            {isSwitchExpandedModeButton && (
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={isExpandedMode ? CollapseDiagonalLine : ExpandDiagonalLineIcon}
                    onClick={toggleExpandedMode}
                />
            )}

            {!isAssistantMessageLoadingOrStreaming && (
                <ButtonIcon
                    type="primary"
                    size="sm"
                    icon={SendPlane2FillIcon}
                    state={sendButtonState}
                    onClick={handleSendMessage}
                />
            )}

            {isAssistantMessageLoadingOrStreaming && (
                <ButtonIcon
                    type="primary"
                    size="sm"
                    icon={StopFillIcon}
                    state="default"
                    onClick={handleCancelStream}
                />
            )}
        </div>
    );

    const commonProps = {
        message,
        setMessage,
        handleMessageChange,
        textareaRef,
        textareaContainerRef,
        commonButtonsGroup,
        handleFocusTextarea,
        handleSendMessageOnEnterPress,
        isMobile,
        isExpandedMode,
        setIsExpandedMode,
        handleAppendPromptToMessage,
        chatInputErrorMessage: messageSubmissionErrorData?.errorMessage,
    };

    if (isDiviBot) {
        return (
            <ChatInputDiviBotView
                {...commonProps}
                selectedTools={selectedTools}
                setSelectedTools={setSelectedTools}
                clearErrorMessage={clearErrorMessage}
            />
        );
    }

    return <ChatInputGenericView {...commonProps} />;
};

export default ChatInputBox;
