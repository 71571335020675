import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
    handleNextPageClick,
    handlePreviousPageClick,
} from '../../../helpers/handleUpdateSearchParams';
import { PAGINATION_LIMIT } from '../PlaybooksIndexPage';

import EmptyView from '../EmptyView/EmptyView';
import PlaybookCard from '../PlaybookCard/PlaybookCard';
import PaginationFooter from '../../LibraryPage/PaginationFooter/PaginationFooter';

const PlaybooksPaginatedList = ({
    page,
    playbooksDataResponse,
    parsedSearchQuery,
    openCreatePlaybookModal,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { data: playbooks, total } = playbooksDataResponse;

    if (total === 0) {
        return (
            <EmptyView
                parsedSearchQuery={parsedSearchQuery}
                openCreatePlaybookModal={openCreatePlaybookModal}
            />
        );
    }

    const start = (page - 1) * PAGINATION_LIMIT + 1;
    const isLastPage = total - PAGINATION_LIMIT * page <= 0;
    const end = isLastPage ? total : start + PAGINATION_LIMIT - 1;

    const handlePreviousClick = () => {
        handlePreviousPageClick({ searchParams, setSearchParams, currentPage: page });
    };

    const handleNextClick = () => {
        handleNextPageClick({ searchParams, setSearchParams, currentPage: page });
    };

    return (
        <div>
            {!!playbooks.length && (
                <>
                    <div className="grid grid-cols-1 min-[724px]:grid-cols-2 min-[1085px]:grid-cols-3 min-[1580px]:grid-cols-4 gap-x-5 gap-y-5">
                        {playbooks.map((playbook) => (
                            <PlaybookCard key={playbook.id} playbook={playbook} />
                        ))}
                    </div>
                    {total > PAGINATION_LIMIT && (
                        <PaginationFooter
                            start={start}
                            end={end}
                            total={total}
                            onNextPressed={handleNextClick}
                            onPreviousPressed={handlePreviousClick}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default PlaybooksPaginatedList;
