export const insertItemAtSpecificIndexInArray = (array, newItem, index) => {
    if (index === 0) {
        return [newItem, ...array];
    }

    const lastArrayElementIndex = array.length - 1;
    if (index > lastArrayElementIndex) {
        return [...array, newItem];
    }

    const arrayWithInsertedItem = [
        ...array.slice(0, index), // before the insertion point
        newItem,
        ...array.slice(index), // after the insertion point
    ];

    return arrayWithInsertedItem;
};
