import React from 'react';

const DownloadCloudIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none">
            <g>
                <path d="M8 17L12 21L16 17" fill="white" />
                <path
                    d="M8 17L12 21L16 17"
                    stroke={props.color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 12V21"
                    stroke={props.color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.8812 18.09C21.7505 17.4786 22.4025 16.6061 22.7424 15.5991C23.0824 14.5921 23.0926 13.503 22.7715 12.4898C22.4505 11.4766 21.815 10.5921 20.9572 9.96456C20.0994 9.33703 19.064 8.99916 18.0012 8.99999H16.7412C16.4404 7.82787 15.8776 6.73924 15.0953 5.81607C14.3129 4.89291 13.3313 4.15925 12.2244 3.67035C11.1174 3.18144 9.914 2.95002 8.70468 2.99351C7.49536 3.037 6.31167 3.35426 5.24271 3.92142C4.17375 4.48858 3.24738 5.29084 2.53334 6.26782C1.8193 7.2448 1.33621 8.37104 1.12041 9.56174C0.904624 10.7524 0.961764 11.9766 1.28753 13.142C1.6133 14.3074 2.19921 15.3838 3.00115 16.29"
                    stroke={props.color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default DownloadCloudIcon;
