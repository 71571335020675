import { SORT_TYPES } from '../constants/sort';

export const getPlaybooksRequestQueryParams = (parsedSearchParams) => {
    const queryParams = { ordering: SORT_TYPES.alphabetical };

    if (parsedSearchParams.search) {
        queryParams.search = parsedSearchParams.search;
    }

    return queryParams;
};
