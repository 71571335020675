import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';

import { Button, ButtonIcon } from '../../../design-system';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import EyeIcon from '../../../design-system/Icons/EyeIcon';
import SyncPlaybookDataButton from '../SyncPlaybookDataButton/SyncPlaybookDataButton';

const PlaybookBuilderTopControls = ({
    backLinkHref,
    setPageTopControlsHeight,
    handleSyncPlaybookData,
    changeMode,
}) => {
    const [searchParams] = useSearchParams();
    const mode = searchParams.get('mode');

    const navigate = useNavigate();

    const containerRef = useRef(null);
    const { width: screenWidth } = useWindowSize();

    useEffect(() => {
        if (containerRef.current) {
            setPageTopControlsHeight(containerRef.current?.clientHeight || 0);
        }
    }, [containerRef.current, screenWidth]);

    return (
        <div ref={containerRef}>
            <div className="flex items-center justify-between relative min-h-10">
                <div className="flex items-center gap-5">
                    <Button
                        type="link"
                        size="xs"
                        text="Back"
                        leadingIcon={ArrowGoBackLineIcon}
                        onClick={() => navigate(backLinkHref)}
                    />
                    <p className="font-body text-body-regular-s text-neutral-300">Playbook /</p>
                </div>

                <div className="flex items-center gap-0.5 lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:translate-x-[-50%] lg:translate-y-[-50%]">
                    <ButtonIcon
                        size="xs"
                        type={mode === PLAYBOOK_BUILDER_MODE.edit ? 'primary' : 'grey'}
                        icon={EditLineIcon}
                        onClick={() => changeMode(PLAYBOOK_BUILDER_MODE.edit)}
                    />
                    <ButtonIcon
                        size="xs"
                        type={mode === PLAYBOOK_BUILDER_MODE.view ? 'primary' : 'grey'}
                        icon={EyeIcon}
                        onClick={() => changeMode(PLAYBOOK_BUILDER_MODE.view)}
                    />
                </div>

                <div className="flex items-center gap-4">
                    {mode === PLAYBOOK_BUILDER_MODE.edit && (
                        <SyncPlaybookDataButton handleSync={handleSyncPlaybookData} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PlaybookBuilderTopControls;
