import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DEFAULT_LIMIT } from '../../hooks/useCustomPagination';
import {
    handleNextPageClick,
    handlePreviousPageClick,
} from '../../helpers/handleUpdateSearchParams';

import PaginationFooter from '../../pages/LibraryPage/PaginationFooter/PaginationFooter';

PaginatedContainer.propTypes = {
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    onlyShowPaginationIfMultiplePages: PropTypes.bool,
    children: PropTypes.node,
};

function PaginatedContainer({
    page,
    total,
    count,
    onlyShowPaginationIfMultiplePages = false,
    children,
}) {
    const [searchParams, setSearchParams] = useSearchParams();

    const start = (page - 1) * DEFAULT_LIMIT + Math.min(count, 1);
    const end = total - start < count ? total : start + Math.max(count, 1) - 1;
    const hasData = !!total;

    const isJustOnePage = page === 1 && total <= DEFAULT_LIMIT && total === count;
    const hidePagination = onlyShowPaginationIfMultiplePages && isJustOnePage;

    const handlePreviousPressed = () => {
        handlePreviousPageClick({ searchParams, setSearchParams, currentPage: page });
    };

    const handleNextPressed = () => {
        handleNextPageClick({ searchParams, setSearchParams, currentPage: page });
    };

    return (
        <>
            {children}

            {hasData && !hidePagination && (
                <div className="flex flex-col">
                    <PaginationFooter
                        start={start}
                        end={end}
                        total={total}
                        onNextPressed={handleNextPressed}
                        onPreviousPressed={handlePreviousPressed}
                    />
                </div>
            )}
        </>
    );
}

export default PaginatedContainer;
