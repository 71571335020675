import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import LoaderFillIcon from 'design-system/Icons/LoaderFillIcon';

Button.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'neutral', 'ghost', 'link', 'critical'])
        .isRequired,
    size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']).isRequired,
    state: PropTypes.oneOf(['disabled', 'loading', 'default']),
    text: PropTypes.string.isRequired,
    leadingIcon: PropTypes.func,
    trailingIcon: PropTypes.func,
    helperIcon: PropTypes.func,
    helperText: PropTypes.string,
    onClick: PropTypes.func, // If button type is "submit" or "link" onClick is not required. TODO: Enforce this as required for other types
    isSubmit: PropTypes.bool,
    logAs: PropTypes.string,
    noRoundedRight: PropTypes.bool,
    id: PropTypes.string,
};

function Button(props) {
    const {
        type,
        size,
        text,
        onClick,
        leadingIcon,
        logAs,
        trailingIcon,
        helperIcon,
        helperText,
        state = 'default',
        isSubmit = false,
        noRoundedRight = false,
        id,
    } = props;

    const sizes = {
        lg: type === 'link' ? 'py-4 px-0 h-14' : 'py-4 px-6 h-14',
        md: type === 'link' ? 'py-3 px-0 h-12' : 'py-3 px-6 h-12',
        sm: type === 'link' ? 'py-2 px-0 h-10' : 'py-2 px-4 h-10',
        xs: type === 'link' ? 'py-1.5 px-0 h-9' : 'py-1.5 px-3 h-9',
    };

    const colors = {
        primary:
            state === 'disabled' ? 'bg-neutral-300 text-neutral-200' : 'bg-neutral-500 text-white',
        secondary:
            state === 'disabled' ? 'bg-purple-100 text-purple-300' : 'bg-purple-500 text-white',
        neutral:
            state === 'disabled'
                ? 'bg-neutral-100 text-neutral-200'
                : 'bg-neutral-100 text-neutral-400',
        ghost:
            state === 'disabled'
                ? 'bg-transparent text-neutral-300 shadow-neutral-300'
                : 'bg-transparent text-neutral-400 shadow-neutral-500',
        link: state === 'disabled' ? 'text-neutral-300' : 'text-neutral-400',
        critical:
            state === 'disabled'
                ? 'bg-transparent text-red-300 shadow-red-300'
                : 'bg-transparent text-red-500 shadow-red-500',
    };

    const mainIcon = {
        primary: state === 'disabled' ? '#CFD6E5' : '#FFFFFF',
        secondary: state === 'disabled' ? '#C6B8E7' : '#FFFFFF',
        neutral: state === 'disabled' ? '#CFD6E5' : '#33373D',
        ghost: state === 'disabled' ? '#5E6470' : '#33373D',
        link: state === 'disabled' ? '#5E6470' : '#33373D',
        critical: state === 'disabled' ? '#F8CACE' : '#E95B69',
    };

    const shadows = {
        primary: 'focus:shadow-neutral-100',
        secondary: 'focus:shadow-purple-100',
        neutral: 'focus:shadow-neutral-200',
        ghost: 'focus:shadow-neutral-500',
        link: 'focus:shadow-none',
        critical: 'focus:shadow-red-500',
    };

    const buttonSize = sizes[size];
    const buttonColors = colors[type];
    const focusShadows = shadows[type];
    const mainIconColor = mainIcon[type];

    let className = classNames(
        'flex items-center justify-center gap-2 font-brand font-semibold leading-1 focus:border-transparent focus:outline-0 focus:shadow-l2 transition-all ease-in',
        buttonSize,
        buttonColors,
        focusShadows,
        {
            'min-w-55': state === 'loading' && (size === 'lg' || size === 'md'),
            'min-w-51': state === 'loading' && size === 'sm',
            'min-w-45.5': state === 'loading' && size === 'xs',
            'text-small tracking-[0.7px]': size === 'xs' || size === 'sm',
            'text-base tracking-[0.7px]': size === 'lg' || size === 'md',
            'shadow-inset-2': type === 'ghost' || type === 'critical',
            'hover:shadow-inset-3 disabled:shadow-inset-2':
                (type === 'ghost' || type === 'critical') && size === 'lg',
            'rounded-2': !noRoundedRight,
            'rounded-l-2': noRoundedRight,
        }
    );

    const buttonType = isSubmit ? 'submit' : 'button';

    return (
        <button
            type={buttonType}
            disabled={state === 'disabled' || state === 'loading'}
            className={className}
            onClick={onClick}
            {...(logAs ? { 'data-dd-action-name': logAs } : {})}
            {...(id ? { id } : {})}
        >
            {state !== 'loading' && leadingIcon && (
                <SvgIcon icon={leadingIcon} size="large" color={mainIconColor} />
            )}
            {state !== 'loading' && text && <span>{text}</span>}
            {state !== 'loading' && trailingIcon && (
                <SvgIcon icon={trailingIcon} size="large" color={mainIconColor} />
            )}
            {state === 'default' && (helperIcon || helperText) && (
                <>
                    {helperIcon && (
                        <SvgIcon
                            icon={helperIcon}
                            size="rectangle"
                            color={type === 'secondary' ? '#CFD6E5' : '#5E6470'}
                        />
                    )}
                    {helperText && (
                        <span
                            className={`font-body text-body-regular-s ${
                                type === 'secondary' ? 'text-[#CFD6E5]' : 'text-[#5E6470]'
                            }`}
                        >
                            {helperText}
                        </span>
                    )}
                </>
            )}
            {state === 'loading' && (
                <span className="animate-icon-spin">
                    <SvgIcon icon={LoaderFillIcon} size="large" color={mainIconColor} />
                </span>
            )}
        </button>
    );
}

export default Button;
