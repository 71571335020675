import React, { memo, useState } from 'react';

import { API } from 'constants';
import client from '../../../services/assistant-api';
import useSWRRequest from '../../../hooks/useSWRRequest';

import NotCreatedPromptBlock from './NotCreatedPromptBlock/NotCreatedPromptBlock';
import CreatedPromptBlock from './CreatedPromptBlock/CreatedPromptBlock';
import ConfirmPromptBlockDeleteModal from './ConfirmPromptBlockDeleteModal/ConfirmPromptBlockDeleteModal';

const ChatPromptBlock = ({
    blockData,
    editMode = false,
    handleDeleteBlock,
    handleBlockPropertyChange,
}) => {
    const {
        id,
        properties: { chat_prompt_id },
    } = blockData;

    const isPromptCreated = !!chat_prompt_id && id;

    const [confirmDeleteModal, setConfirmDeleteModal] = useState(null);

    const promptData = useSWRRequest({
        client,
        route: chat_prompt_id ? `${API.ROUTES.assistant.chatPrompt}${chat_prompt_id}/` : null,
    });

    return (
        <>
            {!isPromptCreated && (
                <NotCreatedPromptBlock
                    isDisabled={!editMode}
                    handleDeleteBlock={handleDeleteBlock}
                    handleBlockPropertyChange={handleBlockPropertyChange}
                />
            )}

            {isPromptCreated && (
                <CreatedPromptBlock
                    promptData={promptData}
                    isDisabled={!editMode}
                    setConfirmDeleteModal={setConfirmDeleteModal}
                />
            )}

            {confirmDeleteModal && (
                <ConfirmPromptBlockDeleteModal
                    id={chat_prompt_id}
                    name={confirmDeleteModal.name}
                    handleDeleteBlock={handleDeleteBlock}
                    handleBlockPropertyChange={handleBlockPropertyChange}
                    onClose={() => setConfirmDeleteModal(null)}
                />
            )}
        </>
    );
};

export default memo(ChatPromptBlock);
