import React, { useEffect, useMemo, useRef, useState } from 'react';

import DocCard from '../DocCard/DocCard';
import DocsColumnHeader, { rowMinWidthStyles } from '../DocsColumnHeader/DocsColumnHeader';
import RenameDocModal from '../RenameDocModal/RenameDocModal';
import DocCardMoreOptionsPopup from '../DocCardMoreOptionsPopup/DocCardMoreOptionsPopup';
import ArchiveDocModal from '../ArchiveDocModal/ArchiveDocModal';
import Alert from '../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import SuccessArchiveDocAlert from '../SuccessArchiveDocAlert/SuccessArchiveDocAlert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import { ARCHIVED_DOC_DATA_LS_KEY } from '../../../constants/docs';

const docMoreOptionsPopupEmptyState = {
    isOpened: false,
    docData: { id: null, label: null },
    buttonTopPosition: 0,
};
const actionModalEmptyState = {
    action: 'null',
    docData: { id: null, label: null },
};
const successArchiveDocAlertEmptyState = {
    archivedDocId: null,
    archivedDocLabel: null,
    archivedDocIndex: null,
};

const DocsList = ({ docsHookResponse, selectedDocIds, setSelectedDocIds, requestKeysToMutate }) => {
    const { data, key } = docsHookResponse;

    const containerRef = useRef(null);
    const containerTopPosition = useRef(0);

    const [docMoreOptionsPopup, setDocMoreOptionsPopup] = useState(docMoreOptionsPopupEmptyState);
    const [actionModal, setActionModal] = useState(actionModalEmptyState);

    const [successAlert, setSuccessAlert] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const [successArchiveDocAlert, setSuccessArchiveDocAlert] = useState(
        successArchiveDocAlertEmptyState
    );

    const allCurrentPageDocIds = useMemo(() => data?.map(({ id }) => id) || [], [data]);

    useEffect(() => {
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            containerTopPosition.current = rect.top;
        }
    }, [containerRef, allCurrentPageDocIds]);

    useEffect(() => {
        // if the doc was archived from Doc Detail page show success archive alert
        const savedSuccessArchiveDocData = localStorage.getItem(ARCHIVED_DOC_DATA_LS_KEY);
        if (savedSuccessArchiveDocData) {
            try {
                const parsedData = JSON.parse(savedSuccessArchiveDocData);
                setSuccessArchiveDocAlert(parsedData);
            } catch (e) {
            } finally {
                localStorage.removeItem(ARCHIVED_DOC_DATA_LS_KEY);
            }
        }
    }, []);

    return (
        <>
            <div className="relative" ref={containerRef}>
                {!!data?.length && (
                    <div className="max-md:overflow-x-auto">
                        <DocsColumnHeader
                            selectedDocIds={selectedDocIds}
                            setSelectedDocIds={setSelectedDocIds}
                            allCurrentPageDocIds={allCurrentPageDocIds}
                        />
                        <ul className={`flex flex-col gap-1 ${rowMinWidthStyles}`}>
                            {data.map((doc) => (
                                <DocCard
                                    key={doc.id}
                                    doc={doc}
                                    isSelected={!!selectedDocIds[doc.id]}
                                    setSelectedDocIds={setSelectedDocIds}
                                    setDocMoreOptionsPopup={setDocMoreOptionsPopup}
                                    allCurrentPageDocIds={allCurrentPageDocIds}
                                />
                            ))}
                        </ul>
                    </div>
                )}

                {docMoreOptionsPopup.isOpened && (
                    <DocCardMoreOptionsPopup
                        docData={docMoreOptionsPopup.docData}
                        setActionModal={setActionModal}
                        topPosition={`${
                            docMoreOptionsPopup.buttonTopPosition -
                            containerTopPosition.current +
                            32
                        }px`}
                        setSuccessAlert={setSuccessAlert}
                        setErrorAlert={setErrorAlert}
                        requestKeysToMutate={requestKeysToMutate}
                        onClose={() => setDocMoreOptionsPopup(docMoreOptionsPopupEmptyState)}
                    />
                )}
            </div>

            {actionModal.action === 'rename' && (
                <RenameDocModal
                    docData={actionModal.docData}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={() => setActionModal(actionModalEmptyState)}
                />
            )}

            {actionModal.action === 'archive' && (
                <ArchiveDocModal
                    docData={actionModal.docData}
                    docsList={data}
                    requestKeysToMutate={requestKeysToMutate}
                    setSuccessArchiveDocAlert={setSuccessArchiveDocAlert}
                    onClose={() => setActionModal(actionModalEmptyState)}
                />
            )}

            {successArchiveDocAlert.archivedDocId && (
                <SuccessArchiveDocAlert
                    data={successArchiveDocAlert}
                    requestKey={key}
                    setErrorAlert={setErrorAlert}
                    handleClose={() => setSuccessArchiveDocAlert(successArchiveDocAlertEmptyState)}
                />
            )}

            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    description={successAlert.description}
                    position="top-center"
                    autoCloseInMS={5000}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

export default DocsList;
