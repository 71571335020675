import React from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';

import { ButtonIcon } from '../../../design-system';
import { CloseLineIcon } from '../../../design-system/Icons';
import Panel from '../../../components/Panel';
import ConnectedAgentsBlock from '../ConnectedAgentsBlock/ConnectedAgentsBlock';
import CreationAndUpdateLog from '../CreationAndUpdateLog/CreationAndUpdateLog';
import PlaybookMainInfo from '../PlaybookMainInfo/PlaybookMainInfo';
import PlaybookBuilderNavigation from '../PlaybookBuilderNavigation/PlaybookBuilderNavigation';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';

export const sectionContainerStyles = 'pb-8 lg:pb-4 border-b-1 border-neutral-200';

const PlaybookDetailsPanel = ({ playbook, onClose }) => {
    const { width: screenWidth } = useWindowSize();

    const isDesktop = screenWidth >= 1024;
    const WrapperComponent = isDesktop ? Panel : MobilePopup;

    return (
        <WrapperComponent onClose={onClose} closeByClickOutside showControls={false} resetPadding>
            <div className="min-h-full flex flex-col justify-between gap-6 lg:gap-8 lg:pt-4 lg:px-8">
                <div>
                    <div className="flex items-start justify-between gap-5 mb-6 lg:mb-3">
                        <p className="font-heading-bold text-heading-bold-s lg:text-heading-bold-m text-neutral-500">
                            Playbook Details
                        </p>
                        {isDesktop && (
                            <ButtonIcon
                                type="link"
                                size="sm"
                                icon={CloseLineIcon}
                                onClick={onClose}
                            />
                        )}
                    </div>

                    <div className="flex flex-col gap-8 lg:gap-4">
                        <PlaybookMainInfo playbook={playbook} />
                        <CreationAndUpdateLog playbook={playbook} />
                        <ConnectedAgentsBlock agents={playbook.processes} />
                    </div>
                </div>

                <div className="pb-1 lg:pb-8">
                    <PlaybookBuilderNavigation playbookId={playbook.id} />
                </div>
            </div>
        </WrapperComponent>
    );
};

export default PlaybookDetailsPanel;
