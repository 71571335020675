import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../services/knowledge-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { mutateDataAfterArchivingDoc } from '../../../helpers/docsUtils';

import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import Modal from '../../../design-system/Modal/Modal';
import FileTextIcon from '../../../design-system/Icons/FileTextIcon';
import Alert from '../../../design-system/Alert/Alert';
import { Button, SvgIcon } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import { ARCHIVED_DOC_DATA_LS_KEY } from '../../../constants/docs';

const ArchiveDocModal = ({
    docData,
    docsList = [],
    requestKeysToMutate = [],
    setSuccessArchiveDocAlert,
    navigateToDocsIndex,
    onClose,
}) => {
    const { id, label } = docData;

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleArchive = async () => {
        try {
            setIsLoading(true);
            await client.delete(`${API.ROUTES.knowledge.document}${id}/`);

            mutateDataAfterArchivingDoc({ requestKeysToMutate, docId: id });

            const successAlertData = { archivedDocId: id, archivedDocLabel: label };

            if (setSuccessArchiveDocAlert) {
                const archivedDocIndex = docsList?.findIndex((doc) => doc.id === id);
                setSuccessArchiveDocAlert({
                    ...successAlertData,
                    archivedDocIndex: archivedDocIndex > 0 ? archivedDocIndex : 0,
                });
            }
            if (navigateToDocsIndex) {
                localStorage.setItem(ARCHIVED_DOC_DATA_LS_KEY, JSON.stringify(successAlertData));
                navigateToDocsIndex();
            }

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        }
    };

    const archiveButtonState = isLoading ? 'loading' : 'default';

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <ModalHeader onClose={onClose} title="Archive Doc" outlined />

            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                    <p className="font-body text-body-regular-m text-neutral-500">
                        Are you sure you’d like to archive this Doc?
                    </p>

                    <div className="px-2 py-3 rounded-2 bg-neutral-50 flex items-start gap-1 min-h-[16px]">
                        <SvgIcon color="#754DCF" icon={FileTextIcon} size="medium" />
                        <p className="font-body-bold text-body-bold-s text-[#25324B] py-auto">
                            {label}
                        </p>
                    </div>

                    <p className="font-body text-body-regular-m text-neutral-500">
                        This Doc will be recoverable, if needed.
                    </p>
                </div>

                <div className="flex items-center justify-between gap-4">
                    <Button size="md" type="neutral" text="Cancel" onClick={onClose} />
                    <Button
                        size="md"
                        type="secondary"
                        text="Yes, Archive"
                        state={archiveButtonState}
                        onClick={handleArchive}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default ArchiveDocModal;
