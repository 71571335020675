import { mutate } from 'swr';

export const mutateKeyData = ({ key, revalidate, cb }) =>
    mutate(
        key,
        (currentData) => {
            if (!currentData) {
                return;
            }

            return cb(currentData, key);
        },
        { revalidate }
    );

export const mutateRequestKeysData = ({ requestKeysToMutate, revalidate = true, cb }) => {
    if (requestKeysToMutate) {
        requestKeysToMutate?.map((key) => {
            mutateKeyData({ key, revalidate, cb });
        });
    }
};

export const revalidateAllCashedRequest = ({ requestKeysToMutate }) => {
    requestKeysToMutate?.map((key) => {
        mutate(key);
    });
};

export const mutateAllCashedRequest = ({ requestKeysToMutate }) => {
    requestKeysToMutate?.map((key) => {
        mutate(key, null, true);
    });
};

export const mutateListDataAfterRenamingItem = ({ requestKeysToMutate, id, updatedName }) => {
    mutateRequestKeysData({
        requestKeysToMutate,
        revalidate: false,
        cb: (currentData) => {
            const updatedResults = currentData.results.map((item) =>
                item.id === id ? { ...item, name: updatedName } : item
            );

            return { ...currentData, results: updatedResults };
        },
    });
};

export const mutateDataAfterDeletingItem = ({ requestKeysToMutate, id }) => {
    mutateRequestKeysData({
        requestKeysToMutate,
        revalidate: true,
        cb: (currentData, key) => {
            const isKeyWithSearch = key.includes('?search=');
            const isDeletedItemMissedInResultsWithSearchQuery =
                isKeyWithSearch && !currentData.results.find((item) => item.id === id);
            if (isDeletedItemMissedInResultsWithSearchQuery) {
                return currentData;
            }

            const updatedResults = currentData.results.filter((item) => item.id !== id);

            return { ...currentData, results: updatedResults, count: currentData.count - 1 };
        },
    });
};

export const mutateListDataAfterItemStatusChanging = ({ requestKeysToMutate, id, status }) => {
    mutateRequestKeysData({
        requestKeysToMutate,
        revalidate: false,
        cb: (currentData) => {
            const updatedResults = currentData.results.map((item) =>
                item.id === id ? { ...item, status } : item
            );

            return { ...currentData, results: updatedResults };
        },
    });
};
