import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../services/knowledge-api';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../constants/errorMessages';

import { useFormState } from '../../../hooks/useFormState';
import { mutateDataAfterRenamingDoc } from '../../../helpers/docsUtils';

import Modal from '../../../design-system/Modal/Modal';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import Alert from '../../../design-system/Alert/Alert';
import { Button, Input } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const RenameDocModal = ({ docData, requestKeysToMutate, onClose }) => {
    const { id, label } = docData;

    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        { label }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleSave = async () => {
        if (!formData.label) {
            setFieldErrorMessages({ label: emptyFieldErrorMessage });
            return;
        }

        try {
            setIsLoading(true);

            const { data } = await client.patch(`${API.ROUTES.knowledge.document}${id}/`, {
                label: formData.label,
            });
            mutateDataAfterRenamingDoc({
                requestKeysToMutate,
                docId: id,
                updatedLabel: data.label,
            });

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        }
    };

    const saveButtonState = isLoading ? 'loading' : 'default';

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <ModalHeader onClose={onClose} title="Rename Doc" outlined />

            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-6">
                <Input
                    size="md"
                    name="label"
                    value={formData.label}
                    state={fieldErrorMessages.label ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.label}
                    onChange={(e) => handleInputChange('label', e.target.value)}
                />

                <div className="flex items-center justify-between gap-4">
                    <Button size="md" type="neutral" text="Cancel" onClick={onClose} />
                    <Button
                        size="md"
                        type="secondary"
                        text="Save"
                        state={saveButtonState}
                        onClick={handleSave}
                    />
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default RenameDocModal;
