import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as API from 'constants/api';
import client from 'services/organization-api';
import { useDeleteRequest, useGetRequest, usePostRequest } from 'hooks/request';

import { ORGANIZATION_PLAN } from '../../../constants/organization';
import {
    transformDataMembersTable,
    transformDataTeamsTable,
} from 'helpers/formatOrganizationDataForTable';
import { handleContactUs } from '../../../helpers/handleContactUs';

import { ErrorWarningLineIcon } from 'design-system/Icons';
import { Button } from 'design-system';
import Table from 'design-system/Table/Table';
import AddOrganizationModal from 'pages/OrganizationPage/AddOrganizationModal/AddOrganizationModal';
import EditTeamModal from 'pages/OrganizationPage/EditTeamModal/EditTeamModal';
import EditMembershipModal from 'pages/OrganizationPage/EditMembershipModal/EditMembershipModal';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Alert from '../../../design-system/Alert/Alert';
import EditableTextArea from '../../../components/EditableTextArea/EditableTextArea';
import Loading from '../../../components/Loading';
import ApiKeyBlock from '../ApiKeyBlock/ApiKeyBlock';
import OrganizationConnectionsBlock from '../OrganizationConnectionsBlock/OrganizationConnectionsBlock';

const OrganizationBlock = () => {
    const { id: orgId } = useParams();
    const navigate = useNavigate();

    const [userRole, setUserRole] = useState(null);
    const [orgPlan, setOrgPlan] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [addOrganizationModal, setAddOrganizationModal] = useState({ opened: false });
    const [editTeamModal, setEditTeamModal] = useState({
        opened: false,
        data: null,
        mode: 'create',
    });
    const [editMemberModal, setEditMemberModal] = useState({
        opened: false,
        data: null,
        mode: 'create', // can be "edit"
        teamsData: null,
        organizationData: null,
    });

    const [deleteTeamConfirmModal, setDeleteTeamConfirmModal] = useState({
        opened: false,
        itemId: null,
    });
    const [deleteMembershipConfirmModal, setDeleteMembershipConfirmModal] = useState({
        opened: false,
        itemId: null,
    });

    const [teamsTableData, setTeamsTableData] = useState(null);
    const [membersTableData, setMembersTableData] = useState(null);

    const organizationData = useGetRequest(
        // TODO: update this to pull from the URL parameter instead of the default user org id
        { url: API.ROUTES.organization.organization + orgId + '/' },
        client
    );
    const memberDetail = useGetRequest({}, client);

    const organizationCreate = usePostRequest(
        { url: API.ROUTES.organization.organization },
        client
    );
    const teamDelete = useDeleteRequest({}, client);

    const fetchData = async () => {
        const { response, success, error } = await organizationData.request();
        if (success) {
            setMembersTableData(
                transformDataMembersTable(response.organization_data.organization_members)
            );
            setTeamsTableData(transformDataTeamsTable(response.organization_data.teams));
            setUserRole(response.organization_data.organization_member_role);
            setOrgPlan(response.organization_data.billing_account?.plan);
            setEditMemberModal((prevState) => ({
                ...prevState,
                teamsData: response.organization_data.teams,
                organizationData: response.organization_data.organization,
            }));
        }
        if (error) {
            navigate('/profile');
        }
    };

    useEffect(() => {
        const firstFetchOrgData = async () => {
            setIsDataLoading(true);
            await fetchData();
            setIsDataLoading(false);
        };

        firstFetchOrgData();
    }, []);

    const handleUpdateTeam = () => {
        fetchData();
    };

    const handleDeleteTeamConfirm = async (teamId) => {
        const { success, error } = await teamDelete.request({
            url: `${API.ROUTES.organization.team}${teamId}/`,
        });
        if (success) {
            setDeleteTeamConfirmModal({
                opened: false,
            });
            fetchData();
        }
    };

    const handleDeleteMembershipConfirm = async (membershipId) => {
        const { success, error } = await teamDelete.request({
            url: `${API.ROUTES.organization.organizationMembership}${membershipId}/`,
        });
        if (success) {
            setDeleteMembershipConfirmModal({
                opened: false,
            });
            fetchData();
        }
    };

    const handleUpdateMember = () => {
        fetchData();
    };

    const handleAddOrganization = () => {
        fetchData();
    };

    const fetchMemberDetail = async (memberId) => {
        const { success, response } = await memberDetail.request({
            url: `${API.ROUTES.organization.organizationMembership}${memberId}/`,
        });
        if (success) {
            console.log(response);
            setEditMemberModal((prevState) => ({
                ...prevState,
                opened: true,
                mode: 'edit',
                data: response,
                teamsData: response.teams,
            }));
        }
    };

    const organizationDataRes = organizationData.response?.organization_data;

    const isOrganizationOwnerOrAdmin =
        organizationDataRes && (userRole === 'admin' || userRole === 'owner');

    const canDeleteRole = (rowRole) => {
        // Return true only if the rowRole is not 'owner'
        return rowRole !== 'owner';
    };

    const memberRowActionButtons =
        isOrganizationOwnerOrAdmin && orgPlan !== ORGANIZATION_PLAN.plus
            ? [
                  {
                      action: 'edit',
                      handleAction: (row) => {
                          fetchMemberDetail(row.id);
                      },
                  },
                  {
                      action: 'delete',
                      handleAction: (row) => {
                          if (canDeleteRole(row.role)) {
                              // Allow the delete action
                              setDeleteMembershipConfirmModal({
                                  opened: true,
                                  itemId: row.id,
                              });
                          } else {
                              // Show a message or prevent the action
                              setErrorMessage("You can't delete the Owner of an organization.");
                          }
                      },
                  },
              ]
            : [];

    const teamRowActionButtons = isOrganizationOwnerOrAdmin
        ? [
              {
                  action: 'edit',
                  handleAction: (row) => {
                      setEditTeamModal({
                          opened: true,
                          data: row,
                          mode: 'edit',
                      });
                  },
              },
              {
                  action: 'delete',
                  handleAction: (row) => {
                      setDeleteTeamConfirmModal({
                          opened: true,
                          itemId: row.id,
                      });
                  },
              },
          ]
        : [];

    const handleOrgNameEdit = async (newName) => {
        try {
            await client.patch(`${API.ROUTES.organization.organization}${orgId}/`, {
                name: newName,
            });
        } catch (e) {
            return true;
        }
    };

    return (
        <div className="">
            {deleteTeamConfirmModal.opened && (
                <ConfirmationModal
                    onClose={() =>
                        setDeleteTeamConfirmModal({
                            opened: false,
                        })
                    }
                    onConfirm={handleDeleteTeamConfirm}
                    itemId={deleteTeamConfirmModal.itemId}
                    confirmText="Are you sure to delete this team?"
                />
            )}

            {deleteMembershipConfirmModal.opened && (
                <ConfirmationModal
                    onClose={() =>
                        setDeleteMembershipConfirmModal({
                            opened: false,
                        })
                    }
                    onConfirm={handleDeleteMembershipConfirm}
                    itemId={deleteMembershipConfirmModal.itemId}
                    confirmText="Are you sure to delete this member?"
                />
            )}

            {addOrganizationModal.opened && (
                <AddOrganizationModal
                    onClose={() =>
                        setAddOrganizationModal({
                            opened: false,
                        })
                    }
                    handleSave={handleAddOrganization}
                    organizationCreate={organizationCreate}
                />
            )}

            {editTeamModal.opened && (
                <EditTeamModal
                    data={editTeamModal.data}
                    onClose={() =>
                        setEditTeamModal({
                            opened: false,
                            data: null,
                            mode: 'create',
                        })
                    }
                    handleSave={handleUpdateTeam}
                    mode={editTeamModal.mode}
                    organizationId={organizationDataRes?.organization.id}
                />
            )}

            {editMemberModal.opened && (
                <EditMembershipModal
                    data={editMemberModal.data}
                    onClose={() =>
                        setEditMemberModal((prevState) => ({
                            ...prevState,
                            opened: false,
                            data: null,
                            mode: 'create',
                        }))
                    }
                    handleSave={handleUpdateMember}
                    mode={editMemberModal.mode}
                    organizationData={editMemberModal.organizationData}
                    teamsData={editMemberModal.teamsData}
                />
            )}

            <div className="mb-5">
                {isOrganizationOwnerOrAdmin ? (
                    <EditableTextArea
                        initialText={organizationDataRes?.organization.name}
                        textStyle="font-heading-bold text-heading-bold-l text-black"
                        onSave={handleOrgNameEdit}
                        errorMessage="Oops! Something went wrong while editing Organization Name. Please try again."
                    />
                ) : (
                    <h1 className="font-heading-bold text-heading-bold-l text-black">
                        {organizationDataRes?.organization.name}
                    </h1>
                )}
            </div>

            {isDataLoading && (
                <div className="w-full">
                    <Loading />
                </div>
            )}

            {organizationDataRes && Object.keys(organizationDataRes).length === 0 ? (
                // <div className="p-4 md:pt-4 md:pb-8 md:px-8 rounded-md flex flex-col w-full bg-white space-y-4">
                //     <div className="font-body-bold text-body-bold-m">
                //         Begin sharing and set up a team
                //     </div>
                //     <div className="w-full max-w-[320px]">
                //         <Button
                //             type="primary"
                //             text="Create your Organization"
                //             size="sm"
                //             onClick={() => setAddOrganizationModal({ opened: true })}
                //         />
                //     </div>
                // </div>
                <div className="p-4 md:pt-4 md:pb-8 md:px-8 rounded-md flex flex-col w-full bg-white space-y-4">
                    <div className="font-body-bold text-body-bold-m">
                        You need to have an <strong>Organization</strong> to enable teams and
                        sharing! Contact us to set up an Organization.
                    </div>
                    <div className="w-full max-w-[320px]">
                        <Button
                            onClick={handleContactUs}
                            text={`Contact Us`}
                            type="primary"
                            size="sm"
                        />
                    </div>
                </div>
            ) : (
                <>
                    {!isDataLoading && (
                        <div className="flex flex-col gap-5">
                            <div className="p-4 md:pt-4 md:pb-8 md:px-8 rounded-md flex flex-col w-full bg-white space-y-4">
                                <div className="font-body-bold text-body-bold-l">
                                    Organization Members
                                </div>
                                {organizationDataRes &&
                                    organizationDataRes?.organization_members && (
                                        <>
                                            <div className="w-full relative">
                                                <Table
                                                    data={membersTableData}
                                                    size="md"
                                                    rowActionButtons={memberRowActionButtons}
                                                    tableActionButton={
                                                        isOrganizationOwnerOrAdmin
                                                            ? orgPlan === ORGANIZATION_PLAN.plus
                                                                ? {
                                                                      buttonText:
                                                                          'Contact us for Team Sharing',
                                                                      buttonSize: 'sm',
                                                                      onClick: handleContactUs,
                                                                  }
                                                                : {
                                                                      buttonText: 'Add Member',
                                                                      buttonSize: 'sm',
                                                                      onClick: () => {
                                                                          setEditMemberModal(
                                                                              (prevState) => ({
                                                                                  ...prevState,
                                                                                  opened: true,
                                                                              })
                                                                          );
                                                                      },
                                                                  }
                                                            : null
                                                    }
                                                    includePagination={false}
                                                    columnsCount={1}
                                                    shift={560}
                                                />
                                            </div>
                                        </>
                                    )}
                                {orgPlan !== ORGANIZATION_PLAN.plus && (
                                    <>
                                        <div className="font-body-bold text-body-bold-l">
                                            Organization Teams
                                        </div>
                                        {organizationDataRes && organizationDataRes?.teams && (
                                            <div className="w-full relative">
                                                <Table
                                                    data={teamsTableData}
                                                    size="md"
                                                    rowActionButtons={teamRowActionButtons}
                                                    tableActionButton={
                                                        isOrganizationOwnerOrAdmin
                                                            ? {
                                                                  buttonText: 'Create a Team',
                                                                  buttonSize: 'sm',
                                                                  onClick: () => {
                                                                      setEditTeamModal(
                                                                          (prevState) => ({
                                                                              ...prevState,
                                                                              opened: true,
                                                                          })
                                                                      );
                                                                  },
                                                              }
                                                            : null // Render nothing if the condition is not met
                                                    }
                                                    includePagination={false}
                                                    shift={110}
                                                    columnsCount={1}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>

                            <OrganizationConnectionsBlock orgId={orgId} orgPlan={orgPlan} />

                            <ApiKeyBlock orgId={orgId} />
                        </div>
                    )}
                </>
            )}
            {errorMessage && (
                <Alert
                    status="critical"
                    message={
                        errorMessage ||
                        'Something went wrong. Please contact us if the issue persists'
                    }
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorMessage(null)}
                />
            )}
        </div>
    );
};

export default OrganizationBlock;
