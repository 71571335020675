import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { TASK_TYPES } from '../../constants/library';
import { integerRegex } from '../../constants/regex_patterns';
import { formatKeyToLabel } from '../../helpers/formatKeyToLabel';

import NewTextAreaBox from '../../design-system/NewTextAreaBox/NewTextAreaBox';
import Checkbox from '../../design-system/Checkbox/Checkbox';
import TaskInputHeader from '../TaskInputHeader/TaskInputHeader';
import Radio from '../../design-system/Radio/Radio';
import { Input, Select } from '../../design-system';

TaskInputsContainer.propTypes = {
    inputsData: PropTypes.array.isRequired,
    setInputsData: PropTypes.func,
    placeholder: PropTypes.string,
    withCopyButton: PropTypes.bool,
    withDeleteButton: PropTypes.bool,
    onDeleteButtonClick: PropTypes.func,
    protectedMode: PropTypes.bool,
    errorMessage: PropTypes.string,
    hasEditAccess: PropTypes.bool,
    taskType: PropTypes.oneOf([
        'prompt',
        'inbox',
        'retrieval',
        'trigger',
        'webhook',
        'assistant',
        'function',
        'record',
        'api',
    ]),
};

const booleanTypeOptions = [
    { name: 'True', value: true },
    { name: 'False', value: false },
];
function TaskInputsContainer(
    {
        inputsData,
        setInputsData,
        taskType,
        placeholder,
        withCopyButton,
        withDeleteButton,
        onDeleteButtonClick,
        protectedMode,
        errorMessage,
        hasEditAccess,
    },
    ref
) {
    const handleInputsDataChange = (newValue, id) => {
        setInputsData((prevState) => {
            return prevState.map((input) =>
                input.id === id ? { ...input, value: newValue, state: 'default' } : input
            );
        });
    };

    const handleMultiSelectChange = (id, option) => {
        setInputsData((prevData) =>
            prevData.map((item) => {
                if (item.id === id) {
                    const newValue = item.value?.includes(option)
                        ? item.value.filter((el) => el !== option)
                        : [...(item.value || []), option];
                    return { ...item, value: newValue };
                } else {
                    return item;
                }
            })
        );
    };

    const handleRadioButtonChange = (id, option) => {
        setInputsData((prevData) =>
            prevData.map((item) => (item.id === id ? { ...item, value: option } : item))
        );
    };

    const handleIntegerInputChange = (e, id) => {
        const inputValue = e.target.value;

        if (integerRegex.test(inputValue)) {
            setInputsData((prevData) =>
                prevData.map((item) => (item.id === id ? { ...item, value: +inputValue } : item))
            );
        }
    };

    const handleSelectChange = (id, option) => {
        setInputsData((prevData) =>
            prevData.map((item) => (item.id === id ? { ...item, value: option } : item))
        );
    };

    return (
        <div className="flex flex-col gap-4 flex-grow" ref={ref}>
            {!!inputsData?.length &&
                inputsData.map((item) => {
                    const tags = [{ text: formatKeyToLabel(item.type || ''), color: 'neutral' }];
                    const label = taskType === TASK_TYPES.api ? `{{${item.key}}}` : `[${item.key}]`;
                    if (item.action_type) {
                        tags.push({
                            text: formatKeyToLabel(item.action_type || ''),
                            color: 'peach',
                        });
                    }

                    const assistantWarningText =
                        item.missedInUserMessage && `${label} not used in your user message`;
                    const agentWarningText =
                        item.missedInUserAndSystemMessages && `${label} not used in your messages`;
                    const apiWarningText =
                        item.missedInApiRequest && `${label} not used in your api request`;

                    const warningText = {
                        [TASK_TYPES.assistant]: assistantWarningText,
                        [TASK_TYPES.function]: agentWarningText,
                        [TASK_TYPES.api]: apiWarningText,
                    };

                    const warningInfo = {
                        text: warningText[taskType],
                        state: 'error',
                    };

                    const selectOptions = item.options
                        ? Object.values(item.options)?.map((option) => ({
                              id: option,
                              name: option,
                          }))
                        : [];

                    return (
                        <div className="flex flex-grow flex-col gap-[6px]" key={item.id}>
                            {item.action_type === 'multi-select' ||
                            item.action_type === 'single-select' ||
                            (!item.action_type &&
                                (item.type === 'boolean' ||
                                    item.type === 'integer' ||
                                    item.type === 'number' ||
                                    item.options)) ? (
                                <TaskInputHeader
                                    id={item.id}
                                    label={item.action_type ? item.label : label}
                                    isRequired={item.is_required}
                                    state={hasEditAccess ? item.state || 'default' : 'disabled'}
                                    errorMessage={errorMessage}
                                    value={item.value}
                                    withCopyButton={
                                        item.type === 'integer' || item.type === 'number'
                                    }
                                    withDeleteButton={
                                        withDeleteButton && !protectedMode && hasEditAccess
                                    }
                                    onDeleteButtonClick={onDeleteButtonClick}
                                    protectedMode={protectedMode}
                                    trailingTags={tags}
                                    info={warningInfo}
                                >
                                    {item.action_type === 'multi-select' && (
                                        <ul className="flex flex-col gap-1">
                                            {Object.values(item.options)?.map((option, index) => (
                                                <li key={index} className="py-1 px-2">
                                                    <Checkbox
                                                        name={option}
                                                        label={option}
                                                        isChecked={item.value?.includes(option)}
                                                        state={
                                                            hasEditAccess
                                                                ? item.state || 'default'
                                                                : 'disabled'
                                                        }
                                                        onChange={() =>
                                                            handleMultiSelectChange(item.id, option)
                                                        }
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    {item.action_type === 'single-select' && (
                                        <ul className="flex flex-col gap-1">
                                            {Object.values(item.options)?.map((option, index) => (
                                                <li key={index} className="py-1 px-2">
                                                    <Radio
                                                        isSelected={option === item.value}
                                                        name={option}
                                                        state={
                                                            hasEditAccess
                                                                ? item.state || 'default'
                                                                : 'disabled'
                                                        }
                                                        onChange={() =>
                                                            handleRadioButtonChange(item.id, option)
                                                        }
                                                        label={option}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    {!item.action_type &&
                                        (item.options ? (
                                            <Select
                                                size="sm"
                                                name={item.key}
                                                value={item.value}
                                                options={selectOptions}
                                                state={
                                                    hasEditAccess
                                                        ? item.state || 'default'
                                                        : 'disabled'
                                                }
                                                onChange={(option) =>
                                                    handleSelectChange(item.id, option)
                                                }
                                            />
                                        ) : (
                                            <>
                                                {item.type === 'boolean' && (
                                                    <ul className="flex flex-col gap-1">
                                                        {booleanTypeOptions.map((option) => (
                                                            <li
                                                                key={option.value}
                                                                className="py-1 px-2"
                                                            >
                                                                <Radio
                                                                    isSelected={
                                                                        option.value === item.value
                                                                    }
                                                                    name={option.name}
                                                                    state={
                                                                        hasEditAccess
                                                                            ? item.state ||
                                                                              'default'
                                                                            : 'disabled'
                                                                    }
                                                                    onChange={() =>
                                                                        handleRadioButtonChange(
                                                                            item.id,
                                                                            option.value
                                                                        )
                                                                    }
                                                                    label={option.name}
                                                                />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                                {item.type === 'integer' && (
                                                    <Input
                                                        size="xs"
                                                        value={item.value}
                                                        name={item.key}
                                                        isRequired
                                                        type="text"
                                                        placeholder="0"
                                                        state={
                                                            hasEditAccess
                                                                ? item.state || 'default'
                                                                : 'disabled'
                                                        }
                                                        onChange={(e) =>
                                                            handleIntegerInputChange(e, item.id)
                                                        }
                                                    />
                                                )}
                                                {item.type === 'number' && (
                                                    <Input
                                                        size="xs"
                                                        value={item.value}
                                                        name={item.key}
                                                        isRequired
                                                        placeholder="0.1"
                                                        type="number"
                                                        step="0.1"
                                                        state={
                                                            hasEditAccess
                                                                ? item.state || 'default'
                                                                : 'disabled'
                                                        }
                                                        onChange={(e) =>
                                                            handleInputsDataChange(
                                                                e.target.value,
                                                                item.id
                                                            )
                                                        }
                                                    />
                                                )}
                                            </>
                                        ))}
                                </TaskInputHeader>
                            ) : (
                                <NewTextAreaBox
                                    id={item.id}
                                    name={item.key}
                                    value={item.value}
                                    label={label}
                                    isRequired={item.is_required || false}
                                    placeholder={placeholder || 'Write here'}
                                    state={hasEditAccess ? item.state || 'default' : 'disabled'}
                                    errorMessage={errorMessage}
                                    onChange={(e) =>
                                        handleInputsDataChange(e.target.value, item.id)
                                    }
                                    withCopyButton={withCopyButton}
                                    withDeleteButton={
                                        withDeleteButton && !protectedMode && hasEditAccess
                                    }
                                    onDeleteButtonClick={onDeleteButtonClick}
                                    protectedMode={protectedMode}
                                    trailingTags={tags}
                                    info={warningInfo}
                                />
                            )}
                        </div>
                    );
                })}
        </div>
    );
}

const ForwardedTaskInputsContainer = forwardRef(TaskInputsContainer);
export default ForwardedTaskInputsContainer;
