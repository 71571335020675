import React, { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { parseJobsSearchParams } from '../../../helpers/parseJobsSearchParams';
import { handleSortOptionSelect } from '../../../helpers/handleUpdateSearchParams';

import { TAB, TAB_LABEL } from '../../../constants/jobsAndProjects';
import { jobsSortOptions, SORT_TYPES } from '../../../constants/sort';

import { Tabs } from '../../../design-system';
import SearchBarWithUrlParamsHandling from '../../../components/SearchBarWithUrlParamsHandling/SearchBarWithUrlParamsHandling';
import SortDropdown from '../../../components/SortDropdown/SortDropdown';
import FilterBlock from '../FilterBlock/FilterBlock';

export const tabs = [TAB.jobs, TAB.projects];
const formattedTabs = tabs.map((tab) => ({ name: TAB_LABEL[tab], key: tab }));

const PageTopControls = ({ activeTab, isMobile, resetPageState }) => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = useMemo(() => parseJobsSearchParams(searchParams), [searchParams]);

    const activeTabIndex = Math.max(tabs.indexOf(activeTab), 0);

    const [isSortDropdownOpened, setIsSortDropdownOpened] = useState(false);

    const closeSortDropdown = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setIsSortDropdownOpened(false);
    };

    const handleJobsSortOptionSelect = (sortOption) => {
        handleSortOptionSelect({
            sortOption,
            setSearchParams,
            searchParams,
            usePagination: !isMobile,
            usePageState: true,
            resetPageState,
            onSearchDropdownClose: closeSortDropdown,
        });
    };

    // const handleAddProcessFilterOption = (process) => {
    //     handleAddNewFilterOption({
    //         searchParams,
    //         setSearchParams,
    //         currentFilters: parsedParams.process,
    //         newFilter: process.id,
    //         usePagination: !isMobile,
    //         usePageState: true,
    //         resetPageState,
    //         searchParamKey: activeTab === TAB.jobs ? 'process__in' : 'playbook__in',
    //         shouldSortFilters: true,
    //     });
    // };
    //
    // const handleRemoveProcessFilterOption = (process) => {
    //     handleRemoveFilterOption({
    //         searchParams,
    //         setSearchParams,
    //         currentFilters: parsedParams.process,
    //         filterToDelete: process.id,
    //         usePagination: !isMobile,
    //         usePageState: true,
    //         resetPageState,
    //         searchParamKey: activeTab === TAB.jobs ? 'process__in' : 'playbook__in',
    //     });
    // };

    const handleTabChange = (tab) => {
        resetPageState();

        const URL = isMobile
            ? `/${tab.key}?sort=${SORT_TYPES.created}`
            : `/${tab.key}?page=1&sort=${SORT_TYPES.created}`;
        navigate(URL);
    };

    const sortDropdownPosition = {
        top: '120%',
        left: 0,
    };

    return (
        <div className="flex flex-col ql:flex-row ql:items-center gap-3 w-full relative justify-between">
            <div>
                <div className="flex items-center gap-4 relative min-w-max">
                    <Tabs
                        tabs={formattedTabs}
                        tabIndex={activeTabIndex}
                        onTabChanged={handleTabChange}
                    />

                    <div className="min-[680px]:relative">
                        <button
                            className="border-0 outline-0 bg-none text-[14px] font-bold underline underline-offset-[3px]"
                            onClick={() => setIsSortDropdownOpened(true)}
                        >
                            Sort
                        </button>
                        {isSortDropdownOpened && (
                            <SortDropdown
                                options={jobsSortOptions}
                                selectedOption={parsedParams.sort}
                                handleOptionSelect={handleJobsSortOptionSelect}
                                dropdownPosition={sortDropdownPosition}
                                onClose={closeSortDropdown}
                            />
                        )}
                    </div>

                    <FilterBlock
                        activeTab={activeTab}
                        parsedParams={parsedParams}
                        isMobile={isMobile}
                        resetPageState={resetPageState}
                    />
                </div>
            </div>
            <div className="ql:max-w-[300px] w-full">
                <SearchBarWithUrlParamsHandling
                    size="xs"
                    placeholder="Search by keyword"
                    usePagination={!isMobile}
                    resetPageState={resetPageState}
                    usePageState={true}
                />
            </div>
        </div>
    );
};

export default PageTopControls;
