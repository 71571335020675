import React from 'react';
import classNames from 'classnames';

import { Badge } from '../../../design-system';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';

const ChatInputDiviBotHeader = ({ documentData, isMobile, removeSelectedDoc }) => {
    const { label } = documentData;

    const containerClassName = classNames(
        'bg-neutral-50 relative z-10 -mb-3 pt-2.5 pb-[22px] px-4 rounded-2 border-1 border-neutral-200 flex gap-x-4 gap-y-1 max-w-full',
        { 'flex-row items-center': !isMobile, 'flex-col': isMobile }
    );

    return (
        <div className={containerClassName}>
            <p className="font-body text-body-regular-xs text-neutral-300 whitespace-nowrap">
                Chatting with knowledge from
            </p>

            <div className="flex-1 overflow-hidden">
                <Badge
                    text={label}
                    leadingIcon={FileTextLineIcon}
                    leadingIconColor="#754DCF"
                    color="purple"
                    removeable
                    handleRemove={removeSelectedDoc}
                />
            </div>
        </div>
    );
};

export default ChatInputDiviBotHeader;
