import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';
import { changeItemsOrderUsingDragAndDrop } from '../../../helpers/changeItemsOrderUsingDragAndDrop';
import DraggableLineIcon from '../../../design-system/Icons/DraggableLineIcon';
import PlaybookContentBlock from '../PlaybookContentBlock/PlaybookContentBlock';

const DraggablePlaybookBlock = ({
    mode,
    blockData,
    sectionTemporaryId,
    setContentBlock,
    connectedAgents,
    linkedDocsDataState,
    connectedAgentsDetailedDataState,
}) => {
    const { order, temporaryId } = blockData;

    const blockRef = useRef(null);
    const dragIconRef = useRef(null);

    const { isDesktop } = useResponsiveBreakpoints();
    const [isHovered, setIsHovered] = useState(false);

    const isDragAndDropEnabled = isDesktop && mode === PLAYBOOK_BUILDER_MODE.edit;

    const [{ handlerId }, drop] = useDrop({
        accept: 'BLOCK',
        item: {
            order,
            dndType: 'BLOCK',
            sectionTemporaryId,
        },
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
        }),
        hover(draggingItem, monitor) {
            if (!blockRef.current) {
                return;
            }

            if (draggingItem.sectionTemporaryId !== sectionTemporaryId) {
                return;
            }

            const dragOrder = draggingItem.order;
            const hoverOrder = order;

            if (dragOrder === hoverOrder) {
                return;
            }

            const hoverBoundingRect = blockRef.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragOrder < hoverOrder && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragOrder > hoverOrder && hoverClientY > hoverMiddleY) {
                return;
            }

            changeItemsOrderUsingDragAndDrop(dragOrder, hoverOrder, setContentBlock);
            draggingItem.order = hoverOrder;
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: 'BLOCK',
        item: { order, dndType: 'BLOCK', sectionTemporaryId, blockTemporaryId: temporaryId },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(dragIconRef);
    drop(preview(blockRef));

    const blockContainerClassName = classNames('flex ml-[-24px]', {
        'opacity-0': isDragging,
        'opacity-100': !isDragging,
    });
    const draggableIconClassName = classNames('pt-1 transition-opacity', {
        'opacity-100 cursor-grab': isHovered && isDragAndDropEnabled,
        'opacity-0 pointer-events-none': !isHovered || !isDragAndDropEnabled,
    });

    return (
        <div
            ref={blockRef}
            className={blockContainerClassName}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div
                ref={dragIconRef}
                data-handler-id={handlerId}
                draggable={isDragAndDropEnabled}
                className={draggableIconClassName}
            >
                <DraggableLineIcon width={24} height={23} color="#000000" />
            </div>

            <div className="flex-grow max-w-[calc(100%-24px)]">
                <PlaybookContentBlock
                    mode={mode}
                    blockData={blockData}
                    setContentBlock={setContentBlock}
                    connectedAgents={connectedAgents}
                    linkedDocsDataState={linkedDocsDataState}
                    connectedAgentsDetailedDataState={connectedAgentsDetailedDataState}
                />
            </div>
        </div>
    );
};

export default DraggablePlaybookBlock;
