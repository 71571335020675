import { TOOL_TYPE } from '../constants/assistant';

export const getFormattedSelectedTools = ({ selectedTools }) => {
    return Object.entries(selectedTools).map(([type, { details }]) => {
        if (type === TOOL_TYPE.add_document) {
            return { type, details: { document: details?.documentData?.id } };
        }
        return { type, details };
    });
};

export const appendModelAndToolsToRequestBody = ({
    requestBody,
    selectedAiModel,
    isDiviBot,
    selectedTools,
}) => {
    const updatedRequestBody = {
        ...requestBody,
        model: selectedAiModel,
        is_divibot: !!isDiviBot,
    };

    const areToolsSelected = isDiviBot && !!Object.keys(selectedTools).length;
    if (areToolsSelected) {
        updatedRequestBody.tools = getFormattedSelectedTools({ selectedTools });
    }

    return updatedRequestBody;
};
