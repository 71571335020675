import React, { useEffect, useRef } from 'react';

const JobSubmissionDetailItem = ({ label, value }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!ref) return;

        ref.current.innerHTML = value;
    }, [ref, value]);

    return (
        <li>
            <p className="font-body-bold text-body-bold-s">{label}</p>
            <div
                ref={ref}
                className="font-body text-body-regular-s"
                style={{ lineHeight: '150%' }}
            ></div>
        </li>
    );
};

export default JobSubmissionDetailItem;
