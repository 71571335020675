import { useState } from 'react';
import PropTypes from 'prop-types';

export default function TabItem({ name, shortcut, index, onClick, logAs, current, vertical }) {
    const [isHovering, setIsHovering] = useState(false);
    const containerClasses = `${current ? 'bg-white' : 'bg-neutral-500'} 
  border rounded-lg border-neutral-500 inline-flex py-[7px] px-4 leading-[100%] justify-center items-center gap-2 cursor-pointer flex-grow
  ${vertical && 'self-stretch'}`;

    let tabNameColor = 'white';
    if (current) {
        tabNameColor = 'neutral-500';
    }
    if (isHovering) {
        tabNameColor = 'neutral-300';
    }

    const nameClasses = `text-${tabNameColor} font-body text-[14px] font-semibold transition-colors`;
    const shortcutClasses = `text-neutral-300 font-body text-[14px] font-normal leading-5`;

    return (
        <div
            key={`${name}-${index}`}
            onClick={() => onClick && onClick(index)}
            onMouseEnter={() => {
                setIsHovering(true);
            }}
            onMouseLeave={() => {
                setIsHovering(false);
            }}
            className={containerClasses}
            {...(logAs ? { 'data-dd-action-name': logAs } : {})}
        >
            <p className={nameClasses}>{name}</p>
            {shortcut && <p className={shortcutClasses}>{shortcut}</p>}
        </div>
    );
}

TabItem.propTypes = {
    name: PropTypes.string.isRequired,
    shortcut: PropTypes.string,
    current: PropTypes.bool.isRequired,
    index: PropTypes.number,
    onClick: PropTypes.func,
    logAs: PropTypes.string,
};
