import React, { useState } from 'react';

import { useFormState } from '../../../../hooks/useFormState';

import SelectButton from '../../../SelectButton/SelectButton';
import SelectAgentDropdown from '../../../../pages/PlaybookBuilderPage/SelectAgentDropdown/SelectAgentDropdown';
import PlayCircleFillIcon from '../../../Icons/PlayCircleFillIcon';
import GenericBlockEmptyStateWrapper from '../../GenericBlockEmptyStateWrapper/GenericBlockEmptyStateWrapper';

const UnconfirmedAgentBlock = ({
    isEditable,
    agentOptionsHookResponse,
    handleDeleteBlock,
    handleConfirmAgent,
}) => {
    const {
        formData: { selectedAgent },
        fieldErrorMessages: { selectedAgent: errorMessage },
        handleInputChange,
        setFieldErrorMessages,
    } = useFormState({ selectedAgent: null });

    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const onConfirmAgentClick = () => {
        if (!selectedAgent?.id) {
            setFieldErrorMessages({ selectedAgent: 'Please select an agent' });
            return;
        }

        handleConfirmAgent(selectedAgent);
    };

    const toggleDropdownOpened = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setIsDropdownOpened((prevState) => !prevState);
    };

    const selectState = !isEditable ? 'disabled' : errorMessage ? 'error' : 'default';

    const formattedSelectedAgent = selectedAgent
        ? {
              ...selectedAgent,
              leadingIcon: PlayCircleFillIcon,
          }
        : null;

    return (
        <GenericBlockEmptyStateWrapper
            isEditable={isEditable}
            isError={!!errorMessage}
            handleDeleteBlock={handleDeleteBlock}
            handleSubmit={onConfirmAgentClick}
        >
            <SelectButton
                size="xs"
                state={selectState}
                selectedItem={formattedSelectedAgent}
                label="Connected Agent"
                placeholder="Select an agent"
                errorMessage={errorMessage}
                isDropdownOpened={isDropdownOpened}
                toggleDropdownOpened={toggleDropdownOpened}
                showAsBadge
                badgeColor={`${selectedAgent?.leadingIconColor + '33'}`} // + "33" to add 20% opacity
            />

            {isDropdownOpened && (
                <SelectAgentDropdown
                    agentOptionsHookResponse={agentOptionsHookResponse}
                    selectedAgentId={selectedAgent?.id}
                    handleAgentSelect={(agent) => handleInputChange('selectedAgent', agent)}
                    onClose={toggleDropdownOpened}
                />
            )}
        </GenericBlockEmptyStateWrapper>
    );
};

export default UnconfirmedAgentBlock;
