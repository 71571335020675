import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ArrowGoBackLineIcon, FlashlightFillIcon } from '../../../../design-system/Icons';
import Button from '../../../../design-system/Button/Button';

const PageTopNavBlock = ({ processId, playbookId }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const playbookHref = playbookId ? `/playbook/${playbookId}` : '/playbooks';

    return (
        <div className="py-3 px-4 sm:px-6 bg-white border-b-1 border-neutral-200 flex items-center justify-between gap-x-5 gap-y-1 flex-wrap">
            <div className="flex items-center gap-x-5 gap-y-0.5">
                <div className="min-w-fit">
                    <Button
                        type="link"
                        size="xs"
                        text="Back to Playbooks"
                        leadingIcon={ArrowGoBackLineIcon}
                        onClick={() => navigate(playbookHref, { state: { from: location } })}
                    />
                </div>

                <p className="font-body text-body-regular-s text-neutral-300">
                    Agents / Agent Overview
                </p>
            </div>
            <div className="min-w-max">
                <Button
                    type="primary"
                    size={'sm'}
                    text="Start New Job"
                    trailingIcon={FlashlightFillIcon}
                    onClick={() =>
                        navigate(`/agent/${processId}/start`, { state: { from: location } })
                    }
                />
            </div>
        </div>
    );
};

export default PageTopNavBlock;
