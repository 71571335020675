import React from 'react';
import classNames from 'classnames';

import { useRearrangeGridItems } from '../../../../hooks/useRearrangeGridItems';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import EditLineIcon from '../../../../design-system/Icons/EditLineIcon';
import { ButtonIcon } from '../../../../design-system';
import { DraggableIcon } from '../../../../design-system/Icons';

const DEFAULT_CARD_NAME = 'Element';

const NestedInputValueCard = ({
    item,
    itemName,
    isDisabled,
    setItemModal,
    handleValueChange,
    firstStringPropertyName,
    columnNumber,
}) => {
    const { order, data } = item;
    const { isDesktop } = useResponsiveBreakpoints();

    const { itemRef, dragIconRef, isDragging, handlerId } = useRearrangeGridItems({
        itemType: 'ITEM',
        item,
        setData: handleValueChange,
        columns: columnNumber,
    });

    const isDragAndDropEnabled = isDesktop && !isDisabled;

    const containerClassName = classNames(
        'w-full h-[140px] min-h-[140px] rounded-2 border-1 border-neutral-200 p-3 flex flex-col gap-1 overflow-hidden',
        isDragging ? 'opacity-0' : 'opacity-100',
        isDisabled ? 'bg-neutral-50' : 'bg-white'
    );

    const buttonsContainerClassName = classNames(
        'flex items-center gap-2',
        isDisabled ? 'hidden' : 'block'
    );

    return (
        <div className={containerClassName} ref={isDragAndDropEnabled ? itemRef : null}>
            <div className="flex items-center justify-between gap-3">
                <p className="font-body-bold text-body-bold-xs text-neutral-300 uppercase">
                    {itemName} {order}
                </p>

                <div className={buttonsContainerClassName}>
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={EditLineIcon}
                        onClick={() => setItemModal({ mode: 'edit', item })}
                    />
                    <div
                        ref={isDragAndDropEnabled ? dragIconRef : null}
                        data-handler-id={handlerId}
                        className={`cursor-grab ${isDragAndDropEnabled ? 'block' : 'hidden'}`}
                    >
                        <ButtonIcon
                            type="neutral"
                            size="xs"
                            icon={DraggableIcon}
                            cursor="grab"
                            onClick={() => {}}
                        />
                    </div>
                </div>
            </div>
            <p className="font-heading-bold text-heading-bold-s text-neutral-500 line-clamp-3">
                {data[firstStringPropertyName] || `${DEFAULT_CARD_NAME} ${order}`}
            </p>
        </div>
    );
};

export default NestedInputValueCard;
