import React from 'react';

import { sectionContainerStyles } from '../PlaybookDetailsPanel/PlaybookDetailsPanel';
import PlaybookInfoBadges from '../PlaybookInfoBadges/PlaybookInfoBadges';

const PlaybookMainInfo = ({ playbook }) => {
    const { name, description, process_count, sections } = playbook;

    return (
        <div className={`flex flex-col gap-4 ${sectionContainerStyles}`}>
            <div>
                <p className="font-heading-regular font-[600] text-[20px] lg:text-[24px] leading-[26px] text-black mb-1">
                    {name}
                </p>
                <p className="font-body text-body-regular-s text-neutral-300">{description}</p>
            </div>

            <PlaybookInfoBadges processes={process_count} sections={sections} />
        </div>
    );
};

export default PlaybookMainInfo;
