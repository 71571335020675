import { isValidJson } from './isValidJson';
import { defaultErrorMessage } from '../constants/errorMessages';
import { generateUUID } from './generateUUID';

const actionsWithArrayValue = ['multi-input', 'multi-select', 'multi-dropdown', 'nested-input'];

export const getSettingsFormDataInitialState = (settings) => {
    return settings?.map((item) => {
        if (item.action === 'json-input' && !item.value) {
            return { ...item, value: {}, state: 'default', errorMessage: null }; // set initial value for "json-input"
        }

        if (item.action === 'nested-input') {
            const formattedValue =
                item.value?.map((item, index) => ({
                    order: index + 1,
                    temporaryId: generateUUID(),
                    data: item,
                })) || [];
            return { ...item, value: formattedValue, state: 'default', errorMessage: null };
        }

        if (actionsWithArrayValue.includes(item.action) && !item.value) {
            return { ...item, value: [], state: 'default', errorMessage: null }; // set initial value for 'multi-input', 'multi-select', 'multi-dropdown'
        }

        if (item.action === 'toggle' && !item.value) {
            return { ...item, value: false, state: 'default', errorMessage: null }; // set initial value for 'toggle'
        }

        return { ...item, state: 'default', errorMessage: null };
    });
};

export const mergeValuesIntoSettingsFormData = (formData, valuesArray) => {
    // an object if format {name: value} for faster lookup
    const settingValuesMap =
        valuesArray?.reduce(
            (acc, { name, value }) => ({
                ...acc,
                [name]: value,
            }),
            {}
        ) || {};

    return formData?.map((item) => {
        // set the value from the valuesArray if it exists
        const updatedValue = settingValuesMap[item.name] || item.value;
        return { ...item, value: updatedValue };
    });
};

export const checkIsRequiredFieldFilled = (item) => {
    const isFieldNotFilled =
        (actionsWithArrayValue.includes(item.action) && !item.value?.length) ||
        (!actionsWithArrayValue.includes(item.action) &&
            !item.value &&
            item.value !== 0 &&
            item.value !== false);

    return item.is_required ? !isFieldNotFilled : true;
};

export const checkIsRequiredNonHiddenFieldFilled = (item) => {
    return item.is_hidden || checkIsRequiredFieldFilled(item);
};

export const checkIfAllRequiredFieldsFilled = (formData) => {
    return formData.every((field) => checkIsRequiredFieldFilled(field));
};

export const checkIfAllNonHiddenRequiredFieldsFilled = (formData) => {
    return formData.every((field) => field.is_hidden || checkIsRequiredFieldFilled(field));
};

export const checkIfDisplayedRequiredFieldsFilled = ({
    formData,
    areHiddenSettingsDisplayed = true,
}) => {
    if (!areHiddenSettingsDisplayed) {
        return checkIfAllNonHiddenRequiredFieldsFilled(formData);
    }
    return checkIfAllRequiredFieldsFilled(formData);
};

export const checkIfInputtedJsonIsValid = (acc, item) => {
    if (item.action === 'json-input' && item.value && typeof item.value === 'string') {
        const isJsonValueValid = isValidJson(item.value);
        if (!isJsonValueValid) {
            return {
                areErrorFields: true,
                updatedFormData: [
                    ...acc.updatedFormData,
                    {
                        ...item,
                        state: 'error',
                        errorMessage: 'Invalid JSON format. Please check your syntax.',
                    },
                ],
            };
        }
    }
};

export const checkIsFormDataValid = (
    settingsFormData,
    setSettingsFormData,
    skipCheckingHiddenSettings
) => {
    const { areErrorFields, updatedFormData } = settingsFormData.reduce(
        (acc, item) => {
            if (skipCheckingHiddenSettings && item.is_hidden) {
                return { ...acc, updatedFormData: [...acc.updatedFormData, item] };
            }

            // check if inputted Json is valid
            const invalidJsonError = checkIfInputtedJsonIsValid(acc, item);
            if (invalidJsonError) {
                return invalidJsonError;
            }

            // check if all required fields are filled
            const isRequiredFieldFilled = checkIsRequiredNonHiddenFieldFilled(item);
            if (isRequiredFieldFilled) {
                return { ...acc, updatedFormData: [...acc.updatedFormData, item] };
            } else {
                return {
                    areErrorFields: true,
                    updatedFormData: [...acc.updatedFormData, { ...item, state: 'error' }],
                };
            }
        },
        { areErrorFields: false, updatedFormData: [] }
    );

    if (areErrorFields) {
        setSettingsFormData(updatedFormData);
        return false; // means that there is a form data error
    }

    return true; // means that there are no form data errors
};

export const getFormattedSettingsFormValue = (item) => {
    let formattedValue = item.value;

    // make value type "number" for action === 'single-input' && type === 'number' (note: we do not have to do this for item.type === 'integer' as that value is already parsedInt)
    if (item.action === 'single-input' && item.type === 'number' && item.value) {
        formattedValue = parseFloat(item.value);
    }

    // parse Json value
    if (item.action === 'json-input' && item.value && typeof item.value === 'string') {
        formattedValue = JSON.parse(item.value);
    }

    if (item.action === 'nested-input' && item.value) {
        formattedValue = item.value?.map(({ data }) => ({ ...data }));
    }

    return formattedValue;
};

export const formatSettingsFormDataForRequestBody = (settingsFormData) => {
    return (settingsFormData || []).map((item) => {
        const formattedValue = getFormattedSettingsFormValue(item);
        return { name: item.name, value: formattedValue };
    });
};

export const getMappedFormErrors = (errorObject, formData, errorKey) => {
    const updatedFormData = formData.map((item) => {
        // if there is an error message for current field set it
        const currentFieldError = errorObject[item[errorKey]];
        if (currentFieldError) {
            const currentFieldErrorMessage =
                typeof currentFieldError === 'string' ? currentFieldError : currentFieldError?.[0];
            return {
                ...item,
                state: 'error',
                errorMessage: currentFieldErrorMessage,
            };
        } else {
            return item;
        }
    });

    return updatedFormData;
};

export const getMappedSettingsFormErrors = (error, settingsFormData) => {
    const settingsErrors = error.response?.data?.error?.settings;
    return getMappedFormErrors(settingsErrors, settingsFormData, 'name');
};

// --------------utils for settings form broken up into steps-------------------
export const getSetStepFormDataFunction = (order, setFormData) => {
    // function that set form data for a specific step
    return (cb) => {
        setFormData((prevData) => ({
            ...prevData,
            [order]: typeof cb === 'function' ? cb(prevData[order]) : cb,
        }));
    };
};

export const formatStepFormDataForRequestBody = (stepFormData) => {
    return (stepFormData || []).map(({ state, errorMessage, ...item }) => {
        const formattedValue = getFormattedSettingsFormValue(item);
        return { ...item, value: formattedValue };
    });
};

export const checkIfEveryStepFormDataValid = (
    formData,
    setFormData,
    skipCheckingHiddenSettings = true
) => {
    const updatedStepsErrorData = {};

    const isValid = Object.entries(formData)
        .map(([order, stepFormData]) => {
            const setStepFormData = getSetStepFormDataFunction(+order, setFormData);
            const isDataValid = checkIsFormDataValid(
                stepFormData,
                setStepFormData,
                skipCheckingHiddenSettings
            );

            if (!isDataValid) {
                // set error state for whole step
                updatedStepsErrorData[order] = { isError: true };
            }
            return isDataValid;
        })
        .every(Boolean);

    return { isValid, updatedStepsErrorData };
};

export const getMappedStepsFormErrors = (errorObject, formData, settings) => {
    const updatedStepsErrorData = {};
    const updatedFormData = Object.entries(formData)
        .map(([order, stepFormData]) => {
            const currentStepName = settings.find((step) => step.order === +order)?.name;
            const currentStepError = errorObject[currentStepName];

            // handle step-specific field errors
            if (currentStepError && typeof currentStepError === 'object') {
                updatedStepsErrorData[order] = { isError: true };
                return [order, getMappedFormErrors(currentStepError, stepFormData, 'name')];
            }

            // handle step-specific non-field errors
            if (currentStepError && typeof currentStepError === 'string') {
                updatedStepsErrorData[order] = {
                    isError: true,
                    errorMessage: currentStepError,
                };
            }

            return [order, stepFormData];
        })
        .reduce((acc, [order, stepsFormData]) => ({ ...acc, [order]: stepsFormData }), {});

    return { updatedFormData, updatedStepsErrorData };
};

export const handleStepsFormErrors = ({
    e,
    setErrorBannerMessage,
    setStepsErrorData,
    setErrorAlert,
    formData,
    setFormData,
    settings,
    expandAllErrorsSteps,
}) => {
    const error = e.response?.data?.error;

    if (typeof error === 'string') {
        // display general error banner
        setErrorBannerMessage(error);
    } else if (error?.settings) {
        // handle step-specific errors
        const { updatedFormData, updatedStepsErrorData } = getMappedStepsFormErrors(
            error?.settings,
            formData,
            settings
        );
        setFormData(updatedFormData);
        if (expandAllErrorsSteps) {
            expandAllErrorsSteps(updatedStepsErrorData);
        }
        setStepsErrorData(updatedStepsErrorData);
        setErrorAlert({
            message:
                'It looks like an error occurred at some steps. Please navigate to those steps to find out the error details.',
        });
    } else {
        setErrorAlert({ message: defaultErrorMessage });
    }
};
