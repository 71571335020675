import React, { useMemo, useState } from 'react';

import { API } from 'constants';
import client from '../../services/library-api';

import { SORT_TYPES } from '../../constants/sort';
import { PAGINATION_LIMIT } from '../../pages/PlaybooksIndexPage/PlaybooksIndexPage';

import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../hooks/useFetchOptionsForPaginatedSelectWithSWR';
import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';

import Modal from '../../design-system/Modal/Modal';
import PlaybookContainer from './PlaybookContainer/PlaybookContainer';
import SearchBar from '../../design-system/SearchBar/SearchBar';
import Loading from '../Loading';
import ModalHeader from '../../design-system/ModalHeader/ModalHeader';
import MobilePopup from '../../design-system/MobilePopup/MobilePopup';

const StartNewJobModal = ({ handleClose }) => {
    const formatResponseToOptions = (results) =>
        results?.map(({ id, name, icon_color, processes }) => ({
            id,
            name,
            icon_color,
            processes,
        }));

    const {
        options: playbooks,
        optionsLoading,
        setPage,
        canLoadMoreOptions,
        isFirstRequestCompleted,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.library.playbook,
        searchParams: { ordering: SORT_TYPES.alphabetical },
        limit: PAGINATION_LIMIT,
        formatResponseToOptions,
    });

    const { guardRef, isNextPageLoading } = useIntersectionObserver({
        handleFetch: () => setPage((page) => page + 1),
        optionsLoading,
        options: playbooks,
        canLoadMore: canLoadMoreOptions,
    });

    const [searchValue, setSearchValue] = useState('');
    const loweredSearchValue = searchValue.toLowerCase();

    // playbooks with processes that match search
    const filteredProcesses = useMemo(() => {
        if (!loweredSearchValue) {
            return playbooks;
        }

        return playbooks?.map((playbook) => ({
            ...playbook,
            processes: playbook.processes?.filter((process) =>
                process.name?.toLowerCase()?.includes(loweredSearchValue)
            ),
        }));
    }, [playbooks, loweredSearchValue]);

    // hide playbooks with no processes
    const playbooksWithProcesses = filteredProcesses?.filter(
        (playbook) => !!playbook.processes?.length
    );

    const noMatchingResultsFound =
        !playbooksWithProcesses?.length && searchValue && !isNextPageLoading && !optionsLoading;
    const noAgentsFound =
        !playbooksWithProcesses?.length && !searchValue && !isNextPageLoading && !optionsLoading;

    const { isMobile } = useResponsiveBreakpoints();

    const content = (
        <>
            <SearchBar
                size="md"
                value={searchValue}
                state={!playbooks ? 'disabled' : 'default'}
                placeholder="Search all agents ..."
                onChange={(e) => setSearchValue(e.target.value)}
                tipText="Use command + J for quick start"
                withClearIcon
                showClearIconIfValueIsNotEmpty
                onClear={() => setSearchValue('')}
                onSubmit={() => {}}
                autoFocus
            />

            {isFirstRequestCompleted && (
                <div className="overflow-y-scroll">
                    <div className="flex flex-col gap-4">
                        {!!playbooksWithProcesses?.length &&
                            playbooksWithProcesses.map((playbook) => (
                                <PlaybookContainer
                                    key={playbook.id}
                                    playbook={playbook}
                                    isMobile={isMobile}
                                    handleModalClose={handleClose}
                                />
                            ))}
                    </div>

                    <div ref={guardRef}></div>

                    {noMatchingResultsFound && (
                        <p className="font-body-bold text-body-bold-s text-[#25324B] px-3 py-2 text-center">
                            No matching results found
                        </p>
                    )}

                    {noAgentsFound && (
                        <p className="font-body-bold text-body-bold-s text-[#25324B] px-3 py-2 text-center">
                            No agents found
                        </p>
                    )}

                    {isNextPageLoading && (
                        <div className="h-[38px] flex justify-center items-center">
                            <Loading withText={false} />
                        </div>
                    )}
                </div>
            )}

            {!isFirstRequestCompleted && optionsLoading && (
                <div className="flex items-center justify-center h-[60px]">
                    <Loading withText={false} />
                </div>
            )}
        </>
    );

    if (isMobile) {
        return (
            <MobilePopup onClose={handleClose} closeButtonText="Cancel" minHeight="82%">
                <div className="flex flex-col gap-4 px-[1px]">
                    <p className="font-heading-bold text-heading-bold-m text-black">
                        Start New Job
                    </p>

                    {content}
                </div>
            </MobilePopup>
        );
    }

    return (
        <Modal onClose={handleClose} size="midSize">
            <div className="max-h-[452px] flex flex-col gap-4">
                <ModalHeader title="Start New Job" onClose={handleClose} size="xs" />

                {content}
            </div>
        </Modal>
    );
};

export default StartNewJobModal;
