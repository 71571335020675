import React, { useState } from 'react';

import { defaultErrorMessage } from '../../../constants/errorMessages';

import { Button } from 'design-system';
import { FileCopyLineIcon } from '../../../design-system/Icons';
import Modal from 'design-system/Modal/Modal';
import Input from 'design-system/Input/Input';
import ErrorWarningLineIcon from 'design-system/Icons/ErrorWarningLineIcon';
import Alert from 'design-system/Alert/Alert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';

const EditApiKeyModal = ({
    onClose,
    apiKeyCreate,
    apiKeyEdit,
    apiKeyDelete,
    handleSave,
    data = null,
    mode = 'create',
    orgId = null,
}) => {
    const [errorAlert, setErrorAlert] = useState({ show: false, message: '', statusCode: null });
    const [isCreated, setIsCreated] = useState(false);
    const [copySuccessAlert, setCopySuccessAlert] = useState(false);

    const [apiKeyData, setApiKeyData] = useState({
        name: mode === 'edit' && data ? data.name : '',
        key: mode === 'edit' && data ? data.key : '',
    });
    const [errorFields, setErrorFields] = useState({
        name: false,
    });

    const handleInputChange = (e) => {
        setApiKeyData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCopyApiKey = () => {
        navigator.clipboard
            .writeText(apiKeyData.key)
            .then(() => {
                setCopySuccessAlert(true);
            })
            .catch(() => {
                setErrorAlert({ show: true });
            });
    };

    const handleCreateSubmit = async () => {
        if (!apiKeyData.name) {
            setErrorFields({
                name: true,
            });
            return;
        }
        const { success, response } = await apiKeyCreate.request({
            data: { name: apiKeyData.name, organization: orgId },
        });

        if (success) {
            setApiKeyData({
                name: apiKeyData.name,
                key: response.id,
                organization: response.organization,
            });
            setIsCreated(true);
            handleSave();
        }
    };

    const handleEditSubmit = async () => {
        if (!apiKeyData.name) {
            setErrorFields({
                name: true,
            });
            return;
        }

        const { success } = await apiKeyEdit.request({
            data: { name: apiKeyData.name },
            id: data.id, // Assuming the data has an id property
        });

        if (success) {
            onClose();
            handleSave();
        }
    };

    const handleDelete = async () => {
        const { success } = await apiKeyDelete.request({
            id: data.id,
        });

        if (success) {
            onClose();
            handleSave();
        }
    };

    return (
        <Modal size="small" onClose={onClose}>
            <div className="flex flex-col gap-[16px]">
                <div className="flex justify-between items-center gap-2">
                    <h2 className="font-heading-bold text-heading-bold-m text-black">
                        {isCreated
                            ? 'Great! Here it is.'
                            : mode === 'create'
                            ? 'Give your API Key a Name'
                            : 'Edit this API Key'}
                    </h2>
                </div>
                <Input
                    label={'Name'}
                    value={apiKeyData.name}
                    placeholder="Name"
                    name="name"
                    size="xs"
                    onChange={handleInputChange}
                    isRequired
                    state={errorFields.name ? 'error' : 'default'}
                    errorMessage="Please fill in this field."
                />
                {mode === 'edit' || isCreated ? (
                    <>
                        <Input
                            label={'API Key'}
                            value={apiKeyData.key}
                            name="key"
                            disabled={true}
                            size="xs"
                            tipText="Please copy this API key and store it in a safe place."
                        />
                    </>
                ) : null}
                <div className="flex justify-between mt-2">
                    {!isCreated && (
                        <Button type="neutral" text="Cancel" size="sm" onClick={onClose} />
                    )}
                    {isCreated && (
                        <div className="flex justify-center w-full">
                            <Button
                                type="primary"
                                text={'Copy to Clipboard'}
                                leadingIcon={FileCopyLineIcon}
                                size="sm"
                                onClick={handleCopyApiKey}
                            />
                        </div>
                    )}
                    {mode === 'create' && !isCreated ? (
                        <Button
                            type="primary"
                            text={'Create'}
                            size="sm"
                            onClick={handleCreateSubmit}
                        />
                    ) : (
                        !isCreated && (
                            <>
                                <Button
                                    type="primary"
                                    text={'Save'}
                                    size="sm"
                                    onClick={handleEditSubmit}
                                />
                            </>
                        )
                    )}
                </div>
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() =>
                        setErrorAlert({ show: false, message: '', statusCode: null })
                    }
                />
            )}
            {copySuccessAlert && (
                <Alert
                    status="positive"
                    message={`API Key was copied to clipboard!`}
                    icon={CheckLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setCopySuccessAlert(false)}
                />
            )}
        </Modal>
    );
};

export default EditApiKeyModal;
