import React from 'react';
import classNames from 'classnames';

import { ListOptionGroup, SvgIcon } from '../../../design-system';
import { CheckboxCircleLineIcon } from '../../../design-system/Icons';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';

const AiModelsDropdown = ({ onClose, options, selectedOption, handleOptionSelect, isMobile }) => {
    const handleOptionClick = (option) => {
        handleOptionSelect(option);
        onClose();
    };

    let listOptions;

    if (!!options?.length) {
        listOptions = options.map((option) => {
            const { model, name, is_divibot } = option;

            const isSelected =
                model === selectedOption.model && is_divibot === selectedOption.is_divibot;
            const optionClassName = classNames(
                'w-full rounded-2 hover:bg-neutral-50 p-2 flex items-center justify-between gap-2 cursor-pointer transition-all',
                {
                    'bg-white': !isSelected,
                    'bg-neutral-50': isSelected,
                }
            );
            return (
                <div
                    key={name + model}
                    className={optionClassName}
                    onClick={() => handleOptionClick(option)}
                >
                    <p className="flex-1 truncate text-start font-body text-body-regular-m text-neutral-500">
                        {name}
                    </p>
                    {isSelected && (
                        <SvgIcon color="#754DCF" icon={CheckboxCircleLineIcon} size="medium" />
                    )}
                </div>
            );
        });
    }

    if (!options?.length) {
        listOptions = (
            <p className="font-body-bold text-body-bold-s text-neutral-400 px-3 text-center">
                Please add your LLM API key
            </p>
        );
    }

    if (isMobile) {
        return (
            <MobilePopup onClose={onClose} closeButtonText="Cancel" title="Choose a Model">
                <div className="flex flex-col gap-1">{listOptions}</div>
            </MobilePopup>
        );
    }

    return (
        <div className="absolute right-0 top-[110%] z-20">
            <ListOptionGroup
                listOptions={listOptions}
                fixedWidth={260}
                maxHeight={380}
                handleClose={onClose}
            />
        </div>
    );
};

export default AiModelsDropdown;
