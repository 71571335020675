import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import * as API from '../../../constants/api';
import client from '../../../services/library-api';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import { Button } from '../../../design-system';
import { AddLineIcon } from '../../../design-system/Icons';
import ScenariosSelector from '../ScenariosSelector/ScenariosSelector';

const NewProjectBlock = () => {
    const { playbookId } = useParams();

    const [isSelectorOpened, setIsSelectorOpened] = useState(false);

    const formatResponseToOptions = (results) => results?.map(({ id, name }) => ({ id, name }));

    const scenariosOptionHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.library.scenario,
        searchParams: { playbook: playbookId, is_live: true },
        formatResponseToOptions,
    });
    const { isFirstRequestCompleted, options } = scenariosOptionHookResponse;
    const noResults = isFirstRequestCompleted && !options?.length;

    return (
        <div className="relative w-fit">
            <Button
                type="primary"
                size="md"
                text="New Project"
                state={noResults ? 'disabled' : 'default'}
                leadingIcon={AddLineIcon}
                onClick={() => setIsSelectorOpened(true)}
            />
            {isSelectorOpened && !noResults && (
                <ScenariosSelector
                    playbookId={playbookId}
                    scenariosOptionHookResponse={scenariosOptionHookResponse}
                    onClose={() => setIsSelectorOpened(false)}
                />
            )}
        </div>
    );
};

export default NewProjectBlock;
