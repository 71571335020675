import { mutateKeyData } from './swrMutateUtils';

export const mutateMilestonesDataAfterReassignInboxMessage = async ({
    key,
    milestoneId,
    contentId,
    messageId,
    memberId,
    email,
}) => {
    await mutateKeyData({
        key,
        revalidate: true,
        cb: (currentData) => {
            if (currentData.status === 'started') {
                return currentData;
            }

            const updatedMilestones = currentData.milestones.map((milestone) => {
                if (milestone.id !== milestoneId) return milestone;
                const updatedContent = milestone.content.map((content) => {
                    if (content.id !== contentId) return content;
                    let updatedData;

                    if (Array.isArray(content.data)) {
                        updatedData = content.data.map((item) => {
                            if (item.message_id !== messageId) return item;
                            return {
                                ...item,
                                assigned_to_email: email,
                                assigned_to_id: memberId,
                            };
                        });
                    } else {
                        updatedData = {
                            ...content.data,
                            assigned_to_email: email,
                            assigned_to_id: memberId,
                        };
                    }

                    return { ...content, data: updatedData };
                });
                return { ...milestone, content: updatedContent };
            });
            return { ...currentData, milestones: updatedMilestones };
        },
    });
};
