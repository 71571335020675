import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/library-api';

import { useFormState } from '../../../hooks/useFormState';
import { collectionColorOptions } from '../../../constants/collections';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../constants/errorMessages';

import Modal from '../../../design-system/Modal/Modal';
import Alert from '../../../design-system/Alert/Alert';
import ColorPicker from '../../../design-system/ColorPicker/ColorPicker';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import FileInput from '../../../design-system/FileInput/FileInput';
import FormFieldWrapper from '../../../design-system/FormFieldWrapper/FormFieldWrapper';
import { Button, Input } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const PlaybookDetailsModal = ({
    mode = 'create',
    playbookData = null,
    setUpdatedPlaybookData = () => {},
    onClose,
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { formData, setFormData, fieldErrorMessages, setFieldErrorMessages, handleInputChange } =
        useFormState({
            name: '',
            description: '',
            icon_color: collectionColorOptions[0],
            cover_image: null,
        });

    const [isFormDataLoaded, setIsFormDataLoaded] = useState(mode === 'create');

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    useEffect(() => {
        if (mode === 'edit' && playbookData) {
            const { name, description, icon_color, cover_image } = playbookData;
            setFormData({ name, description, icon_color, cover_image });
            setIsFormDataLoaded(true);
        }
    }, [mode, playbookData]);

    const getRequestBody = () => {
        const isCoverImageChanged =
            formData.cover_image && typeof formData.cover_image !== 'string';

        if (!isCoverImageChanged) {
            const { cover_image, ...restFormData } = formData;
            return restFormData;
        }

        if (isCoverImageChanged) {
            const requestFormData = new FormData();

            requestFormData.append('name', formData.name);
            requestFormData.append('description', formData.description);
            requestFormData.append('icon_color', formData.icon_color);
            requestFormData.append('cover_image', formData.cover_image);

            return requestFormData;
        }
    };

    const createNewPlaybook = async () => {
        const requestBody = getRequestBody();
        const { data } = await client.post(API.ROUTES.library.playbook, requestBody);

        navigate(`/playbook/${data.id}?mode=edit`, { state: { from: location } });
    };

    const editPlaybook = async () => {
        const playbookIdToEdit = playbookData.id;
        const requestBody = getRequestBody();
        const { data } = await client.patch(
            `${API.ROUTES.library.playbook}${playbookIdToEdit}/`,
            requestBody
        );
        const { name, description, icon_color, cover_image } = data;

        setUpdatedPlaybookData((prevData) => ({
            ...prevData,
            name,
            description,
            icon_color,
            cover_image,
        }));
    };

    const performSavingAction = {
        create: createNewPlaybook,
        edit: editPlaybook,
    };

    const handleSaveAction = async () => {
        const areEmptyFields = !formData.name || !formData.description;
        if (areEmptyFields) {
            setFieldErrorMessages({
                name: !formData.name ? emptyFieldErrorMessage : null,
                description: !formData.description ? emptyFieldErrorMessage : null,
            });
            return;
        }

        try {
            setIsLoading(true);

            await performSavingAction[mode]();

            setIsLoading(false);
            onClose();
        } catch (e) {
            if (e.response?.data?.name) {
                setFieldErrorMessages((prevMessages) => ({
                    ...prevMessages,
                    name: e.response.data.name?.find(Boolean),
                }));
            } else {
                setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
            }
            setIsLoading(false);
        }
    };

    const handleColorSelect = (color) => {
        setFormData((prevData) => ({ ...prevData, icon_color: color }));
    };

    const modalTitle = {
        create: 'Create new playbook',
        edit: 'Edit playbook',
    };

    const confirmButtonText = {
        create: 'Create Playbook',
        edit: 'Save changes',
    };

    if (!isFormDataLoaded) {
        return <></>;
    }

    return (
        <Modal onClose={onClose} size="medium" resetPadding>
            <div className="py-3 px-5 sm:px-8 border-b-1 border-neutral-200">
                <ModalHeader title={modalTitle[mode]} onClose={onClose} />
            </div>
            <div className="py-5 px-5 sm:px-8 flex flex-col gap-4">
                <div className="flex flex-col gap-4">
                    <Input
                        size="sm"
                        name="name"
                        value={formData.name}
                        label="Give your playbook a name"
                        isRequired
                        state={fieldErrorMessages.name ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.name}
                        placeholder="i.e. High Conversion Sales Calls Playbook"
                        onChange={(e) => handleInputChange('name', e.target.value)}
                    />
                    <Input
                        size="sm"
                        name="description"
                        value={formData.description}
                        label="Explain what your playbook covers"
                        isRequired
                        state={fieldErrorMessages.description ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.description}
                        placeholder="How to ..."
                        onChange={(e) => handleInputChange('description', e.target.value)}
                    />
                    <ColorPicker
                        options={collectionColorOptions}
                        selected={formData.icon_color}
                        label="Select a header color"
                        handleSelect={handleColorSelect}
                    />
                    <FormFieldWrapper label="Playbook Cover Image">
                        <FileInput
                            handleFile={(file) => handleInputChange('cover_image', file)}
                            file={formData.cover_image}
                            formatsArray={['.jpg', '.jpeg', '.png']}
                            maxSizeInBytes={5242880}
                            placeholder="Drag & drop a file to upload or click to browse"
                            shouldShowPreview
                        />
                    </FormFieldWrapper>
                </div>

                <Button
                    size="md"
                    type="primary"
                    text={confirmButtonText[mode]}
                    state={isLoading ? 'loading' : 'default'}
                    onClick={handleSaveAction}
                />
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default PlaybookDetailsModal;
