import React from 'react';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useDefaultSearchParams } from '../../hooks/useDefaultSearchParams';

import BasesPageHeader from './BasesPageHeader/BasesPageHeader';
import BasesIndex from './BasesIndex/BasesIndex';

const BasesPage = () => {
    useDefaultSearchParams();

    useDocumentTitle('Bases');

    return (
        <div className="w-full sm:w-[calc(100%-68px)] p-4 sm:p-0 flex flex-col gap-5">
            <BasesPageHeader />

            <BasesIndex />
        </div>
    );
};

export default BasesPage;
