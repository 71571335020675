import React from 'react';
import CreditsCountBadge from '../../../../components/CreditsCountBadge/CreditsCountBadge';
import RichTextArea from '../../../../design-system/RichTextArea/RichTextArea';

const AboutTabSection = ({ processDetails }) => {
    const { description, default_version } = processDetails;
    const { overview, cost_per_run } = default_version;

    return (
        <div className="w-full p-5 sm:p-6 bg-white rounded-2xl">
            <div className="flex items-center justify-between gap-x-5 gap-y-1.5 flex-wrap mb-5">
                <p className="font-heading-bold text-heading-bold-s text-black">Agent Overview</p>
                <CreditsCountBadge cost={cost_per_run} label="job" />
            </div>

            <RichTextArea
                value={overview || description}
                previewOnly
                autoExpand
                isBorderHidden
                minHeight={32}
            />
        </div>
    );
};

export default AboutTabSection;
