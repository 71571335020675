import React from 'react';
import { DateTime } from 'luxon';

import { SvgIcon } from '../../../../design-system';
import ArchiveLineIcon from '../../../../design-system/Icons/ArchiveLineIcon';

const EntryCard = ({ entry }) => {
    const {
        properties: { label },
        creationTimeUnix,
    } = entry;

    const formattedTime = DateTime.fromISO(creationTimeUnix).toFormat('MM/dd/yyyy, HH:mm:ss');

    return (
        <div className="w-full px-4 py-3 rounded-2 flex flex-col min-[530px]:flex-row min-[530px]:items-center gap-x-3 gap-y-2 min-[530px]:justify-between bg-white">
            <div className="flex items-start gap-4">
                <div className="w-[32px] h-[32px] min-w-[32px] rounded-2 bg-purple-100 flex items-center justify-center">
                    <SvgIcon color="#754DCF" icon={ArchiveLineIcon} size="medium" />
                </div>
                <p className="font-body-medium text-body-medium-s text-black my-auto">{label}</p>
            </div>

            <p className="font-body text-body-regular-xs text-neutral-300 whitespace-nowrap border-t-1 border-neutral-200 pt-2 min-[530px]:border-none min-[530px]:p-0">
                Created At{' '}
                <span className="font-body-bold text-body-bold-xs text-neutral-500">
                    {formattedTime}
                </span>
            </p>
        </div>
    );
};

export default EntryCard;
