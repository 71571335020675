import React, { useEffect, useState } from 'react';

import client from '../../../../services/report-api';
import { API } from 'constants';
import {
    checkIsFormDataValid,
    formatInputsToFormData,
    getStartNewJobFormDataInitialState,
    handleClientStartNewJobRequestErrors,
} from '../../../../helpers/startNewJobFormUtils';
import { mutateJobsDataAfterRestartingJob } from '../../../../helpers/jobsUtils';

import NewJobForm from '../../../../components/NewJobForm/NewJobForm';
import Loading from '../../../../components/Loading';
import Alert from '../../../../design-system/Alert/Alert';
import PlayCircleFillIcon from '../../../../design-system/Icons/PlayCircleFillIcon';
import Panel from '../../../../components/Panel';
import { Button, ButtonIcon } from '../../../../design-system';
import { CloseLineIcon, ErrorWarningLineIcon } from '../../../../design-system/Icons';

const RestartJobSidePanel = ({ jobId, mutate, requestKeysToMutate = [], onClose }) => {
    const [formData, setFormData] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const [shouldClosePanelOnClickOutside, setShouldClosePanelOnClickOutside] = useState(true);

    useEffect(() => {
        const fetchRestartFormData = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.report.job}${jobId}/restart/`);
                setFormData(getStartNewJobFormDataInitialState(data.inputs));
            } catch (e) {
                onClose();
            }
        };

        fetchRestartFormData();
    }, []);

    const restartJob = async () => {
        const isFormDataValid = checkIsFormDataValid(formData, setFormData);
        if (!isFormDataValid) {
            return;
        }

        try {
            setIsLoading(true);

            const inputs = formatInputsToFormData(formData);
            const { data } = await client.post(`${API.ROUTES.report.job}${jobId}/restart/`, {
                inputs,
            });
            // reload the Job Details
            if (mutate) {
                await mutate();
            }
            if (!!requestKeysToMutate.length) {
                const updatedStatus = data.status;
                mutateJobsDataAfterRestartingJob(requestKeysToMutate, jobId, updatedStatus);
            }

            setIsLoading(false);
            onClose();
        } catch (error) {
            setIsLoading(false);
            handleClientStartNewJobRequestErrors({ error, setErrorAlert, formData, setFormData });
        }
    };

    return (
        <Panel
            onClose={onClose}
            resetPadding
            showControls={false}
            closeByClickOutside={shouldClosePanelOnClickOutside}
        >
            <div className="flex flex-col gap-5 sm:gap-7 px-5 py-5 sm:px-8 lg:px-[40px] lg:py-8">
                <div className="flex items-start justify-between gap-3">
                    <div>
                        <p className="font-heading-bold text-heading-bold-m text-black mb-2">
                            Restart Job
                        </p>
                        <p className="font-body text-body-regular-s text-neutral-400">
                            Edit the current job inputs and click Restart Job
                        </p>
                    </div>
                    <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                </div>

                {!!formData && (
                    <>
                        <NewJobForm
                            formData={formData}
                            setFormData={setFormData}
                            containerGap={28}
                            setCloseParentOnClickOutside={setShouldClosePanelOnClickOutside}
                        />
                        <div className="py-2 sm:py-4">
                            <div className="pt-4 border-t-1 border-neutral-200 flex justify-end">
                                <Button
                                    type="secondary"
                                    size="sm"
                                    text="Restart Job"
                                    state={isLoading ? 'loading' : 'default'}
                                    trailingIcon={PlayCircleFillIcon}
                                    onClick={restartJob}
                                />
                            </div>
                        </div>
                    </>
                )}

                {!formData && (
                    <div className="flex justify-center py-2">
                        <Loading withText={false} />
                    </div>
                )}
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Panel>
    );
};

export default RestartJobSidePanel;
