import React from 'react';

import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import PaginatedSelect from '../../../design-system/PaginatedSelect/PaginatedSelect';
import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';
import Modal from '../../../design-system/Modal/Modal';
import { Button, Input } from '../../../design-system';
import { submitButtonText } from '../QuickAddToDocModal';

const QuickAddToDocDesktopView = ({
    docsOptionsHookResponse,
    formData,
    mode,
    tipText,
    fieldErrorMessages,
    handleInputChange,
    handleSubmit,
    isLoading,
    onClose,
}) => {
    const { options, totalOptions, canLoadMoreOptions, setPage, optionsLoading } =
        docsOptionsHookResponse;

    return (
        <Modal onClose={onClose} size="mediumPlus" resetPadding>
            <ModalHeader
                onClose={onClose}
                title="Quick Add to Doc"
                outlined
                customPaddings="px-5 py-3"
            />

            <div className="px-5 py-5 flex flex-col gap-6">
                {mode === 'add' && (
                    <PaginatedSelect
                        size="md"
                        name="doc"
                        options={options}
                        optionsLoading={optionsLoading}
                        value={formData.doc?.id}
                        label="Select Document"
                        isRequired
                        info={tipText}
                        placeholder="Select a Document"
                        onChange={(_, doc) => handleInputChange('doc', doc)}
                        state={fieldErrorMessages.doc ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.doc}
                        fetchOptions={() => setPage((page) => page + 1)}
                        canLoadMore={canLoadMoreOptions}
                        includeClientSideFiltering
                        totalOptionsCount={totalOptions}
                        dropdownHeight={260}
                    />
                )}

                {mode === 'create' && (
                    <Input
                        size="md"
                        name="name"
                        value={formData.label}
                        onChange={(e) => handleInputChange('label', e.target.value)}
                        label="Create New Document"
                        isRequired
                        state={fieldErrorMessages.label ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.label}
                        placeholder="Give your new document a name"
                        tipText={tipText}
                    />
                )}

                <div className="h-[200px] px-[1px]">
                    <RichTextArea
                        value={formData.value}
                        setValue={(value) => handleInputChange('value', value)}
                        label="Text to Add"
                        state={fieldErrorMessages.value ? 'error' : 'default'}
                        errorMessage={fieldErrorMessages.value}
                    />
                </div>
            </div>

            <div className="px-5 sm:px-6 md:px-8 py-3 flex justify-end">
                <Button
                    type="primary"
                    size="sm"
                    text={submitButtonText[mode]}
                    state={isLoading ? 'loading' : 'default'}
                    onClick={handleSubmit}
                />
            </div>
        </Modal>
    );
};

export default QuickAddToDocDesktopView;
