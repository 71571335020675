import { Link } from 'react-router-dom';

import { API } from 'constants';
import client from '../services/knowledge-api';
import { defaultErrorMessage } from '../constants/errorMessages';
import { EMPTY_DOCUMENT_DATA } from '../constants/docs';
import { mutateRequestKeysData, revalidateAllCashedRequest } from './swrMutateUtils';

export const mutateDataAfterRenamingDoc = ({ requestKeysToMutate, docId, updatedLabel }) => {
    mutateRequestKeysData({
        requestKeysToMutate,
        revalidate: false,
        cb: (currentData) => {
            const updatedResults = currentData.results.map((doc) =>
                doc.id === docId ? { ...doc, label: updatedLabel } : doc
            );

            return { ...currentData, results: updatedResults };
        },
    });
};

export const mutateDataAfterArchivingDoc = ({ requestKeysToMutate, docId }) => {
    mutateRequestKeysData({
        requestKeysToMutate,
        revalidate: false,
        cb: (currentData, key) => {
            const isKeyWithSearch = key.includes('?search=');
            const isArchivedDocMissedInResultsWithSearchQuery =
                isKeyWithSearch && !currentData.results.find((doc) => doc.id === docId);
            if (isArchivedDocMissedInResultsWithSearchQuery) {
                return currentData;
            }

            const updatedResults = currentData.results.filter((doc) => doc.id !== docId);

            return { ...currentData, results: updatedResults, count: currentData.count - 1 };
        },
    });
};

export const handleDuplicateDoc = async ({
    id,
    setSuccessAlert,
    setErrorAlert,
    location,
    requestKeysToMutate = [],
    setIsLoading = () => {},
    closePopup = () => {},
}) => {
    try {
        setIsLoading(true);

        const { data } = await client.post(`${API.ROUTES.knowledge.document}${id}/duplicate/`);
        revalidateAllCashedRequest({ requestKeysToMutate });
        setSuccessAlert({
            message: `Created ”${data.label}”`,
            description: (
                <Link
                    to={`/doc/${data.id}`}
                    state={{ from: location }}
                    className="font-[inherit] text-[inherit] underline"
                >
                    Visit
                </Link>
            ),
        });

        closePopup();
    } catch (e) {
        setIsLoading(false);
        setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
    }
};

export const handleCreateNewDoc = async ({ setIsLoading, setErrorAlert, navigate, location }) => {
    try {
        setIsLoading(true);
        const { data: doc } = await client.post(API.ROUTES.knowledge.document, EMPTY_DOCUMENT_DATA);

        navigate(`/doc/${doc.id}`, { state: { from: location } });
    } catch (e) {
        setIsLoading(false);
        setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
    }
};

export const mutateDocsDataAfterDocEditing = ({ requestKeysToMutate, docId, updatedValue }) => {
    mutateRequestKeysData({
        requestKeysToMutate,
        revalidate: false,
        cb: (currentData) => {
            const updatedResults = currentData.results.map((doc) =>
                doc.id === docId
                    ? { ...doc, default_version: { ...doc.default_version, value: updatedValue } }
                    : doc
            );
            return { ...currentData, results: updatedResults };
        },
    });
};
