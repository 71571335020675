import React, { useEffect, useMemo, useState } from 'react';

import useUser from '../../../hooks/useUser';
import { getAssistantAllowedModelOptions } from '../../../helpers/getModelOptions';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import AiModelsDropdown from '../AIModelsDropdown/AIModelsDropdown';
import SelectButton from '../../../design-system/SelectButton/SelectButton';

import Alert from '../../../design-system/Alert/Alert';

export const chatGpt4oModel = 'gpt-4o';
const diviBotOption = { name: 'DiviBot Assistant', model: chatGpt4oModel, is_divibot: true };

const placeholder = 'Select a model';

const AiModelSelector = ({ selectedAiModel, setSelectedAiModel, isDiviBot, setIsDiviBot }) => {
    const { user } = useUser();

    const allowedModelOptions = useMemo(() => {
        if (!user) {
            return null;
        }

        const sortedAllowedModels = getAssistantAllowedModelOptions(user).sort((a, b) =>
            a.name.localeCompare(b.name)
        );
        const isChatGpt4oAvailable = !!sortedAllowedModels.find(
            ({ model }) => model === chatGpt4oModel
        );

        if (isChatGpt4oAvailable) {
            return [diviBotOption, ...sortedAllowedModels];
        }
        return sortedAllowedModels;
    }, [user]);

    const selectedModelOption = isDiviBot
        ? allowedModelOptions?.find(({ is_divibot }) => is_divibot)
        : allowedModelOptions?.find(
              ({ model, is_divibot }) => model === selectedAiModel && !is_divibot
          );

    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const { isMobile } = useResponsiveBreakpoints();

    useEffect(() => {
        if (!allowedModelOptions || selectedAiModel) {
            return;
        }

        const isDiviBotInAllowedOptions = !!allowedModelOptions.find(
            ({ is_divibot }) => is_divibot
        );

        if (isDiviBotInAllowedOptions) {
            setIsDiviBot(true);
            setSelectedAiModel(diviBotOption.model);
        }
        // // if there are no models, show an error alert
        // if (!allowedModelOptions.length) {
        //     setErrorAlert({ message: 'Please add an LLM API key to use this assistant' });
        // }

        if (!isDiviBotInAllowedOptions) {
            setErrorAlert({ message: 'Please add an OpenAI API key to use this assistant' });
        }
    }, [allowedModelOptions, selectedAiModel]);

    const toggleDropdownOpened = () => {
        setIsDropdownOpened((prevState) => !prevState);
    };

    if (!allowedModelOptions) {
        return <div className="w-50 min-w-50 max-w-50"></div>;
    }

    const handleSelectOption = ({ model, is_divibot }) => {
        setIsDiviBot(!!is_divibot);
        setSelectedAiModel(model);
    };

    return (
        <div className="w-50 min-w-50 max-w-[200px] relative">
            <SelectButton
                size="xs"
                selectedItem={selectedModelOption}
                isDropdownOpened={isDropdownOpened}
                toggleDropdownOpened={toggleDropdownOpened}
                placeholder={placeholder}
            />

            {isDropdownOpened && (
                <AiModelsDropdown
                    options={allowedModelOptions || []}
                    selectedOption={selectedModelOption}
                    handleOptionSelect={handleSelectOption}
                    isMobile={isMobile}
                    onClose={(e) => {
                        e?.stopPropagation();
                        setIsDropdownOpened(false);
                    }}
                />
            )}

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default AiModelSelector;
