import React from 'react';
import useUser from '../../../hooks/useUser';

const ChatInvitingView = ({ isDiviBot }) => {
    const { user } = useUser();

    const greetings = user?.first_name ? `Hey ${user.first_name}!` : 'Hey there!';
    const chatInviteText = isDiviBot
        ? 'Start chatting with DiviBot - your agency assistant'
        : 'Start chatting with AI';

    return (
        <div className="px-5 sm:px-6 h-4/5 flex items-center justify-center">
            <div>
                <p className="font-heading-bold text-heading-bold-m text-neutral-500 text-center">
                    {greetings}
                </p>
                <p className="font-heading-regular text-heading-regular-m text-neutral-500 text-center">
                    {chatInviteText}
                </p>
            </div>
        </div>
    );
};

export default ChatInvitingView;
