import AlignLeftIcon from '../design-system/Icons/AlignLeftIcon';
import ImageAddLineIcon from '../design-system/Icons/ImageAddLineIcon';
import VideoAddLineIcon from '../design-system/Icons/VideoAddLineIcon';
import TextBlockIcon from '../design-system/Icons/TextBlockIcon';

export const PLAYBOOK_BUILDER_MODE = {
    edit: 'edit',
    view: 'view',
};

export const BLOCK_TYPE = {
    richtext: 'richtext',
    image: 'image',
    video: 'video',
    callout: 'callout',
    document: 'document',
    agent: 'agent',
    chat_prompt: 'chat_prompt',
};

export const BLOCK_TYPE_LABEL = {
    [BLOCK_TYPE.richtext]: 'Text',
    [BLOCK_TYPE.image]: 'Image',
    [BLOCK_TYPE.video]: 'Video',
    [BLOCK_TYPE.callout]: 'Callout',
    [BLOCK_TYPE.document]: 'Document',
    [BLOCK_TYPE.agent]: 'Agent',
    [BLOCK_TYPE.chat_prompt]: 'Prompt',
};

export const BLOCK_TYPE_ICON = {
    [BLOCK_TYPE.richtext]: AlignLeftIcon,
    [BLOCK_TYPE.image]: ImageAddLineIcon,
    [BLOCK_TYPE.video]: VideoAddLineIcon,
    [BLOCK_TYPE.callout]: TextBlockIcon,
    [BLOCK_TYPE.document]: TextBlockIcon,
    [BLOCK_TYPE.agent]: TextBlockIcon,
    [BLOCK_TYPE.chat_prompt]: TextBlockIcon,
};

export const DEFAULT_SECTION_NAME = 'Add a section title';

export const EMPTY_PLAYBOOK_SECTION = {
    id: null,
    order: 1,
    name: '',
    description: '',
    version_id: null,
    block_content: [],
};

export const EMPTY_CALLOUT_BLOCK_PROPERTIES = {
    properties: {
        title: '',
        description: '',
        callout_color: '#5E6470',
    },
};

export const EMPTY_RICHTEXT_BLOCK_PROPERTIES = {
    properties: {
        content: '',
    },
};

export const EMPTY_IMAGE_BLOCK_PROPERTIES = {
    properties: {
        image_url: '',
    },
};

export const EMPTY_VIDEO_BLOCK_PROPERTIES = {
    properties: {
        video_url: '',
    },
};

export const EMPTY_DOCUMENT_BLOCK_PROPERTIES = {
    properties: {
        document_id: '',
    },
};

export const EMPTY_AGENT_BLOCK_PROPERTIES = {
    properties: {
        agent_id: '',
    },
};

export const EMPTY_CHAT_PROMPT_BLOCK_PROPERTIES = {
    properties: {
        chat_prompt_id: '',
    },
};

export const EMPTY_BLOCK_PROPERTIES = {
    [BLOCK_TYPE.richtext]: EMPTY_RICHTEXT_BLOCK_PROPERTIES,
    [BLOCK_TYPE.image]: EMPTY_IMAGE_BLOCK_PROPERTIES,
    [BLOCK_TYPE.video]: EMPTY_VIDEO_BLOCK_PROPERTIES,
    [BLOCK_TYPE.callout]: EMPTY_CALLOUT_BLOCK_PROPERTIES,
    [BLOCK_TYPE.document]: EMPTY_DOCUMENT_BLOCK_PROPERTIES,
    [BLOCK_TYPE.agent]: EMPTY_AGENT_BLOCK_PROPERTIES,
    [BLOCK_TYPE.chat_prompt]: EMPTY_CHAT_PROMPT_BLOCK_PROPERTIES,
};
