import React from 'react';
import { useSearchParams } from 'react-router-dom';

import client from '../../../services/knowledge-api';
import * as API from '../../../constants/api';

import useCustomPagination from '../../../hooks/useCustomPagination';
import { useSwrPageRequestKeysManagingWithContextSaving } from '../../../hooks/useSwrPageRequestKeysManagingWithContextSaving';

import { parseSearchParams } from '../../../helpers/parseSearchSortPageSearchParams';

import Loading from '../../../components/Loading';
import BasesEmptyView from '../BasesEmptyView/BasesEmptyView';
import BasesList from '../BasesList/BasesList';

const BasesIndex = () => {
    const [searchParams] = useSearchParams();
    const parsedParams = parseSearchParams(searchParams);

    const page = parsedParams.page || 1;

    const basesHookResponse = useCustomPagination({
        client,
        route: API.ROUTES.knowledge.base,
        pageIndex: page - 1,
        searchParams: parsedParams.search ? { search: parsedParams.search } : {},
        autoFetchNextPage: true,
    });
    const { data, total, loading, key, nextPageKey } = basesHookResponse;

    const { requestKeysToMutate } = useSwrPageRequestKeysManagingWithContextSaving({
        pageAccessor: 'bases',
        key,
        nextPageKey,
    });

    if (!total && loading) {
        return (
            <div className="pt-5">
                <Loading />
            </div>
        );
    }

    const emptyStateShown = !total && !data?.length && !loading;

    if (emptyStateShown) {
        return <BasesEmptyView isSearchQuery={parsedParams.search} />;
    }

    return (
        <BasesList
            basesHookResponse={basesHookResponse}
            requestKeysToMutate={requestKeysToMutate}
            page={page}
        />
    );
};

export default BasesIndex;
