import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { handleDuplicateDoc } from '../../../helpers/docsUtils';
import { ListOption, ListOptionGroup } from '../../../design-system';

const DocCardMoreOptionsPopup = ({
    docData,
    setActionModal,
    topPosition = '40px',
    setErrorAlert,
    setSuccessAlert,
    requestKeysToMutate,
    onClose,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();

    const openModal = (action) => {
        setActionModal({ action, docData });
        onClose();
    };

    const handleClose = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        onClose();
    };

    const duplicateDoc = async () => {
        await handleDuplicateDoc({
            id: docData.id,
            location,
            setSuccessAlert,
            setErrorAlert,
            setIsLoading,
            closePopup: handleClose,
            requestKeysToMutate,
        });
    };

    return (
        <div
            className="absolute right-2 z-20 pb-5"
            style={{ top: topPosition }}
            onClick={(e) => e.preventDefault()}
        >
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editLineIcon"
                            text="Rename"
                            onClick={() => openModal('rename')}
                        />
                        <ListOption
                            leadingIconName="copyIcon"
                            text="Duplicate"
                            isLoading={isLoading}
                            onClick={duplicateDoc}
                        />
                        <ListOption
                            leadingIconName="inboxArchiveLineIcon"
                            text="Archive"
                            onClick={() => openModal('archive')}
                        />
                    </>
                }
                handleClose={handleClose}
            />
        </div>
    );
};
export default DocCardMoreOptionsPopup;
