import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import './jsonEditor.css';
import { useWindowSize } from '../../hooks/useWindowSize';
import { setSyncTimeout } from '../../helpers/setSyncTimeout';

JsonEditor.propTypes = {
    mode: PropTypes.oneOf(['view', 'code']).isRequired, // if user should be able to edit or create json use "code", otherwise use "view".
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    setValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    shouldRefreshValue: PropTypes.bool, // When the JsonEditor component mounts, the initial value is set, which can only be changed by the user through keyboard input (uncontrolled typing). However, if it's necessary to programmatically change the value in the JSON Editor, then set shouldRefreshValue = True, and the new value will be set.
    setShouldRefreshValue: PropTypes.func,
    autoExpand: PropTypes.bool,
};

function JsonEditor({
    value,
    setValue,
    mode,
    disabled = false,
    shouldRefreshValue = false,
    setShouldRefreshValue,
    autoExpand,
}) {
    const containerRef = useRef(null);
    const editor = useRef(null);

    const { width: screenWidth } = useWindowSize();

    const [jsonEditorHeight, setJsonEditorHeight] = useState(0);
    const modeContainerClassName = {
        view: 'editor-view-mode',
        code: 'editor-code-mode',
    };

    useEffect(() => {
        const container = containerRef.current;
        // for mode "code" (user can edit ets.) set "preview" mode when disabled state
        // for mode "code" leave "code" for not disabled state
        const editorMode = mode === 'code' && disabled ? 'preview' : mode;

        if (container) {
            const options = {
                mode: editorMode,
                onChangeText: (value) => {
                    setValue(value);

                    if (autoExpand && mode === 'code') {
                        setSyncTimeout(updateEditorHeight, 20);
                    }
                },
                search: false,
            };
            editor.current = new JSONEditor(container, options);
            editor.current.set(value);
        }

        return () => {
            if (editor.current) {
                editor.current.destroy();
            }
        };
    }, [containerRef]);

    useEffect(() => {
        if (shouldRefreshValue && editor.current) {
            // set new value
            editor.current.set(value);
            setShouldRefreshValue(false);
        }
    }, [shouldRefreshValue]);

    useEffect(() => {
        // toggle disabled state
        if (containerRef.current && mode === 'code') {
            // mode "preview" means disabled state
            // mode "code" means not disabled state
            editor.current.setMode(disabled ? 'preview' : 'code');
        }
    }, [containerRef.current, disabled]);

    function updateEditorHeight() {
        if (editor.current && mode === 'code') {
            const height = editor.current.aceEditor?.renderer?.layerConfig?.maxHeight;

            if (height) {
                setJsonEditorHeight(height);
            }
        }
    }

    useEffect(() => {
        if (autoExpand) {
            updateEditorHeight();
        }
    }, [editor.current, screenWidth, autoExpand]);

    const style = {
        minHeight: '100%',
        height:
            autoExpand && !disabled
                ? `${jsonEditorHeight + 100}px`
                : !autoExpand && disabled
                ? `100px` // the editor will have a height of at least 100 pixels, but it will expand to fill the entire height of its parent component due to the minHeight: '100%' property.
                : '100%',
    };
    if (!autoExpand) {
        style.flexGrow = 1;
    }

    return (
        <div className={`${modeContainerClassName[mode]}`} ref={containerRef} style={style}></div>
    );
}

export default JsonEditor;
