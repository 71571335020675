import React, { useState } from 'react';
import { API } from 'constants';
import client from '../../../../services/library-api';

import { TASK_TYPES } from '../../../../constants/library';
import { formatKeyToLabel } from '../../../../helpers/formatKeyToLabel';
import { formatToKey } from '../../../../helpers/formatLabelToKey';
import { updateSpecificUserInputs } from '../../../../helpers/determineInputs';
import { removeRedundantFieldsFromInput } from '../../../../helpers/taskPlaygroundUtils';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import Modal from '../../../../design-system/Modal/Modal';
import EscapeIcon from '../../../../design-system/Icons/EscapeIcon';
import Alert from '../../../../design-system/Alert/Alert';
import OptionsInput from '../../../../design-system/OptionsInput/OptionsInput';
import { Button, Input, Select, SvgIcon } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const inboxActionTypesOptions = [
    { id: 'body', name: 'Body' },
    { id: 'freeform', name: 'Freeform' },
    { id: 'single-select', name: 'Single Select' },
    { id: 'multi-select', name: 'Multi Select' },
];

const webhookTypesOptions = [
    { id: 'string', name: 'String' },
    { id: 'array', name: 'Array' },
    { id: 'object', name: 'Object' },
    { id: 'number', name: 'Number' },
];

const apiTypesOptions = [
    { id: 'string', name: 'String' },
    { id: 'array', name: 'Array' },
    { id: 'object', name: 'Object' },
    { id: 'number', name: 'Number' },
    { id: 'boolean', name: 'Boolean' },
    { id: 'integer', name: 'Integer' },
];

const typesOptions = {
    [TASK_TYPES.webhook]: webhookTypesOptions,
    [TASK_TYPES.api]: apiTypesOptions,
};

const CreateNewInputModal = ({
    onClose,
    userInput,
    taskId,
    taskType,
    setUpdatedUserInputs,
    setOutputs,
    messagesArray,
    apiTaskChangeableData,
}) => {
    const [enteredData, setEnteredData] = useState({
        label: '',
        actionType: null, // for inbox type
        optionLabel: '', // for inbox type
        type: null, // for webhook and api types
    });
    const [optionsArr, setOptionsArr] = useState([]);
    const [errorFields, setErrorFields] = useState({
        label: false,
        actionType: false,
        options: false,
        type: false,
    });
    const [errorAlert, setErrorAlert] = useState({
        isShown: false,
        message: '',
        statusCode: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);

    const shouldAddOptions =
        enteredData.actionType === 'single-select' || // for inbox task
        enteredData.actionType === 'multi-select' || // for inbox task
        enteredData.type === 'string'; // for api task

    const handleChange = (e) => {
        setEnteredData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
        if (errorFields[e.target.name]) {
            setErrorFields((prevState) => ({ ...prevState, [e.target.name]: false }));
        }
    };

    const handleSelect = (field, option) => {
        setEnteredData((prevData) => ({ ...prevData, [field]: option }));
        if (errorFields[field]) {
            setErrorFields((prevState) => ({ ...prevState, [field]: false }));
        }
    };

    const handleAddNewOption = (newOption) => {
        setOptionsArr((prevOptions) => [...prevOptions, newOption]);
        if (errorFields.options) {
            setErrorFields((prevState) => ({
                ...prevState,
                options: false,
            }));
        }
    };

    const handleRemoveOptionBadge = (optionToRemove) => {
        setOptionsArr((prevOptions) => prevOptions.filter((option) => option !== optionToRemove));
    };

    const getFormattedOptions = () => {
        return !shouldAddOptions
            ? {}
            : optionsArr.reduce((acc, item) => ({ ...acc, [item]: item }), {});
    };

    const checkFormForEmptyRequiredFields = () => {
        if (
            taskType === TASK_TYPES.inbox &&
            (!enteredData.label ||
                !enteredData.actionType ||
                (shouldAddOptions && !optionsArr.length))
        ) {
            setErrorFields({
                label: !enteredData.label,
                actionType: !enteredData.actionType,
                options: shouldAddOptions ? !optionsArr.length : false,
            });
            return true; // means that there are empty fields
        }

        if (
            (taskType === TASK_TYPES.webhook || taskType === TASK_TYPES.api) &&
            (!enteredData.label || !enteredData.type)
        ) {
            setErrorFields({
                label: !enteredData.label,
                type: !enteredData.type,
            });
            return true; // means that there are empty fields
        }

        if (taskType === TASK_TYPES.assistant && !enteredData.label) {
            setErrorFields({
                label: !enteredData.label,
            });
            return true; // means that there are empty fields
        }

        return false; // if we get there it means that there are no empty fields
    };

    const addNewInput = async () => {
        const areEmptyFields = checkFormForEmptyRequiredFields();
        if (areEmptyFields) {
            return;
        }

        try {
            setIsLoading(true);

            const newInputRequestBody = {
                [TASK_TYPES.inbox]: {
                    key: formatToKey(enteredData.label),
                    type: enteredData.actionType === 'multi-select' ? 'array' : 'string',
                    label: enteredData.label,
                    is_required: true,
                    options: getFormattedOptions(),
                    action_type: enteredData.actionType,
                },
                [TASK_TYPES.webhook]: {
                    label: enteredData.label,
                    type: enteredData.type,
                    key: formatToKey(enteredData.label),
                    is_required: true,
                },
                [TASK_TYPES.assistant]: {
                    label: enteredData.label,
                    type: 'string',
                    key: formatToKey(enteredData.label),
                    is_required: true,
                    value: '',
                },
                [TASK_TYPES.function]: {
                    label: enteredData.label,
                    type: 'string',
                    key: formatToKey(enteredData.label),
                    is_required: true,
                    value: '',
                },
                [TASK_TYPES.api]: {
                    label: enteredData.label,
                    type: enteredData.type,
                    key: formatToKey(enteredData.label),
                    is_required: true,
                    value: '',
                    // only add options if it's not empty
                    ...(optionsArr.length > 0 && { options: getFormattedOptions() }),
                },
            };

            const requestBody = {
                [userInput.key]: [
                    ...userInput.inputs.map((item) => {
                        const input = removeRedundantFieldsFromInput(item);
                        return taskType === TASK_TYPES.assistant
                            ? { ...input, value: '' }
                            : { ...input };
                    }),
                    newInputRequestBody[taskType],
                ],
            };

            const { data } = await client.patch(
                `${API.ROUTES.library.task}${taskId}/`,
                requestBody
            );
            const { func_def, inputs, outputs } = data;
            const apiRequest = [
                apiTaskChangeableData?.request_url,
                apiTaskChangeableData?.request_headers,
                apiTaskChangeableData?.request_data,
            ];

            setUpdatedUserInputs(
                updateSpecificUserInputs(
                    userInput.key,
                    func_def,
                    inputs,
                    userInput.inputs,
                    taskType,
                    messagesArray,
                    apiRequest
                )
            );
            setOutputs(outputs);

            setIsLoading(false);
            onClose();
        } catch (e) {
            setErrorAlert({ isShown: true, statusCode: e.response.status });
            setIsLoading(false);
        }
    };

    // used to increase the height of the modal so that the dropdown fits entirely on the screen
    const formMarginBottom =
        typeDropdownOpen &&
        ((taskType === TASK_TYPES.inbox &&
            (!enteredData.actionType ||
                enteredData.actionType === 'body' ||
                enteredData.actionType === 'freeform')) ||
            taskType === TASK_TYPES.webhook)
            ? 70
            : typeDropdownOpen && taskType === TASK_TYPES.api && enteredData.type !== 'string'
            ? 126
            : 0;

    return (
        <Modal onClose={onClose} size="medium" hideScrollbar>
            <div className="p-2 flex flex-col gap-8">
                <div className="flex flex-col gap-8">
                    <div className="flex items-center justify-between gap-3">
                        <p className="font-heading-bold text-heading-bold-m text-neutral-400 w-[calc(100%-63px)]">
                            Add New {userInput.label} Input
                        </p>
                        <button onClick={onClose} className="cursor-pointer">
                            <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle" />
                        </button>
                    </div>
                </div>
                <div
                    className="flex flex-col gap-5 transition"
                    style={{
                        marginBottom: `${formMarginBottom}px`,
                        transition: 'margin 0.2s ease-in-out',
                    }}
                >
                    <Input
                        size="md"
                        name="label"
                        label={`${userInput.label} Label`}
                        value={enteredData.label}
                        isRequired
                        onChange={handleChange}
                        state={errorFields.label ? 'error' : 'default'}
                        errorMessage="Please fill in this field."
                        placeholder="Add Input Label"
                    />

                    {taskType === TASK_TYPES.inbox && (
                        <>
                            <Select
                                size="md"
                                name="actionType"
                                value={enteredData.actionType}
                                options={inboxActionTypesOptions}
                                label={`${userInput.label} Action Type`}
                                onChange={(option) => handleSelect('actionType', option)}
                                isRequired
                                placeholder="Select an Action Type"
                                state={errorFields.actionType ? 'error' : 'default'}
                                errorMessage="Please select an Action Type."
                                dropdownHeight={200}
                                useExternalDropdownState
                                dropdownOpen={typeDropdownOpen}
                                setDropdownOpen={setTypeDropdownOpen}
                            />

                            {shouldAddOptions && (
                                <OptionsInput
                                    options={optionsArr}
                                    label={`${userInput.label} Options for ${formatKeyToLabel(
                                        enteredData.actionType
                                    )}`}
                                    state={errorFields.options ? 'error' : 'default'}
                                    isRequired
                                    errorMessage="Please add an Option"
                                    handleAddNewOption={handleAddNewOption}
                                    handleRemoveOption={handleRemoveOptionBadge}
                                />
                            )}
                        </>
                    )}

                    {(taskType === TASK_TYPES.webhook || taskType === TASK_TYPES.api) && (
                        <Select
                            size="md"
                            name="type"
                            value={enteredData.type}
                            options={typesOptions[taskType]}
                            label={`${userInput.label} Type`}
                            onChange={(option) => handleSelect('type', option)}
                            isRequired
                            placeholder="Select a Type"
                            state={errorFields.type ? 'error' : 'default'}
                            errorMessage="Please select a Type."
                            useExternalDropdownState
                            dropdownOpen={typeDropdownOpen}
                            setDropdownOpen={setTypeDropdownOpen}
                        />
                    )}
                    {taskType === TASK_TYPES.api && (
                        <>
                            {enteredData.type === 'string' && (
                                <OptionsInput
                                    options={optionsArr}
                                    label="Input Options"
                                    state="default"
                                    handleAddNewOption={handleAddNewOption}
                                    handleRemoveOption={handleRemoveOptionBadge}
                                />
                            )}
                        </>
                    )}
                </div>
                <div className="flex items-center justify-center gap-1 xs:gap-4">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Add New"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={addNewInput}
                    />
                </div>
            </div>
            {errorAlert.isShown && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert({ isShown: false, statusCode: null })}
                    autoCloseInMS={2000}
                />
            )}
        </Modal>
    );
};

export default CreateNewInputModal;
