import React from 'react';

import { ButtonIcon } from '../../index';
import { DeleteBin4LineIcon } from '../../Icons';
import CheckLineIcon from '../../Icons/CheckLineIcon';
import classNames from 'classnames';

const GenericBlockEmptyStateWrapper = ({
    isEditable = false,
    isError = false,
    handleDeleteBlock,
    handleSubmit,
    children,
}) => {
    const inputContainerClassName = classNames('max-w-full flex gap-2.5 p-2', {
        'items-center': isError && isEditable,
        'items-end': !isError || !isEditable,
    });

    return (
        <div className="relative bg-purple-100 rounded-[10px] border-1 border-dashed border-purple-500 p-2.5 xs:p-4">
            {isEditable && (
                <div className="absolute top-2 right-2">
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={DeleteBin4LineIcon}
                        onClick={handleDeleteBlock}
                    />
                </div>
            )}

            <div className={inputContainerClassName}>
                <div className="flex-1 max-w-[calc(100%-50px)] relative">{children}</div>

                <ButtonIcon
                    type="secondary"
                    size="sm"
                    icon={CheckLineIcon}
                    state={!isEditable ? 'disabled' : 'default'}
                    onClick={handleSubmit}
                />
            </div>
        </div>
    );
};

export default GenericBlockEmptyStateWrapper;
