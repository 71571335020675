import React, { useRef } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useClickOutside } from 'hooks';
import { useEscapeKeyListener } from '../../hooks/useEscapeKeyListener';
import './modal.css';

Modal.propTypes = {
    size: PropTypes.oneOf(['extraSmall', 'small', 'midSize', 'medium', 'mediumPlus', 'large']),
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
    resetPadding: PropTypes.bool,
    higherZIndex: PropTypes.bool,
    closeByClickOutside: PropTypes.bool,
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const modalRoot = document.querySelector('#modal-root');

function Modal(props) {
    const {
        size = 'large',
        children,
        onClose,
        higherZIndex = false,
        closeByClickOutside = true,
        resetPadding = false,
        maxHeight: _maxHeight = '90%',
    } = props;
    const modalRef = useRef(null);

    useClickOutside(modalRef, closeByClickOutside ? onClose : () => {});

    useEscapeKeyListener(onClose);

    const sizes = {
        extraSmall: 'w-[90%] xs:w-[400px]',
        small: 'w-[90%] xs:w-110',
        midSize: 'w-[90%] sm:w-[600px]',
        medium: 'w-[90%] sm:w-[632px]',
        mediumPlus: 'w-[90%] ql:w-[768px]',
        large: 'w-[90%] ql:w-200',
    };

    let modalName = classNames(
        `hide-scrollbar absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] bg-white rounded-2 shadow-l1 overflow-y-auto ${
            !resetPadding && 'p-5 sm:p-6'
        }`,
        {
            'z-52': !higherZIndex,
            'z-55': higherZIndex,
        },
        sizes[size]
    );

    const maxHeight = typeof _maxHeight === 'string' ? _maxHeight : `${_maxHeight}px`;

    return createPortal(
        <div className={`fixed top-0 left-0 right-0 bottom-0 ${higherZIndex ? 'z-53' : 'z-50'}`}>
            <div
                className={`absolute top-0 left-0 w-full h-full backdrop-blur-sm bg-black/50 ${
                    higherZIndex ? 'z-54' : 'z-51'
                }`}
            ></div>
            <div className={modalName} ref={modalRef} style={{ maxHeight }}>
                {children}
            </div>
        </div>,
        modalRoot
    );
}

export default Modal;
