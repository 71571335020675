import React, { useState } from 'react';

import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';
import { pageColumnsCommonStyles } from '../PlaybookBuilderIndex/PlaybookBuilderIndex';

import { Button } from '../../../design-system';
import { AddLineIcon } from '../../../design-system/Icons';
import BlockOptionsSelector from '../BlockOptionsSelector/BlockOptionsSelector';
import NewProjectBlock from '../NewProjectBlock/NewProjectBlock';

const PlaybookPageRightPanel = ({ mode }) => {
    const [isSelectorOpened, setIsSelectorOpened] = useState(false);

    return (
        <div className={`w-[200px] min-w-[200px] ${pageColumnsCommonStyles}`}>
            {mode === PLAYBOOK_BUILDER_MODE.edit && (
                <div className="relative w-fit">
                    <Button
                        type="primary"
                        size="md"
                        text="Add Block"
                        leadingIcon={AddLineIcon}
                        onClick={() => setIsSelectorOpened(true)}
                    />
                    {isSelectorOpened && (
                        <BlockOptionsSelector
                            position="top-[110%] right-0"
                            isDraggableSelector
                            onClose={() => setIsSelectorOpened(false)}
                        />
                    )}
                </div>
            )}

            {mode === PLAYBOOK_BUILDER_MODE.view && <NewProjectBlock />}
        </div>
    );
};

export default PlaybookPageRightPanel;
