import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/assistant-api';
import useSWRRequest from '../../../hooks/useSWRRequest';

import MainChatArea from '../MainChatArea/MainChatArea';
import MainPanelContainer from '../MainPanelContainer/MainPanelContainer';
import MainPanelHeader from '../MainPanelHeader/MainPanelHeader';
import AiModelSelector from '../AIModelSelector/AIModelSelector';

const ChatThreadPanel = ({
    chatThreadId,
    chatThreadDataHookResponse,
    historyPanelExpandedState,
    setChatThreadsHistoryList,
}) => {
    const {
        chatThreadData,
        setChatThreadData,
        selectedAiModel,
        setSelectedAiModel,
        isDiviBot,
        setIsDiviBot,
    } = chatThreadDataHookResponse;

    const [searchParams] = useSearchParams();
    const promptId = searchParams.get('prompt');

    const updateChatThreadName = async (updatedName) => {
        setChatThreadData((prevData) => ({ ...prevData, name: updatedName }));
        setChatThreadsHistoryList((chatThreads) =>
            chatThreads.map((chatThread) =>
                chatThread.id === chatThreadId ? { ...chatThread, name: updatedName } : chatThread
            )
        );

        if (chatThreadId) {
            await client.patch(`${API.ROUTES.assistant.chatThread}${chatThreadId}/`, {
                name: updatedName,
            });
        }
    };

    const shouldFetchPrompt = promptId && !chatThreadId;
    const { data, error } = useSWRRequest({
        client,
        route: shouldFetchPrompt ? `${API.ROUTES.assistant.chatPrompt}${promptId}/` : null,
    });

    const isThreadDataLoading = chatThreadId && !chatThreadData;
    const isPromptLoading = promptId && !data && !error;

    return (
        <MainPanelContainer
            historyPanelExpandedState={historyPanelExpandedState}
            isLoading={isThreadDataLoading || isPromptLoading}
        >
            <MainPanelHeader
                key={chatThreadData?.id ? chatThreadData?.id + 'header' : 'new_chat_header'}
                name={chatThreadData?.name}
                panelEntity="chat"
                handleUpdateName={updateChatThreadName}
                historyPanelExpandedState={historyPanelExpandedState}
                selector={
                    <AiModelSelector
                        selectedAiModel={selectedAiModel}
                        setSelectedAiModel={setSelectedAiModel}
                        isDiviBot={isDiviBot}
                        setIsDiviBot={setIsDiviBot}
                    />
                }
            />
            <MainChatArea
                selectedAiModel={selectedAiModel}
                isDiviBot={isDiviBot}
                chatThreadData={chatThreadData}
                setChatThreadData={setChatThreadData}
                setChatThreadsHistoryList={setChatThreadsHistoryList}
            />
        </MainPanelContainer>
    );
};

export default ChatThreadPanel;
