import React from 'react';

import { getAvailableModalMaxHeight } from '../../../helpers/getAvailableModalMaxHeight';

import SelectDocModalTemplate from '../../../components/SelectDocModalTemplate/SelectDocModalTemplate';

const SelectDocModal = ({
    docsOptionsHookResponse,
    handleDocSelect,
    onCloseAndUnselectTool,
    onClose,
    textareaHeight,
}) => {
    const maxHeight = getAvailableModalMaxHeight(textareaHeight);

    const handleDocClick = (e, doc) => {
        onClose(e);
        handleDocSelect(doc);
    };

    return (
        <SelectDocModalTemplate
            docsOptionsHookResponse={docsOptionsHookResponse}
            handleDocSelect={handleDocClick}
            onClose={onCloseAndUnselectTool}
            modalTitle="Add Doc"
            withEscapeButton
            maxHeight={maxHeight}
            desktopModalPosition="absolute bottom-[calc(100%+6px)] left-0 right-0 z-50"
        />
    );
};

export default SelectDocModal;
