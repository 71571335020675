import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';

import { useFormState } from '../../../../hooks/useFormState';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import Modal from '../../../Modal/Modal';
import Alert from '../../../Alert/Alert';
import ModalHeader from '../../../ModalHeader/ModalHeader';
import RichTextArea from '../../../RichTextArea/RichTextArea';
import ArrowRightUpLineIcon from '../../../Icons/ArrowRightUpLineIcon';
import { Button, ButtonIcon } from '../../../index';
import { ErrorWarningLineIcon } from '../../../Icons';

const EditDocumentModal = ({ id, content = '', setLinkedDocsData, onClose }) => {
    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        { content }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleSave = async () => {
        if (!formData.content) {
            setFieldErrorMessages({ content: emptyFieldErrorMessage });
            return;
        }

        if (content === formData.content) {
            onClose();
            return;
        }

        try {
            setIsLoading(true);

            await client.patch(`${API.ROUTES.knowledge.document}${id}/`, {
                value: formData.content,
            });
            setLinkedDocsData((prevData) => ({
                ...prevData,
                [id]: { ...prevData[id], content: formData.content },
            }));

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        }
    };

    const openDocDetailPage = () => {
        window.open(`/doc/${id}`, '_blank');
    };

    const modalHeaderButton = (
        <ButtonIcon type="link" size="sm" icon={ArrowRightUpLineIcon} onClick={openDocDetailPage} />
    );

    return (
        <Modal size="medium" onClose={onClose} resetPadding>
            <div className="h-[80vh] flex flex-col">
                <ModalHeader
                    onClose={onClose}
                    title="Edit Doc"
                    outlined
                    additionalControls={modalHeaderButton}
                />
                <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-6 flex-1">
                    <div className="flex-1">
                        <RichTextArea
                            value={formData.content}
                            minHeight={300}
                            setValue={(value) => handleInputChange('content', value)}
                            state={fieldErrorMessages.content ? 'error' : 'default'}
                            errorMessage={fieldErrorMessages.content}
                        />
                    </div>

                    <div className="flex items-center gap-3 justify-between">
                        <Button type="neutral" size="md" text="Cancel" onClick={onClose} />
                        <Button
                            type="secondary"
                            size="md"
                            text="Save"
                            state={isLoading ? 'loading' : 'default'}
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default EditDocumentModal;
