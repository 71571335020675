import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';
import { SvgIcon } from '../../../design-system';
import PlayProcessIcon from '../../../design-system/Icons/PlayProcessIcon';

const ConnectedAgents = ({ mode, processes }) => {
    const location = useLocation();

    const containerClassName = classNames('p-3 rounded-2 flex flex-col gap-3 transition-colors', {
        'bg-neutral-50': mode === PLAYBOOK_BUILDER_MODE.edit,
        'bg-neutral-200': mode === PLAYBOOK_BUILDER_MODE.view,
    });

    return (
        <div className={containerClassName}>
            <p className="font-body text-body-regular-xs text-neutral-500 uppercase">
                Connected Agents
            </p>

            {!!processes?.length && (
                <ul className="flex flex-col gap-2.5">
                    {processes.map((process) => (
                        <li key={process.id}>
                            <Link
                                to={`/agent/${process.id}`}
                                state={{ from: location }}
                                className="flex items-center gap-2 p-2 rounded-2 bg-white"
                            >
                                <SvgIcon color="#000000" icon={PlayProcessIcon} size="medium" />
                                <p className="font-body text-body-regular-xs tex-black">
                                    {process.name}
                                </p>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}

            {!processes?.length && (
                <p className="font-body-bold text-body-bold-xs text-neutral-500">
                    No Connected Agents found
                </p>
            )}
        </div>
    );
};

export default memo(ConnectedAgents);
