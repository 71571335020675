import { useEffect } from 'react';

export const useEscapeKeyListener = (callback) => {
    const handleEscapeClick = (e) => {
        if (e.key === 'Escape') {
            callback();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleEscapeClick);

        return () => {
            window.removeEventListener('keydown', handleEscapeClick);
        };
    }, []);
};
