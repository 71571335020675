import { useEffect, useState } from 'react';

import WebSocketInstance from '../services/websocket';

export const useWebSocketMessageStream = ({
    onStreamStart,
    onMessageStream,
    onStreamEnd,
    onStreamError,
}) => {
    // requestUuid is used for websocket messages
    const [generationId, setGenerationId] = useState(null);

    const clearGenIdAfterAction = (func, ...args) => {
        func(...args);
        setGenerationId(null);
    };

    useEffect(() => {
        WebSocketInstance.connect();
        waitForSocketConnection(() => {
            WebSocketInstance.addCallbacks(
                onStreamStart,
                onMessageStream,
                (...args) => clearGenIdAfterAction(onStreamEnd, ...args),
                (...args) => clearGenIdAfterAction(onStreamError, ...args)
            );
        });

        return () => {
            if (WebSocketInstance.isOpen()) {
                WebSocketInstance.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        WebSocketInstance.setCurrentPageGenId(generationId);
    }, [generationId]);

    const waitForSocketConnection = (callback) => {
        const checkConnection = () => {
            if (WebSocketInstance.state() === 1) {
                // console.log('connection is secure');
                callback();
            } else {
                setTimeout(checkConnection, 300);
            }
        };

        checkConnection();
    };

    const tryReconnectIfWebsocketClosed = () => {
        if (!WebSocketInstance.isOpen()) {
            WebSocketInstance.attempt_reconnect();
        }
    };

    const stopStreaming = () => {
        const abortedGenerationId = generationId;
        setGenerationId(null);

        return abortedGenerationId;
    };

    return { setGenerationId, tryReconnectIfWebsocketClosed, stopStreaming };
};
