import React from 'react';

import ProcessOverviewSettingsTabSection from '../../../../components/ProcessOverviewSettingsTabSection/ProcessOverviewSettingsTabSection';
import Loading from '../../../../components/Loading';

const AgentDetails = ({ agentDetails, setAgentDetails, isEditable }) => {
    return (
        <div className="relative px-5 pb-5 pt-8 bg-[#F3F5F880] transform -translate-y-3 rounded-b-2 z-[1]">
            {agentDetails && (
                <div className="flex flex-col gap-5">
                    <div className="pb-5 border-b-1 border-neutral-200">
                        <p className="font-body-bold text-body-bold-s text-neutral-500">
                            Agent Overview
                        </p>
                        <p className="font-body text-body-regular-s text-neutral-500">
                            {agentDetails.description}
                        </p>
                    </div>

                    <ProcessOverviewSettingsTabSection
                        processDetails={agentDetails}
                        setProcessDetails={setAgentDetails}
                        shouldShowHiddenSettings={false}
                        variant="secondary"
                        isFormDisabled={!isEditable}
                    />
                </div>
            )}

            {!agentDetails && (
                <div className="py-3">
                    <Loading withText={false} />
                </div>
            )}
        </div>
    );
};

export default AgentDetails;
