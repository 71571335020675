import React, { useEffect, useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/knowledge-api';

import useUser from '../../../../hooks/useUser';
import { lowerCaseFirstLetter } from '../../../../services/strings';
import { PROPERTY_DATA_FORMAT } from '../../../../constants/base';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import { Button } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';
import KnowledgeBaseEntryForm from '../../../BasesPage/KnowledgeBaseEntryForm/KnowledgeBaseEntryForm';
import Alert from '../../../../design-system/Alert/Alert';
import ArchiveLineIcon from '../../../../design-system/Icons/ArchiveLineIcon';
import JobTaskPageModalContainer from '../../JobTaskPageModalContainer/JobTaskPageModalContainer';

const EditBaseRecordModal = ({
    baseId,
    objectUuid,
    baseDetail,
    setBaseDetail,
    setSuccessAlert,
    onClose,
}) => {
    const { user } = useUser();

    const [entryDetail, setEntryDetail] = useState(null);

    const [inputsData, setInputsData] = useState(null);
    const [dataFieldsError, setDataFieldsError] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    useEffect(() => {
        const fetchEntryDetail = async () => {
            try {
                const { data } = await client.get(
                    `${API.ROUTES.knowledge.base}${baseId}/entry/${objectUuid}/`
                );
                setEntryDetail(data);
            } catch (e) {
                onClose();
            }
        };

        fetchEntryDetail();
    }, []);

    useEffect(() => {
        const fetchBaseDetail = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.knowledge.base}${baseId}/`);
                setBaseDetail(data);
            } catch (e) {
                onClose();
            }
        };

        if (!baseDetail) {
            fetchBaseDetail();
        }
    }, [baseDetail]);

    // useEffect(() => {
    //     const isDataLoaded = baseDetail && entryDetail;

    //     if (isDataLoaded) {
    //         setInputsData(
    //             baseDetail.properties.map(({ name, dataFormat }) => ({
    //                 name,
    //                 dataFormat,
    //                 value: entryDetail.properties[lowerCaseFirstLetter(name)],
    //             }))
    //         );
    //         setDataFieldsError(
    //             baseDetail.properties.reduce((acc, { name }) => ({ ...acc, [name]: false }), {})
    //         );
    //     }
    // }, [baseDetail, entryDetail]);
    
    // useEffect(() => {
    //     const isDataLoaded = baseDetail && entryDetail;

    //     if (isDataLoaded) {
    //         setInputsData(
    //             Object.keys(baseDetail.properties).map((key) => ({
    //                 name: key,
    //                 dataFormat: baseDetail.properties[key].dataType[0],
    //                 value: entryDetail.properties[lowerCaseFirstLetter(key)],
    //             }))
    //         );
    //         console.log('baseDetail', baseDetail);
    //         setDataFieldsError(
    //             Object.keys(baseDetail.properties).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    //         );
    //     }
    // }, [baseDetail, entryDetail]);
    useEffect(() => {
        const isDataLoaded = baseDetail && entryDetail;
    
        if (isDataLoaded) {
            const filteredProperties = Object.keys(baseDetail.properties).filter(
                (key) => key === 'label' || key === 'content'
            );
    
            setInputsData(
                filteredProperties.map((key) => ({
                    name: key,
                    dataFormat: baseDetail.properties[key].dataType[0],
                    value: entryDetail.properties[lowerCaseFirstLetter(key)],
                }))
            );
            console.log('baseDetail', baseDetail);
            setDataFieldsError(
                filteredProperties.reduce((acc, key) => ({ ...acc, [key]: false }), {})
            );
        }
    }, [baseDetail, entryDetail]);

    const handleSaveChanges = async () => {
        const areEmptyFields = inputsData.some(
            (item) => item.value === null || item.value === undefined || item.value === ''
        );

        if (areEmptyFields) {
            setDataFieldsError(
                inputsData.reduce(
                    (acc, item) =>
                        item.value === null || item.value === undefined || item.value === ''
                            ? { ...acc, [item.name]: true }
                            : acc,
                    {}
                )
            );
            return;
        }

        try {
            setIsLoading(true);

            const inputsResult = inputsData.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.name]:
                        item.dataFormat === PROPERTY_DATA_FORMAT.decimal && item.value
                            ? parseFloat(item.value)
                            : item.dataFormat === PROPERTY_DATA_FORMAT.integer && item.value
                            ? parseInt(item.value)
                            : item.value,
                }),
                {}
            );

            const requestBody = {
                properties: {
                    ...inputsResult,
                    // isDeleted: false,
                    createdBy: entryDetail.properties.createdBy,
                    updatedBy: user.id,
                },
            };

            await client.patch(
                `${API.ROUTES.knowledge.base}${baseId}/entry/${objectUuid}/`,
                requestBody
            );
            setSuccessAlert({ message: 'Knowledge Base Item successfully updated!' });
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <JobTaskPageModalContainer
            onClose={onClose}
            title="Editing Knowledge Base Record"
            isDataLoaded={!!inputsData}
        >
            <KnowledgeBaseEntryForm
                inputsData={inputsData || []}
                setInputsData={setInputsData}
                dataFieldsError={dataFieldsError}
                setDataFieldsError={setDataFieldsError}
            />
            <div className="p-2 fixed bottom-1 right-1">
                <Button
                    type="secondary"
                    size="xs"
                    text="Save Changes"
                    state={isLoading ? 'loading' : 'default'}
                    leadingIcon={ArchiveLineIcon}
                    onClick={handleSaveChanges}
                />
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={4000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </JobTaskPageModalContainer>
    );
};

export default EditBaseRecordModal;
