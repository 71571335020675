import React from 'react';
import classNames from 'classnames';

import ConnectedAgents from '../ConnectedAgents/ConnectedAgents';
import NavigationGuide from '../NavigationGuide/NavigationGuide';
import PlaybookHistory from '../PlaybookHistory/PlaybookHistory';

const PlaybookPageLeftPanel = ({
    mode,
    playbookDetail,
    playbookContentFormData,
    areSectionsExpandedState,
}) => {
    const { processes } = playbookDetail;

    const columnClassNames = classNames(
        'flex flex-col gap-6 w-[200px] min-w-[200px] lg:overflow-y-auto pb-[300px] h-full hide-scrollbar pb-[60px] lg:pb-8 xl:pb-[40px]'
    );

    return (
        <div className="h-full relative">
            <div className={columnClassNames}>
                <NavigationGuide
                    mode={mode}
                    playbookContent={playbookContentFormData || []}
                    areSectionsExpandedState={areSectionsExpandedState}
                />
                <PlaybookHistory mode={mode} playbookDetail={playbookDetail} />

                <ConnectedAgents mode={mode} processes={processes} />
            </div>
        </div>
    );
};

export default PlaybookPageLeftPanel;
