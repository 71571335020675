import React, { useRef } from 'react';

import { useClickOutside } from '../../../hooks';

import { SvgIcon } from '../../../design-system';
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList';
import FlowChartIcon from '../../../design-system/Icons/FlowChartIcon';
// import FlowIcon from '../../../design-system/Icons/FlowIcon';

const ScenariosSelector = ({ playbookId, scenariosOptionHookResponse, onClose }) => {
    const selectorRef = useRef(null);

    const { options, optionsLoading, canLoadMoreOptions, setPage } = scenariosOptionHookResponse;

    const handleClose = (e) => {
        e?.stopPropagation();
        onClose();
    };

    useClickOutside(selectorRef, handleClose);

    return (
        <div
            className="w-[237px] absolute top-[110%] right-0 z-20 rounded-2.5 p-5 flex flex-col bg-neutral-400 max-h-[300px] overflow-y-auto"
            ref={selectorRef}
        >
            <InfiniteScrollList
                handleFetch={() => setPage((page) => page + 1)}
                canLoadMore={canLoadMoreOptions}
                items={options}
                loading={optionsLoading}
                loadingIconColor="white"
                gap={8}
            >
                {options?.map(({ id, name }) => (
                    <a
                        key={id}
                        href={`/playbook/${playbookId}/start/${id}`}
                        target="_blank"
                        className="flex items-center gap-3 max-w-full cursor-pointer overflow-hidden py-1"
                        onClick={handleClose}
                    >
                        <SvgIcon color="#FFFFFF" icon={FlowChartIcon} size="large" />
                        <p className="font-body text-body-regular-s text-white truncate">{name}</p>
                    </a>
                ))}
            </InfiniteScrollList>
        </div>
    );
};

export default ScenariosSelector;
