import { STATUS, STATUS_BADGE_COLOR, STATUS_LABEL } from './statuses';

import FlagFillLineIcon from '../design-system/Icons/FlagFillLineIcon';
import FileEditLineIcon from '../design-system/Icons/FileEditLineIcon';
import CheckboxCircleFillLineIcon from '../design-system/Icons/CheckboxCircleFillLineIcon';

export const STATUS_FILTER_OPTIONS = [
    STATUS.pending,
    STATUS.started,
    STATUS.success,
    STATUS.failure,
];

export const FORMATTED_STATUS_OPTIONS = STATUS_FILTER_OPTIONS.map((status) => ({
    id: status,
    name: STATUS_LABEL[status],
    color: STATUS_BADGE_COLOR[status],
}));

export const JOB_TASK_BUCKET_STATUS = {
    pending: 'pending',
    processing: 'processing',
    completed: 'completed',
};

export const JOB_TASK_STATUS_TO_BUCKET_MAPPING = {
    [STATUS.pending]: JOB_TASK_BUCKET_STATUS.pending,
    [STATUS.started]: JOB_TASK_BUCKET_STATUS.processing,
    [STATUS.failure]: JOB_TASK_BUCKET_STATUS.processing,
    [STATUS.success]: JOB_TASK_BUCKET_STATUS.completed,
};

export const JOB_TASK_BUCKET_STATUS_ICON = {
    pending: FlagFillLineIcon,
    processing: FileEditLineIcon,
    completed: CheckboxCircleFillLineIcon,
};

export const JOB_TASK_CONTENT_TYPE = {
    info: 'info',
    static: 'static',
    inbox: 'inbox',
    kb: 'kb',
    api: 'api',
    agent: 'agent',
};
