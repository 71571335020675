import React, { useState } from 'react';

import DocsList from '../DocsList/DocsList';
import Loading from '../../../components/Loading';
import DocsEmptyView from '../DocsEmptyView/DocsEmptyView';
import PaginatedContainer from '../../../components/PaginatedContainer/PaginatedContainer';

const DocsPaginatedContainer = ({ docsHookResponse, page, searchQuery, requestKeysToMutate }) => {
    const { data, total, count, loading } = docsHookResponse;

    const [selectedDocIds, setSelectedDocIds] = useState({}); // keeps data in format { id: boolean }

    if (!total && loading) {
        return (
            <div className="pt-5">
                <Loading />
            </div>
        );
    }

    const emptyStateShown = !total && !data?.length && !loading;

    return (
        <PaginatedContainer page={page} count={count} total={total}>
            {emptyStateShown && <DocsEmptyView isSearchQuery={!!searchQuery} />}

            <DocsList
                docsHookResponse={docsHookResponse}
                selectedDocIds={selectedDocIds}
                setSelectedDocIds={setSelectedDocIds}
                requestKeysToMutate={requestKeysToMutate}
            />
        </PaginatedContainer>
    );
};

export default DocsPaginatedContainer;
