import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { handleCreateNewDoc } from '../../../helpers/docsUtils';

import Alert from '../../../design-system/Alert/Alert';
import { Button } from '../../../design-system';
import { AddCircleLineIcon, ErrorWarningLineIcon } from '../../../design-system/Icons';

const DocsPageHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleNewDocClick = async () => {
        await handleCreateNewDoc({ setIsLoading, setErrorAlert, navigate, location });
    };

    return (
        <div className="flex flex-col min-[420px]:flex-row min-[420px]:items-start min-[420px]:justify-between gap-y-2 gap-x-3">
            <div>
                <h1 className="font-heading-bold text-heading-bold-l text-black">Docs</h1>
                <p className="font-body text-body-regular-xs text-neutral-300">
                    Create and manage reusable docs for context to use globally.
                </p>
            </div>
            <div>
                <Button
                    type="primary"
                    size="sm"
                    text="New Doc"
                    state={isLoading ? 'loading' : 'default'}
                    leadingIcon={AddCircleLineIcon}
                    onClick={handleNewDocClick}
                />
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default DocsPageHeader;
