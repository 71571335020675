import React from 'react';

import { CHAT_ROLE } from '../../../constants/assistant';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { SvgIcon } from '../../../design-system';
import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';
import RefreshCwIcon from '../../../design-system/Icons/RefreshCwIcon';
import MessageWrapper from '../MessageWrapper/MessageWrapper';
import DiviBotMessageHeader from '../DiviBotMessageHeader/DiviBotMessageHeader';
import DiviBotMessageSourcesBlock from '../DiviBotMessageSourcesBlock/DiviBotMessageSourcesBlock';
import AssistantMessageActionButtons from '../AssistantMessageActionButtons/AssistantMessageActionButtons';

// possible statuses: "failure", "pending", "success"
const AssistantMessage = ({
    message,
    setChatMessages,
    isRegenerateDisabled,
    handleRegenerateChatMessage,
}) => {
    const {
        id,
        default_version: { content, status, tools, model, is_divibot, streamErrorMessage },
    } = message;

    const isActionButtonsDisplayed = status === 'success';
    const isClickableErrorBanner = status === 'failure';

    const toolsArray = Object.entries(tools || {});

    const isSourceBlockDisplayed = is_divibot && status === 'success';

    const onRegenerateMessageClick = () => {
        if (handleRegenerateChatMessage) {
            handleRegenerateChatMessage({ id, role: CHAT_ROLE.assistant });
        }
    };

    return (
        <MessageWrapper role={CHAT_ROLE.assistant} blackRobotIcon={!is_divibot}>
            {is_divibot && <DiviBotMessageHeader toolsArray={toolsArray} />}

            <RichTextArea value={content} previewOnly autoExpand isBorderHidden />

            {isSourceBlockDisplayed && <DiviBotMessageSourcesBlock toolsArray={toolsArray} />}

            {isActionButtonsDisplayed && (
                <AssistantMessageActionButtons
                    message={message}
                    setChatMessages={setChatMessages}
                    isRegenerateDisabled={isRegenerateDisabled}
                    onRegenerateMessageClick={onRegenerateMessageClick}
                />
            )}

            {isClickableErrorBanner && (
                <div className="flex gap-2 cursor-pointer" onClick={onRegenerateMessageClick}>
                    <SvgIcon color="#E95B69" icon={RefreshCwIcon} size="medium" />
                    <p className="font-body text-body-regular-s text-red-500">
                        {streamErrorMessage || defaultErrorMessage}
                    </p>
                </div>
            )}
        </MessageWrapper>
    );
};

export default AssistantMessage;
