import React from 'react';

import { integerRegex } from '../../../constants/regex_patterns';
import { PROPERTY_DATA_FORMAT } from '../../../constants/base';

import Radio from '../../../design-system/Radio/Radio';
import TextArea from '../../../design-system/TextArea/TextArea';
import { Input } from '../../../design-system';

const checkboxOptions = [
    { label: 'True', value: true },
    { label: 'False', value: false },
];

const KnowledgeBaseEntryForm = ({
    inputsData,
    setInputsData,
    dataFieldsError,
    setDataFieldsError,
}) => {
    console.log('inputsData', inputsData);
    const handleInputChange = (value, inputIndex, name) => {
        setInputsData((prevData) =>
            prevData.map((item, index) => (index === inputIndex ? { ...item, value } : item))
        );
        if (dataFieldsError[name]) {
            setDataFieldsError((prevData) => ({ ...prevData, [name]: false }));
        }
    };

    const handleIntegerInputChange = (e, inputIndex, name) => {
        const inputValue = e.target.value;

        if (integerRegex.test(inputValue)) {
            setInputsData((prevData) =>
                prevData.map((item, index) =>
                    index === inputIndex ? { ...item, value: inputValue } : item
                )
            );
            if (dataFieldsError[name]) {
                setDataFieldsError((prevData) => ({ ...prevData, [name]: false }));
            }
        }
    };

    return (
        <div className="flex flex-col gap-5">
            {inputsData.map(({ value, name, dataFormat }, index) => (
                <div key={index}>
                    {(dataFormat === PROPERTY_DATA_FORMAT.singleLineText) && (
                        <Input
                            key={index}
                            size="sm"
                            value={value}
                            name={name}
                            label={name}
                            isRequired
                            placeholder="Add text here"
                            state={dataFieldsError[name] ? 'error' : 'default'}
                            errorMessage="Please fill in this field"
                            onChange={(e) => handleInputChange(e.target.value, index, name)}
                        />
                    )}
                    {dataFormat === PROPERTY_DATA_FORMAT.multiLineText && (
                        <TextArea
                            key={index}
                            size="sm"
                            value={value}
                            name={name}
                            label={name}
                            isRequired
                            placeholder="Add text here"
                            state={dataFieldsError[name] ? 'error' : 'default'}
                            errorMessage="Please fill in this field"
                            onChange={(e) => handleInputChange(e.target.value, index, name)}
                            height="210px"
                        />
                    )}
                    {dataFormat === PROPERTY_DATA_FORMAT.integer && (
                        <Input
                            key={index}
                            size="xs"
                            value={value}
                            name={name}
                            label={name}
                            isRequired
                            type="text"
                            placeholder="0"
                            state={dataFieldsError[name] ? 'error' : 'default'}
                            errorMessage="Please fill in this field"
                            onChange={(e) => handleIntegerInputChange(e, index, name)}
                        />
                    )}
                    {dataFormat === PROPERTY_DATA_FORMAT.decimal && (
                        <Input
                            key={index}
                            size="xs"
                            value={value}
                            name={name}
                            label={name}
                            isRequired
                            placeholder="0.1"
                            type="number"
                            step="0.1"
                            state={dataFieldsError[name] ? 'error' : 'default'}
                            errorMessage="Please fill in this field"
                            onChange={(e) => handleInputChange(e.target.value, index, name)}
                        />
                    )}
                    {dataFormat === PROPERTY_DATA_FORMAT.checkbox && (
                        <div>
                            <p className="font-body text-body-bold-s text-neutral-500 mb-1">
                                {name}
                                <sup className="text-red-500 leading-1 font-medium ml-0.5">*</sup>
                            </p>
                            <ul className="flex flex-col gap-1">
                                {checkboxOptions.map((option) => (
                                    <li className="py-1 px-2" key={option.value}>
                                        <Radio
                                            isSelected={option.value === value}
                                            label={option.label}
                                            name={option.label}
                                            onChange={() => handleInputChange(option.value, index)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default KnowledgeBaseEntryForm;
