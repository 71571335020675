import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { handleCreateNewDoc } from '../../../helpers/docsUtils';

import Alert from '../../../design-system/Alert/Alert';
import EmptyView from '../../../design-system/EmptyView/EmptyView';
import FileTextIcon from '../../../design-system/Icons/FileTextIcon';
import PlusCircleIcon from '../../../design-system/Icons/PlusCircleIcon';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const DocsEmptyView = ({ isSearchQuery }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    if (isSearchQuery) {
        return (
            <p className="font-body text-body-bold-s text-neutral-300 px-1 max-w-[600px]">
                No documents found. Please adjust your search and try again!
            </p>
        );
    }

    const handleNewDocClick = async () => {
        await handleCreateNewDoc({ setIsLoading, setErrorAlert, navigate, location });
    };

    return (
        <>
            <EmptyView
                icon={FileTextIcon}
                mainText="You don’t have any Docs yet."
                subtext="Try creating one by clicking “New Doc”."
                buttonText="New Doc"
                buttonLeadingIcon={PlusCircleIcon}
                handleButtonClick={handleNewDocClick}
                buttonState={isLoading ? 'loading' : 'default'}
            />

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

export default DocsEmptyView;
