import React from 'react';
import classNames from 'classnames';

import { ButtonIcon } from '../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../design-system/Icons';

const SettingStepCollapsibleContainer = ({
    stepDetails,
    isExpanded,
    setIsExpanded,
    variant,
    children,
}) => {
    const { order, label, title, description } = stepDetails;

    const toggleExpand = () => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [order]: !prevState[order],
        }));
    };

    const stepClassName = classNames('border-b-1 border-neutral-200', {
        'pb-4': variant === 'primary',
        'pb-5': variant === 'secondary',
    });
    const containerClassName = classNames(
        'flex items-start justify-between',
        variant === 'primary' && 'gap-3'
    );

    const labelClassName = classNames('font-body-bold text-black', {
        'text-body-bold-m mb-2': variant === 'primary',
        'text-body-bold-s': variant === 'secondary',
    });
    const titleClassName = classNames({
        'font-body-bold text-body-bold-s text-neutral-400': variant === 'primary',
        'font-body text-body-regular-s text-neutral-500': variant === 'secondary',
    });

    const buttonType = {
        primary: 'neutral',
        secondary: 'link',
    };

    return (
        <div className={stepClassName}>
            <div className={containerClassName}>
                <div>
                    <p className={labelClassName}>{label}</p>
                    <p className={titleClassName}>
                        {title}{' '}
                        {description && (
                            <span className="font-body text-body-regular-s">{description}</span>
                        )}
                    </p>
                </div>
                <ButtonIcon
                    size="sm"
                    type={buttonType[variant]}
                    icon={isExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon}
                    onClick={toggleExpand}
                />
            </div>

            <div className={`${isExpanded ? 'pt-5 pb-2' : 'h-0 max-h-0 overflow-hidden'}`}>
                {children}
            </div>
        </div>
    );
};

export default SettingStepCollapsibleContainer;
