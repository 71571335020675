import React, { useState } from 'react';

import { defaultErrorMessage } from '../../../constants/errorMessages';
import Alert from '../../../design-system/Alert/Alert';
import ConfirmationModal from '../../../design-system/ConfirmationModal/ConfirmationModal';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const ConfirmSyncModal = ({ onClose, refreshPlaybookData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleConfirmSync = async () => {
        try {
            setIsLoading(true);

            await refreshPlaybookData();

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <>
            <ConfirmationModal
                onClose={onClose}
                text="You are about to overwrite your recent edits. Are you sure?"
                confirmButtonText="Yes, Sync"
                cancelButtonText="No, Go Back"
                confirmButtonState={isLoading ? 'loading' : 'default'}
                handleConfirm={handleConfirmSync}
            />

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

export default ConfirmSyncModal;
