import React, { useEffect, useState } from 'react';
import useSWR from 'swr';

import { API } from 'constants';
import client from '../../../services/library-api';
import knowledgeClient from '../../../services/knowledge-api';

import { DEFAULT_LIMIT } from '../../../hooks/useCustomPagination';
import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';

import {
    checkIfViewModeWithEmptyPlaybookContent,
    extractLinkedDocIds,
} from '../../../helpers/playbookBuilderUtils';

import DraggablePlaybookSection from '../DraggablePlaybookSection/DraggablePlaybookSection';
import AddNewSectionZone from '../AddNewSectionZone/AddNewSectionZone';

const fetcher = (url) => client.get(url).then((res) => res.data);

const PlaybookContent = ({
    mode,
    playbookContentFormData,
    setPlaybookContentFormData,
    areSectionsExpandedState,
    connectedAgents,
    playbookContentData,
}) => {
    const isViewModeWithEmptyPlaybookContent = checkIfViewModeWithEmptyPlaybookContent(
        mode,
        playbookContentFormData
    );

    // to cache the response for the Agent Block
    useSWR(`${API.ROUTES.library.process}?limit=${DEFAULT_LIMIT}&offset=0&ordering=name`, fetcher);

    // keeps data in format {docId: {label: {doc.label}, content: {doc.default_version.value}}}
    const linkedDocsDataState = useState({});
    const [_, setLinkedDocsData] = linkedDocsDataState;

    useEffect(() => {
        if (!playbookContentData?.length) {
            return;
        }

        const fetchDocData = async (idsToFetch) => {
            if (!idsToFetch.length) return;

            try {
                const data = await Promise.allSettled(
                    idsToFetch.map((id) => {
                        return knowledgeClient.get(`${API.ROUTES.knowledge.document}${id}/`);
                    })
                );

                const fetchedDocsData = data.reduce((acc, { status, value }) => {
                    if (status === 'fulfilled') {
                        const { id, label, default_version } = value.data;

                        return {
                            ...acc,
                            [id]: { label, content: default_version.value },
                        };
                    }
                    return acc;
                }, {});

                setLinkedDocsData((prev) => ({
                    ...prev,
                    ...fetchedDocsData,
                }));
            } catch (e) {}
        };

        const linkedDocsIds = extractLinkedDocIds(playbookContentData);
        const idsToFetch = Object.entries(linkedDocsIds).map(([docId]) => docId);

        fetchDocData(idsToFetch);
    }, [playbookContentData]);

    const connectedAgentsDetailedDataState = useState({});

    useEffect(() => {
        const [_, setConnectedAgentsDetailedData] = connectedAgentsDetailedDataState;
        setConnectedAgentsDetailedData({});
    }, [playbookContentData]);

    return (
        <div className="flex flex-col gap-5 lg:gap-6">
            {!!playbookContentFormData.length && !isViewModeWithEmptyPlaybookContent && (
                <div className="flex flex-col gap-5 lg:gap-6">
                    {playbookContentFormData.map((section) => (
                        <DraggablePlaybookSection
                            key={section.temporaryId}
                            sectionData={section}
                            setPlaybookContentFormData={setPlaybookContentFormData}
                            isTheOnlySection={playbookContentFormData.length === 1}
                            mode={mode}
                            areSectionsExpandedState={areSectionsExpandedState}
                            connectedAgents={connectedAgents}
                            linkedDocsDataState={linkedDocsDataState}
                            connectedAgentsDetailedDataState={connectedAgentsDetailedDataState}
                        />
                    ))}
                </div>
            )}

            {mode === PLAYBOOK_BUILDER_MODE.edit && (
                <AddNewSectionZone
                    areSectionsExpandedState={areSectionsExpandedState}
                    setPlaybookContentFormData={setPlaybookContentFormData}
                />
            )}

            {isViewModeWithEmptyPlaybookContent && (
                <p className="font-heading-regular text-heading-regular-m text-black lg:pl-[27px]">
                    Add playbook sections to view them here
                </p>
            )}
        </div>
    );
};

export default PlaybookContent;
