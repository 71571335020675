import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'design-system';
import useUser from 'hooks/useUser';
import Loading from 'components/Loading';
import Error from 'components/Error';
import useDocumentTitle from 'hooks/useDocumentTitle';
import React, { useEffect, useState } from 'react';
import UserSettingsBlock from './UserSettingsBlock/UserSettingsBlock';
import OrganizationsBlock from './OrganizationsBlock/OrganizationsBlock';
import ProviderCard from '../OrganizationPage/ProviderCard/ProviderCard';
import OrgConnectionActionModal from '../OrganizationPage/OrgConnectionActionModal/OrgConnectionActionModal';
import Alert from '../../design-system/Alert/Alert';
import CheckLineIcon from '../../design-system/Icons/CheckLineIcon';
import client from 'services/user-api';

export default function Profile() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const useUserResult = useUser();
    const { user, isLoading, isError, isValidating } = useUserResult;
    const [oauthConnectionData, setOauthConnectionData] = useState(null); // State to hold OAuth connection data

    let baseUrl;
    if (process.env.NODE_ENV === 'production') {
        // TODO: replace with production API URL
        baseUrl = '/api/user/';
    } else {
        baseUrl = 'http://127.0.0.1:8000/api/user/';
    }

    useDocumentTitle('Profile');

    const [connectionActionModal, setConnectionActionModal] = useState({
        opened: false,
        data: null,
        action: 'view',
        provider: null,
    });
    const [successAlertMessage, setSuccessAlertMessage] = useState(null);

    useEffect(() => {
        // Check for existing GitHub credentials
        const checkGithubCredentials = async () => {
            try {
                const { data } = await client.get('api-credentials/?provider=github');
                if (data && data.results.length > 0) {
                    // If credentials exist, set the first item to the Provider Card data
                    setOauthConnectionData(data.results[0]);
                }
            } catch (error) {
                console.error('Error checking GitHub credentials:', error);
            }
        };

        // Handle OAuth callback
        const handleOAuthCallback = async () => {
            const type = searchParams.get('type');
            const status = searchParams.get('status');
            const code = searchParams.get('code');
            if (type === 'github_oauth_callback' && status === 'success' && code) {
                try {
                    // // Handle success (e.g., navigate, update state)
                    const { data } = await client.get(
                        `oauth/callback/?type=${type}&status=${status}&code=${code}`
                    );
                    console.log('OAuth callback response:', data);
                    // Set the OAuth connection data received from the backend
                    setOauthConnectionData(data);
                } catch (error) {
                    console.error('Error handling OAuth callback:', error);
                    // Handle error
                }
            }
        };

        handleOAuthCallback();
        checkGithubCredentials();
    }, [searchParams]);

    if (!user && (isLoading || isValidating)) {
        return (
            <div className="w-full">
                <Loading />
            </div>
        );
    }
    if (isError) {
        if (isError.response.status === 403) {
            return (
                <section className="userNotLoggedIn">
                    <h1 className="font-inter font-bold text-neutral">
                        It looks like you aren't logged in
                    </h1>
                    <Link
                        className="py-1 text-center transition font-brandlight text-blue-500"
                        to="/login"
                    >
                        Go to Login
                    </Link>
                </section>
            );
        }
        return <Error />;
    }

    function handleSubmit() {
        navigate('/login');
    }

    return (
        <div className="flex flex-col w-full sm:w-[calc(100%-68px)] max-sm:p-4">
            {user && (
                <div className="space-y-5">
                    <div className="font-brand text-heading-bold-l">Your Profile</div>
                    <UserSettingsBlock user={user} />
                    <OrganizationsBlock />
                    {user.is_staff && (
                        <div className="p-4 md:pt-4 md:pb-8 md:px-8 rounded-md w-full bg-white flex gap-x-2 gap-y-6 flex-wrap">
                            <div className="flex flex-col space-y-5 w-full max-w-[444px]">
                                <div className="font-body-bold text-body-bold-m">
                                    OAuth Connections
                                </div>
                                <div className="w-full max-w-[400px]">
                                    <ProviderCard
                                        provider="github"
                                        connectionData={oauthConnectionData}
                                        hasCreateAccess={true}
                                        comingSoon={false}
                                        oauthUrl={`${baseUrl}oauth/redirect/?provider=github`}
                                        setConnectionActionModal={setConnectionActionModal}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {!user && (
                <div>
                    <h1>You are not logged in</h1>
                    <Button type="primary" text="Login" size="small" onClick={handleSubmit} />
                </div>
            )}
            {connectionActionModal.opened && (
                <OrgConnectionActionModal
                    data={connectionActionModal.data}
                    provider={connectionActionModal.provider}
                    action="view"
                    setSuccessAlertMessage={setSuccessAlertMessage}
                    onClose={() => setConnectionActionModal({ opened: false })}
                />
            )}
            {successAlertMessage && (
                <Alert
                    status="positive"
                    icon={CheckLineIcon}
                    message={successAlertMessage}
                    autoCloseInMS={3000}
                    handleClose={() => setSuccessAlertMessage(null)}
                />
            )}
        </div>
    );
}
