import React, { memo, useMemo } from 'react';
import { CHAT_ROLE } from '../../../constants/assistant';

import { LINKED_DOC_REGEX } from '../../../components/NewJobForm/RichTextWithLinkedDocs/RichTextWithLinkedDocs';
import { getLinkedDocumentsTagMarkup } from '../../../helpers/getLinkedDocumentsTagMarkup';

import MessageWrapper from '../MessageWrapper/MessageWrapper';
import JobSubmissionDetailItem from '../JobSubmissionDetailItem/JobSubmissionDetailItem';

const JobSubmissionDetails = ({
    agentName,
    inputs,
    isExpanded = true,
    toggleSectionExpand,
    mappedDocsLabels,
}) => {
    const toggleExpandButtonText = isExpanded ? 'Hide Job Submission' : 'See Job Submission';

    const inputsWithLinkedDocumentTags = useMemo(() => {
        if (!inputs?.length) {
            return [];
        }
        return inputs.map((input) => {
            if (input.type !== 'string') {
                return input;
            }

            const updatedValue = input.value?.replace(LINKED_DOC_REGEX, (match, docId) =>
                getLinkedDocumentsTagMarkup(docId, mappedDocsLabels[docId])
            );

            return { ...input, value: updatedValue };
        });
    }, [inputs, mappedDocsLabels]);

    return (
        <div className="w-full py-5">
            <MessageWrapper role={CHAT_ROLE.user} noBgColor>
                <div className="flex flex-col rounded-2 overflow-hidden bg-neutral-100/50">
                    <div className="p-4 flex flex-col sm:flex-row sm:justify-between gap-3.5 sm:gap-5 bg-neutral-100 rounded-2">
                        <p className="font-body text-body-regular-s text-neutral-500">
                            You started{' '}
                            <span className="font-body-bold text-body-bold-s">{agentName}</span> Job{' '}
                        </p>
                        <div>
                            <button
                                type="button"
                                onClick={() => toggleSectionExpand('jobSubmissionDetails')}
                                className="block font-heading-bold text-[14px] font-semibold underline whitespace-nowrap outline-0 focus:outline-0"
                            >
                                {toggleExpandButtonText}
                            </button>
                        </div>
                    </div>

                    {isExpanded && (
                        <div className="px-4 pt-4 pb-6 bg-neutral-100/50 text-neutral-500">
                            {!!inputsWithLinkedDocumentTags?.length && (
                                <ul className="flex flex-col gap-4">
                                    {inputsWithLinkedDocumentTags.map(({ id, label, value }) => (
                                        <JobSubmissionDetailItem
                                            key={id}
                                            label={label}
                                            value={value}
                                        />
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                </div>
            </MessageWrapper>
        </div>
    );
};

export default memo(JobSubmissionDetails);
