import React, { memo } from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';
import UserBadge from '../../../design-system/UserBadge/UserBadge';

const PlaybookHistory = ({ playbookDetail, mode }) => {
    const {
        last_updated_by: { name, updated_at },
    } = playbookDetail;

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a');

    const containerClassName = classNames('p-5 md:p-6 rounded-2.5 flex flex-col gap-4', {
        'bg-neutral-100': mode === PLAYBOOK_BUILDER_MODE.edit,
        'bg-white': mode === PLAYBOOK_BUILDER_MODE.view,
    });
    const labelClassName = 'font-body text-body-regular-xs text-neutral-500 uppercase mb-2.5';

    return (
        <div className={containerClassName}>
            <div>
                <p className={labelClassName}>Last updated by</p>
                <UserBadge name={name} />
            </div>

            <div>
                <p className={labelClassName}>Last updated at</p>
                <p className="font-body-bold text-body-bold-xs text-neutral-500">{formattedTime}</p>
            </div>
        </div>
    );
};

export default memo(PlaybookHistory);
